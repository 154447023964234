define('hyve-frontend/components/message-creator', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Component.extend({

        classNames: 'chat-window',
        classNameBindings: ['open:open'],

        open: false,
        group: null,
        message: '',

        store: service(),
        sessionManager: service(),

        allUsers: [],

        chatWith: _ember['default'].computed('group', function () {
            var _this = this;

            return this.get('group.members').filter(function (user) {
                return user.get('id') != _this.get('sessionManager.user.id');
            }).map(function (user) {
                return user.get('fullName');
            }).join(', ');
        }),

        didRender: function didRender() {
            this._super.apply(this, arguments);
            this.$('.message-window').scrollTop(100000);
        },

        init: function init() {
            var _this2 = this;

            this._super.apply(this, arguments);
            this.get('store').findAll('user').then(function (allUsers) {
                _this2.set('allUsers', allUsers);
            });
        },

        messages: _ember['default'].computed('store.messages.[]', 'group', function () {
            return this.get('group.messages');
        }),

        validUsers: _ember['default'].computed('allUsers.[]', 'sessionManager', function () {
            var _this3 = this;

            // wait for promise to resolve
            return this.get('allUsers').filter(function (user) {
                return user.get('id') != _this3.get('sessionManager.user.id');
            });
        }),

        groupExists: function groupExists(foreignUser) {
            var _this4 = this;

            return new RSVP.Promise(function (resolve, reject) {
                _this4.get('sessionManager.user.groups').then(function (groups) {
                    var group = groups.find(function (g) {
                        return g.get('members').contains(foreignUser);
                    });
                    resolve(group);
                });
            });
        },

        actions: {

            createMessage: function createMessage() {
                var _this5 = this;

                // create a new message
                var messageObj = this.get('store').createRecord('message', {
                    user: this.get('sessionManager.user'),
                    message: this.get('message'),
                    timestamp: Date.now()
                });

                this.get('group.messages').pushObject(messageObj).save().then(function () {
                    _this5.set('message', '');
                });
            },

            open: function open() {
                this.set('open', true);
            },

            close: function close() {
                this.set('open', false);
            },

            createGroup: function createGroup(foreignUser) {
                var _this6 = this;

                // check if a group already exists with this user
                this.groupExists(foreignUser).then(function (group) {
                    if (group) {
                        _this6.set('group', group);
                    } else {
                        (function () {
                            var group = _this6.get('store').createRecord('group');

                            // add users to group
                            group.get('members').pushObject(foreignUser);
                            group.get('members').pushObject(_this6.get('sessionManager.user'));

                            group.save().then(function () {
                                // add the group to each user
                                foreignUser.get('groups').pushObject(group);
                                _this6.get('sessionManager.user.groups').pushObject(group);

                                return RSVP.Promise.all([foreignUser.save(), _this6.get('sessionManager.user').save()]);
                            }).then(function () {
                                _this6.set('group', group);
                            });
                        })();
                    }
                });
            }
        }

    });
});