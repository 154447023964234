define('hyve-frontend/models/comment', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberCpValidations) {

    var Validations = (0, _emberCpValidations.buildValidations)({
        body: {
            description: '',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        }
    });

    exports['default'] = _emberDataModel['default'].extend(Validations, {
        user: (0, _emberDataRelationships.belongsTo)('user'),
        comments: (0, _emberDataRelationships.hasMany)('comment', { inverse: null }),
        body: (0, _emberDataAttr['default'])('string'),
        timestamp: (0, _emberDataAttr['default'])('date'),
        item: (0, _emberDataRelationships.belongsTo)('item', { inverse: 'comments' })
    });
});