define('hyve-frontend/components/invite-button', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        inviteFriendsService: _ember['default'].inject.service(),
        tagName: 'a',

        attributeBindings: ['mailToLink:href', 'target:target'],

        target: '_blank',

        mailToLink: _ember['default'].computed.readOnly('inviteFriendsService.mailToLink')

    });
});