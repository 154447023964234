define('hyve-frontend/services/local-storage', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({

        find: function find(key) {
            var result = window.localStorage.getItem(key);
            try {
                return JSON.parse(result);
            } catch (e) {
                console.warn(e);
            }
        },

        set: function set(key, value) {
            window.localStorage.setItem(key, JSON.stringify(value));
            return this;
        }

    });
});