define('hyve-frontend/models/event', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberDataModel, _emberDataAttr, _emberDataRelationships) {
    exports['default'] = _emberDataModel['default'].extend({
        type: (0, _emberDataAttr['default'])('string'),

        model: (0, _emberDataAttr['default'])('string'),
        modelId: (0, _emberDataAttr['default'])('string'),
        heading: (0, _emberDataAttr['default'])('string'),
        text: (0, _emberDataAttr['default'])('string'),
        notificationGenerated: (0, _emberDataAttr['default'])('boolean', false),

        timestamp: (0, _emberDataAttr['default'])('number', {
            defaultValue: function defaultValue() {
                return new Date();
            }
        }),

        actor: (0, _emberDataRelationships.belongsTo)('user', { inverse: null }),

        routeName: (0, _emberDataAttr['default'])('string'),
        routeSegment: (0, _emberDataAttr['default'])('string'),

        // if the notification needs to be sent directly to a user
        toUser: (0, _emberDataRelationships.belongsTo)('user', { inverse: null }),

        clickMethod: (0, _emberDataAttr['default'])('string'),

        clickMethodArgs: (0, _emberDataAttr['default'])({
            defaultValue: function defaultValue() {
                return [];
            }
        })

    });
});