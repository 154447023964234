define('hyve-frontend/routes/favorites', ['exports', 'ember', 'hyve-frontend/mixins/auth'], function (exports, _ember, _hyveFrontendMixinsAuth) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {

        title: 'BeeSwappy - Favorites',

        sessionManager: service(),
        model: function model() {
            return this.get('sessionManager.user.favorites');
        }

    });
});