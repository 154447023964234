define('hyve-frontend/components/chat-groups', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['chat-groups'],
        store: service(),
        sessionManager: service(),

        getGroups: function getGroups() {
            var _this = this;

            if (this.get('sessionManager.user')) {
                this.get('sessionManager.user.visibleChatGroups').then(function (groups) {
                    _this.set('groups', groups);
                });
            }
        },

        groups: [],

        init: function init() {
            this._super.apply(this, arguments);
            this.getGroups();
        },

        refreshGroups: (function () {
            this.getGroups();
        }).observes('sessionManager.user.visibleChatGroups.[]')

    });
});