define('hyve-frontend/components/rating-form', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        currentRating: null,
        sessionManager: service(),
        store: service(),

        isReadOnly: false,

        actions: {
            ratingChanged: function ratingChanged(rating) {
                var _this = this;

                var user = this.get('user');
                var ratingRecord = this.get('store').createRecord('rating', {
                    author: this.get('sessionManager.user'),
                    on: user,
                    value: rating
                });

                this.get('user.rawRatings').pushObject(ratingRecord);

                this.set('currentRating', rating);
                this.set('isReadOnly', true);

                this.get('user').save().then(function () {
                    ratingRecord.save().then(function () {
                        _this.sendAction('ratingSaved', ratingRecord);
                    });
                });
            }
        }

    });
});