define('hyve-frontend/services/google-maps-api', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {
    exports['default'] = _ember['default'].Service.extend({

        initPromise: null,

        gmapsInit: function gmapsInit() {
            if (this.initPromise) {
                return this.initPromise;
            }

            var original = window.gmapsInit;

            var apiKey = _ember['default'].get(_hyveFrontendConfigEnvironment['default'], 'GOOGLEMAPS.key');

            this.initPromise = new _ember['default'].RSVP.Promise(function (resolve) {
                window.gmapsInit = function () {
                    _ember['default'].run(null, function () {
                        return resolve(window.google.maps);
                    });
                };

                _ember['default'].$.getScript('//maps.googleapis.com/maps/api/js?key=' + apiKey + '&callback=gmapsInit', function () {
                    // noOp, wait for window.gmapsInit to be called
                });
            }).then(function (google) {
                if (original) {
                    window.gmapsInit = original;
                }
                return google;
            });

            return this.initPromise;
        },

        geocode: function geocode(address) {
            return this.gmapsInit().then(function (_ref) {
                var Geocoder = _ref.Geocoder;

                var geocoder = new Geocoder();
                return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                    geocoder.geocode({ address: address }, function (result, status) {
                        if (status === 'OK') {
                            var _location = _ember['default'].get(result, 'firstObject.geometry.location');
                            resolve(_location);
                        } else {
                            reject(status);
                        }
                    });
                });
            });
        },

        reverseGeocode: function reverseGeocode(lat, lng) {
            return this.gmapsInit().then(function (_ref2) {
                var Geocoder = _ref2.Geocoder;

                var geocoder = new Geocoder();
                return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                    geocoder.geocode({ 'location': { lat: lat, lng: lng } }, function (results, status) {
                        if (status == 'OK') {
                            var components = _ember['default'].get(results, 'firstObject.address_components');
                            var foundItem = components.find(function (component) {
                                return component.types.some(function (t) {
                                    return t == 'postal_code';
                                });
                            });
                            resolve(foundItem.short_name);
                        }
                    });
                });
            });
        }

    });
});