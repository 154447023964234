define('hyve-frontend/components/membership-timeline', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        sessionManager: _ember['default'].inject.service(),
        stripeAccount: _ember['default'].inject.service(),

        planAmount: _ember['default'].computed('sessionManager.user.plan', function () {
            // stripe is in cents
            return this.get('sessionManager.user.plan.amount') / 100;
        }),

        loading: false,

        membershipHasExpired: _ember['default'].computed('sessionManager.user.subscriptionEnd', function () {
            var time = new Date().getTime() / 1000;
            return time > this.get('sessionManager.user.subscriptionEnd');
        }),

        actions: {

            cancelSubscription: function cancelSubscription() {
                var _this = this;

                this.set('loading', true);
                this.get('stripeAccount').cancelSubscription().then(function () {
                    _this.set('loading', false);
                });
            },
            reactivateSubscription: function reactivateSubscription() {
                var _this2 = this;

                this.set('loading', true);
                this.get('stripeAccount').reactivateSubscription().then(function () {
                    _this2.set('loading', false);
                });
            }
        }

    });
});