define('hyve-frontend/components/confirm-modal', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        confirmModal: _ember['default'].inject.service(),

        text: _ember['default'].computed.alias('confirmModal.data.text'),
        header: _ember['default'].computed.alias('confirmModal.data.header'),
        isOpen: _ember['default'].computed.alias('confirmModal.open'),

        data: _ember['default'].computed.readOnly('confirmModal.data'),

        rightButtonAction: _ember['default'].computed.readOnly('confirmModal.data.rightButton.action'),
        leftButtonAction: _ember['default'].computed.readOnly('confirmModal.data.leftButton.action'),

        actions: {
            accept: function accept() {
                this.get('confirmModal').accept();
            },

            reject: function reject() {
                this.get('confirmModal').reject();
            },

            leftButtonAction: function leftButtonAction() {
                this.get('leftButtonAction').call(this.get('confirmModal'));
            },

            rightButtonAction: function rightButtonAction() {
                this.get('rightButtonAction').call(this.get('confirmModal'));
            }
        }

    });
});