define('hyve-frontend/components/comment-form', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Component.extend({

        sessionManager: service(),
        notificationSender: service(),

        classNames: ['comment-form'],

        store: service(),

        parentComment: null,

        comment: null,

        init: function init() {
            this._super.apply(this, arguments);

            var comment = this.get('store').createRecord('comment');
            this.set('comment', comment);
        },

        commentByUserThatOwnsItem: function commentByUserThatOwnsItem() {
            // if the item is my item
            return this.get('item.isMyItem');
        },

        /**
         * Send a comment notification to the user that owns the item
         * returns an object that resolves to an promise with a `sent: true` if this action was taken
         * @param item
         * @param commentObj
         * @param user
         * @return Promise
         */
        sendCommentNotificationToItemUser: function sendCommentNotificationToItemUser(item, commentObj, user) {

            if (this.commentByUserThatOwnsItem()) {
                // don't sent a notification because the user that posted the comment
                // and the user that owns the item are the same person
                return RSVP.Promise.resolve({
                    sent: true
                });
            }

            return this.get('notificationSender').commentMade({
                modelId: item.get('id'),
                heading: 'A comment was made on ' + item.get('name'),
                text: 'Comment: ' + commentObj.get('body'),
                routeName: 'item.view',
                routeSegment: item.get('id')
            }, item.get('user')).then(function () {
                return {
                    sent: true
                };
            });
        },

        /**
         * Send a comment notification to the user that owns the item
         * returns an object that resolves to an promise with a `sent: true` if this action was taken
         * @param item
         * @param commentObj
         * @param user
         * @return Promise
         */
        sendCommentNotificationToParentUser: function sendCommentNotificationToParentUser(item, commentObj, user) {
            var _this = this;

            if (!this.get('parentComment')) {
                return RSVP.Promise.resolve({
                    sent: false
                });
            }

            // there is a parent comment send a notification to the user that owns the parent comment
            return this.get('parentComment.user').then(function (parentCommentUser) {
                if (!parentCommentUser.get('isCurrentUser')) {
                    return _this.get('notificationSender').commentRepliedTo({
                        modelId: item.get('id'),
                        heading: 'Reply to your comment on ' + item.get('name'),
                        text: 'Comment Reply: ' + commentObj.get('body'),
                        routeName: 'item.view',
                        routeSegment: item.get('id')
                    }, parentCommentUser).then(function () {
                        // if the user you sent it to is the user that owns the item, let the next part of the chain know
                        // so that duplicate notifications are not sent
                        return parentCommentUser.ownsItem(item);
                    }).then(function (ownsItem) {
                        if (ownsItem) {
                            return {
                                sentToItemUser: true
                            };
                        } else {
                            return {
                                sentToItemUser: false
                            };
                        }
                    });
                }
            });
        },

        actions: {
            submitComment: function submitComment() {
                var _this2 = this;

                var commentObj = this.get('comment');
                commentObj.set('timestamp', new Date());
                commentObj.set('user', this.get('sessionManager.user'));

                var promises = [];

                if (this.get('parentComment')) {
                    this.get('parentComment.comments').pushObject(commentObj);
                    promises.push(this.get('parentComment').save());
                } else {
                    commentObj.set('item', this.get('item'));
                    promises.push(this.get('item').save());
                }

                promises.push(commentObj.save());
                RSVP.Promise.all(promises).then(function () {
                    _this2.set('comment', _this2.get('store').createRecord('comment'));
                    _this2.sendAction('commentSubmitted');
                    var item = _this2.get('item');

                    _this2.sendCommentNotificationToParentUser(_this2.get('item'), commentObj, item.get('user')).then(function (_ref) {
                        var sentToItemUser = _ref.sentToItemUser;

                        if (!sentToItemUser) {
                            return _this2.sendCommentNotificationToItemUser(_this2.get('item'), commentObj, item.get('user'));
                        }
                    });
                });
            }
        }
    });
});