define('hyve-frontend/components/chat-box', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Component.extend({
        isOpen: false,
        sessionManager: service(),
        store: service(),
        chat: service(),
        analytics: service(),
        firebase: service(),
        notification: service('message-notification'),

        classNames: ['chat-box'],

        classNameBindings: ['open:isOpen'],

        open: false,

        group: null,

        unreadItems: 0,

        pulsing: _ember['default'].computed.gt('unreadItems', 0),

        forceOpenTask: _ember['default'].observer('notification.forceOpenChatBoxes.[]', function () {
            if (this.get('notification.forceOpenChatBoxes').contains(this.get('group'))) {
                this.set('open', true);
                this.get('notification.forceOpenChatBoxes').removeObject(this.get('group'));
                _ember['default'].run.later(this, 'scrollToBottom', 500);
            }
        }),

        foreignUsersTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var members;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('group.members');

                    case 2:
                        members = context$1$0.sent;
                        return context$1$0.abrupt('return', members.filter(function (m) {
                            return m.get('uid') != _this.get('sessionManager.user.uid');
                        }));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        foreignUsers: _ember['default'].computed('users.members.[]', function () {
            return this.get('foreignUsersTask').perform();
        }),

        messages: _ember['default'].computed('group.messages.[]', 'group', function () {
            var _this2 = this;

            _ember['default'].run.later(function () {
                return _this2.scrollToBottom();
            }, 100);
            return this.get('group.messages');
        }),

        newItems: 0,

        click: function click() {
            this.set('unreadItems', 0);
        },

        openListener: function openListener(group) {

            if (this.get('group.id') == group.get('id')) {
                if (!this.get('open')) {
                    this.set('open', true);
                }
            }
        },

        willDestroyElement: function willDestroyElement() {
            this.get('chat').removeOpenGroupListener(this.openListener);
        },

        listenToNewGroupMessages: function listenToNewGroupMessages() {
            var _this3 = this;

            // group ids
            var firebase = this.get('firebase');

            firebase.child('groups/' + this.get('group.id') + '/messages').orderByChild('timestamp')
            // only in the future
            .startAt(Date.now()).on('child_added', function (sn) {
                var value = sn.val();
                var userId = value.user;

                if (_this3.get('sessionManager.user.id') !== userId) {
                    _this3.incrementProperty('unreadItems');
                }
            });
        },

        init: function init() {
            this._super.apply(this, arguments);

            this.openListener = this.openListener.bind(this);
            this.get('chat').addOpenGroupListener(this.openListener);
            this.listenToNewGroupMessages();
        },

        chatWith: _ember['default'].computed('foreignUsers', function () {
            return this.get('foreignUsers').map(function (user) {
                return user.get('fullName');
            }).join(', ');
        }),

        scrollToBottom: function scrollToBottom() {
            var el = this.$('.message-window');
            if (el.length) {
                el.scrollTop(el.get(0).scrollHeight);
            }
        },

        didInsertElement: function didInsertElement() {
            var _this4 = this;

            this.$().on('keydown', 'textarea', function (e) {
                if (e.keyCode === 13) {
                    _this4.actions.createMessage.call(_this4);
                }
            });
        },

        createMessageTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(message) {
            var messageObj, group, referenceItem;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:

                        this.get('analytics').sendMessage();

                        messageObj = this.get('store').createRecord('message', {
                            user: this.get('sessionManager.user'),
                            message: this.get('message'),
                            timestamp: Date.now()
                        });
                        group = this.get('group.messages');

                        group.pushObject(messageObj);

                        context$1$0.next = 6;
                        return group.save();

                    case 6:

                        this.scrollToBottom();
                        this.set('message', '');

                        referenceItem = this.get('group.chatSpawnedFrom');

                        this.get('chat').get('generateChatNotificationTask').perform(this.get('group'), messageObj, referenceItem);

                    case 10:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {

            toggleOpen: function toggleOpen() {
                var _this5 = this;

                this.toggleProperty('open');
                _ember['default'].run.later(function () {
                    return _this5.scrollToBottom();
                }, 100);
                _ember['default'].run.later(function () {
                    return _this5.set('newItems', 0);
                }, 100);
                this.set('unreadItems', 0);
            },

            markAsNotVisible: function markAsNotVisible() {
                this.get('sessionManager.user.visibleChatGroups').removeObject(this.get('group'));
                this.get('sessionManager.user').save();
                return false;
            },

            createMessage: function createMessage() {

                if (!this.get('message').trim()) {
                    return;
                }

                this.get('createMessageTask').perform(this.get('message').trim());
            },
            onFocus: function onFocus() {
                this.set('unreadItems', 0);
            }
        }
    });
});