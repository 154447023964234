define('hyve-frontend/initializers/event-bus', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('component', 'eventBus', 'service:event-bus');
  }

  exports['default'] = {
    name: 'event-bus',
    initialize: initialize
  };
});