define('hyve-frontend/routes/profile', ['exports', 'ember', 'hyve-frontend/mixins/auth'], function (exports, _ember, _hyveFrontendMixinsAuth) {
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {

        title: 'BeeSwappy - Profile',

        model: function model(params) {
            return this.store.findRecord('user', params.id);
        }

    });
});