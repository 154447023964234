define('hyve-frontend/models/notification', ['exports', 'ember-data', 'ember-data/attr', 'ember-data/relationships'], function (exports, _emberData, _emberDataAttr, _emberDataRelationships) {
    exports['default'] = _emberData['default'].Model.extend({
        user: (0, _emberDataRelationships.belongsTo)('user'),
        read: (0, _emberDataAttr['default'])('boolean', false),
        emailSent: (0, _emberDataAttr['default'])('boolean', false),

        // all other data about the notification is on the event object
        event: (0, _emberDataRelationships.belongsTo)('event', { inverse: null })

    });
});