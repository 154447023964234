define('hyve-frontend/initializers/firebase-logger', ['exports', 'hyve-frontend/config/environment'], function (exports, _hyveFrontendConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() /* application */{

    console.log(_hyveFrontendConfigEnvironment['default'].firebase);
  }

  exports['default'] = {
    name: 'firebase-logger',
    initialize: initialize
  };
});