define('hyve-frontend/routes/admin/category/new', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return this.store.createRecord('category', {
                parent: null
            });
        },
        deactivate: function deactivate() {
            this.currentModel.rollbackAttributes();
        },
        actions: {
            saveComplete: function saveComplete() {
                this.transitionTo('admin.categories');
            }
        }
    });
});