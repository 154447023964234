define('hyve-frontend/routes/admin/categories', ['exports', 'ember', 'hyve-frontend/mixins/auth'], function (exports, _ember, _hyveFrontendMixinsAuth) {
    var filter = _ember['default'].RSVP.filter;
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {
        model: function model() {
            return this.store.findAll('category').then(function (categories) {
                return filter(categories.toArray(), function (cat) {
                    return cat.get('parent').then(function (parent) {
                        return !parent;
                    });
                });
            });
        },
        actions: {
            'delete': function _delete(item) {
                item.deleteRecord();
                item.save();
            }
        }
    });
});