define('hyve-frontend/helpers/froala-method', ['exports', 'ember-froala-editor/helpers/froala-method'], function (exports, _emberFroalaEditorHelpersFroalaMethod) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFroalaEditorHelpersFroalaMethod['default'];
    }
  });
  Object.defineProperty(exports, 'froalaMethod', {
    enumerable: true,
    get: function get() {
      return _emberFroalaEditorHelpersFroalaMethod.froalaMethod;
    }
  });
});