define('hyve-frontend/components/item-detail-controls', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({
        classNames: 'info-card'.w(),
        confirmModal: service(),
        notify: service(),
        actions: {
            post: function post() {
                return this.get('item').post();
            },
            unpost: function unpost(item) {
                return this.get('item').unpost();
            },
            'delete': function _delete(item) {
                var _this = this;

                this.get('confirmModal').confirm('Confirm Action', 'Are you sure you want to delete this item?').then(function () {
                    item.destroyRecord().then(function () {
                        // this is a pretty hacky way of reloading the main model
                        _this.get('container').lookup('router:main').router.refresh();
                        _this.get('notify').alert(item.get('name') + ' deleted!');
                    })['catch'](function () {
                        _this.get('notify').alert('Unable to delete');
                    });
                });
            },

            finalizeTransaction: function finalizeTransaction(item) {
                var _this2 = this;

                // find the one valid claim on this item, then mark it as finalized
                item.finalizeTransaction().then(function (claim) {
                    // transition to prompt for review
                    // /rating/claimId/buyer-rating
                    _this2.get('container').lookup('router:main').transitionTo('rating.sellerToBuyerRating', claim.get('id'));
                });
                // then prompt user to review
            }

        }
    });
});