define('hyve-frontend/services/notification-sender', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    var service = _ember['default'].inject.service;
    var NOTIFICATION_EVENTS = _hyveFrontendConfigEnvironment['default'].NOTIFICATION_EVENTS;
    exports['default'] = _ember['default'].Service.extend({

        sessionManager: service(),
        store: service(),

        _sendNotification: function _sendNotification(data) {
            data.actor = this.get('sessionManager.user');
            var event = this.get('store').createRecord('event', data);
            return event.save();
        },

        _sendNotificationTo: function _sendNotificationTo(data, user) {

            var event = this.get('store').createRecord('event', data);
            event.set('actor', this.get('sessionManager.user'));
            event.set('toUser', user);
            return event.save();
        },

        /**
         * Item has been updated, only some attributes changing matter
         */
        itemUpdated: function itemUpdated(item, changedAttributes) {

            var updateWorthyProps = ['price', 'images', 'description', 'name', 'pickRadius', 'pickupZipCode', 'categories'];

            function filterObj(obj, cb) {
                var newObj = {};
                for (var prop in obj) {
                    if (cb(obj[prop], prop)) {
                        newObj[prop] = obj[prop];
                    }
                }
                return newObj;
            }

            /**
             *
             * @param obj
             * @param propsToKeep []
             */
            function removeProperties(obj, propsToKeep) {
                return filterObj(obj, function (_, key) {
                    return propsToKeep.contains(key);
                });
            }

            function removeTypeDifferences(obj) {
                return filterObj(obj, function (values) {
                    var _values = _slicedToArray(values, 2);

                    var oldValue = _values[0];
                    var newValue = _values[1];

                    return oldValue != newValue;
                });
            }

            function translateKey(key) {
                var translate = {
                    pickRadius: 'Pickup Radius'
                };

                if (translate[key]) {
                    return translate[key];
                }

                return key.toUpperCase();
            }

            changedAttributes = removeProperties(changedAttributes, updateWorthyProps);
            changedAttributes = removeTypeDifferences(changedAttributes);

            if (!Object.keys(changedAttributes).length) {
                return;
            }

            var text = Object.keys(changedAttributes).map(function (key) {
                var _changedAttributes$key = _slicedToArray(changedAttributes[key], 2);

                var oldValue = _changedAttributes$key[0];
                var newValue = _changedAttributes$key[1];

                return translateKey(key) + ' changed to ' + newValue + ' was ' + oldValue;
            }).join(' ');

            return this._sendNotification({
                type: 'item_updated',
                modelId: item.get('id'),
                heading: item.get('name') + ' has been updated',
                text: text,
                routeName: 'item.view',
                routeSegment: item.get('id')
            });
        },

        itemNew: function itemNew(item) {

            var currentUser = this.get('sessionManager.user');

            return this._sendNotification({
                type: 'user_post_item',
                model: 'item',
                modelId: item.get('id'),
                heading: currentUser.get('nameLastInitial') + ' has added an item',
                text: '' + item.get('name'),
                routeName: 'item.view',
                routeSegment: item.get('id')
            });
        },

        claimSubmitted: function claimSubmitted(data, toUser) {
            data.type = 'my_item_claimed';
            data.model = 'item';
            return this._sendNotificationTo(data, toUser);
        },

        claimAccepted: function claimAccepted(data, toUser) {
            data.type = 'claim_accepted';
            data.model = 'item';
            return this._sendNotificationTo(data, toUser);
        },

        claimUnaccepted: function claimUnaccepted(data, toUser) {
            data.type = 'claim_unaccepted';
            data.model = 'item';
            return this._sendNotificationTo(data, toUser);
        },

        claimRejected: function claimRejected(data, toUser) {
            data.type = 'claim_rejected';
            data.model = 'item';
            return this._sendNotificationTo(data, toUser);
        },

        commentMade: function commentMade(data, toUser) {
            data.type = 'comment_made';
            data.model = 'item';
            return this._sendNotificationTo(data, toUser);
        },

        commentRepliedTo: function commentRepliedTo(data, toUser) {
            data.type = 'comment_replied_to';
            data.model = 'item';
            return this._sendNotificationTo(data, toUser);
        },

        chatSent: function chatSent(data, toUser) {
            data.type = 'user_send_message';
            return this._sendNotificationTo(data, toUser);
        },

        itemChangedStatus: function itemChangedStatus(data) {
            data.type = 'item_change_status';
            return this._sendNotification(data);
        },

        promptToRateUser: function promptToRateUser(data, toUser) {
            data.type = 'prompt_to_rate';
            return this._sendNotificationTo(data, toUser);
        },

        userReported: function userReported(data) {
            data.type = 'user_reported';
            return this._sendNotification(data);
        },

        newUserCreated: function newUserCreated(data) {
            data.type = 'user_signed_up';
            return this._sendNotification(data);
        }

    });
});