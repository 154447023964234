define('hyve-frontend/components/claim-item', ['exports', 'ember'], function (exports, _ember) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    var service = _ember['default'].inject.service;
    var Promise = _ember['default'].RSVP.Promise;
    exports['default'] = _ember['default'].Component.extend({

        chatService: service('chat'),

        rejectionModal: false,

        acceptModal: false,

        rejectionReason: '',

        /**
         * Should action buttons on this component be active
         * @return boolean
         */
        actionButtonsDisabled: _ember['default'].computed('claim.rejected', 'activeClaim', 'claim.accepted', function () {
            if (!this.get('activeClaim')) {
                return true;
            }
        }),

        claimClass: _ember['default'].computed('claim.rejected', 'activeClaim', 'claim.accepted', function () {

            if (this.get('claim.accepted')) {

                return 'accepted';
            }

            if (this.get('claim.rejected')) {
                return 'rejected';
            }

            if (this.get('activeClaim')) {
                return 'active-item';
            }

            return 'pending';
        }),

        actions: {
            showRejectionModal: function showRejectionModal() {
                if (!this.get('actionButtonsDisabled')) {
                    this.set('rejectionModal', true);
                }
            },
            hideRejectionModal: function hideRejectionModal() {
                this.set('rejectionModal', false);
            },

            showAcceptModal: function showAcceptModal() {
                if (!this.get('actionButtonsDisabled')) {
                    this.set('acceptModal', true);
                }
            },

            hideAcceptModal: function hideAcceptModal() {
                this.set('acceptModal', false);
            },

            acceptClaim: function acceptClaim() {
                if (!this.get('actionButtonsDisabled')) {
                    this.set('acceptModal', false);
                    this.claim.acceptClaim();
                }
            },
            rejectClaim: function rejectClaim() {
                this.claim.rejectWithReason(this.rejectionReason);
                this.set('rejectionModal', false);
            },

            unreject: function unreject() {
                this.claim.unreject();
            },

            unacceptClaim: function unacceptClaim() {
                this.claim.unaccept();
            },

            chatWithUser: function chatWithUser() {
                var _this = this;

                if (this.get('claim.chatSent')) {
                    // just open chat
                    this.get('claim.user').then(function (user) {
                        _this.get('chatService').openChat(user);
                    });
                } else {
                    Promise.all([this.get('claim.user'), this.get('claim.item')]).then(function (_ref) {
                        var _ref2 = _slicedToArray(_ref, 2);

                        var user = _ref2[0];
                        var item = _ref2[1];

                        _this.get('chatService').sendMessageToUser(user, 'Re: claim for "' + item.get('name') + '"');
                        _this.get('claim').set('chatSent', true);
                        _this.get('claim').save();

                        // if mobile transition to mobile screen
                    });
                }

                if (this.get('media.isMobile')) {
                    this.get('claim.user').then(function (user) {
                        return _this.get('chatService').findOrCreateGroup(user);
                    }).then(function (group) {
                        _this.get('container').lookup('router:main').transitionTo('message.view', group.get('id'));
                    });
                }
            }
        }
    });
});