define('hyve-frontend/initializers/inactive-checker', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'foo', 'service:inactive-checker');
  }

  exports['default'] = {
    name: 'inactive-checker',
    initialize: initialize
  };
});