define('hyve-frontend/initializers/newsletter', ['exports', 'hyve-frontend/config/environment'], function (exports, _hyveFrontendConfigEnvironment) {
    exports.initialize = initialize;

    function initialize(application) {
        application.register('newsletter:link', _hyveFrontendConfigEnvironment['default'].newsLetterLink, { instantiate: false });
        application.inject('component', 'newsLetterLink', 'newsletter:link');
    }

    exports['default'] = {
        name: 'newsletter',
        initialize: initialize
    };
});