define('hyve-frontend/controllers/application', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Controller.extend({

        newsLetterLink: 'http://example.com',

        actions: {
            transitionToLoginRoute: function transitionToLoginRoute() {
                this.transitionToRoute('login');
            }
        }
    });
});