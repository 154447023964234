define('hyve-frontend/components/mobile-nav-bar', ['exports', 'ember'], function (exports, _ember) {
    var inject = _ember['default'].inject;
    exports['default'] = _ember['default'].Component.extend({

        sessionManager: inject.service(),

        tagName: 'ul',
        classNames: 'mobile-nav-bar'.w()

    });
});