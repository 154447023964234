define('hyve-frontend/utils/thumbnailUrls', ['exports', 'hyve-frontend/config/environment'], function (exports, _hyveFrontendConfigEnvironment) {
    exports['default'] = thumbnailUrls;

    function thumbnailUrls() {
        var url = arguments.length <= 0 || arguments[0] === undefined ? '' : arguments[0];

        if (!url) {
            return {};
        }
        var ext = url.match(/\.[^\.]+$/g)[0];

        url = url.replace(ext, '');

        ext = ext.replace('jpeg', 'jpg');

        var thumbnailDirectories = _hyveFrontendConfigEnvironment['default'].thumnailDirectories;
        var originalDirectory = _hyveFrontendConfigEnvironment['default'].iamgeOriginalDirectory;

        return thumbnailDirectories.reduce(function (prev, curr, index) {
            prev[index] = '' + url.replace('/' + originalDirectory + '/', '/' + curr + '/') + ext;
            return prev;
        }, {});
    }
});