define('hyve-frontend/components/category-form', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    var Promise = _ember['default'].RSVP.Promise;
    exports['default'] = _ember['default'].Component.extend({

        store: service(),

        actions: {

            addSubCategory: function addSubCategory() {
                // create a subcategory, but dont call save on it yet.
                var childCategory = this.get('store').createRecord('category', {
                    parent: this.get('model')
                });
                this.get('model.children').pushObject(childCategory);
            },

            removeSubCategory: function removeSubCategory(cat) {
                this.get('model.children').removeObject(cat);
                cat.destroyRecord();
            },

            saveGroup: function saveGroup() {
                var _this = this;

                this.get('model').save();
                // save all the children then the parent
                var childrenPromises = Promise.all(this.get('model.children').map(function (c) {
                    return c.save();
                }));
                childrenPromises.then(function () {
                    return _this.get('model').save();
                }).then(function () {
                    _this.sendAction('saveComplete');
                });
            }
        }
    });
});