define('hyve-frontend/components/upload/upload-indicator', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    exports['default'] = _ember['default'].Component.extend({

        classNames: 'upload-box__file-indicator indicator'.w(),

        classNameBindings: 'complete:complete'.w(),

        uploader: null,

        percent: 0,

        filename: '',

        myArray: [{
            isComplete: true
        }],

        complete: computed.gte('percent', 100),

        fileUrl: null,

        errors: [],

        hasErrors: computed.notEmpty('errors'),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            // bind progress
            this.get('uploader').on('progress', function (e) {
                return _this.set('percent', e.percent);
            });

            this.get('uploader').on('didError', function (jqXHR, textStatus, errorThrown) {
                _this.get('errors').push(textStatus);
            });

            this.get('uploader').on('didUpload', function (url) {
                // remove from parent
                _this.get('parentView').removeUploader(_this.get('uploader'));
            });

            this.set('filename', this.get('uploader.filename'));
        }

    });
});