define('hyve-frontend/models/item', ['exports', 'ember-data', 'ember', 'npm:truncate', 'hyve-frontend/config/environment', 'npm:moment', 'npm:co', 'ember-concurrency', 'ember-cp-validations'], function (exports, _emberData, _ember, _npmTruncate, _hyveFrontendConfigEnvironment, _npmMoment, _npmCo, _emberConcurrency, _emberCpValidations) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

    var service = _ember['default'].inject.service;
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;
    var hasMany = _emberData['default'].hasMany;

    var Validations = (0, _emberCpValidations.buildValidations)({
        name: {
            description: 'Name of Item',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        },
        description: {
            description: 'Description of Item',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        },
        price: {
            description: 'Price of Item',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
                allowString: true,
                integer: false,
                gte: 15,
                lte: 100000, //todo: allow to be super user editable
                message: '{description} must be between ${gte} and ${lte}'
            })]
        },
        pickRadius: {
            description: 'Pickup Radius',
            validators: [(0, _emberCpValidations.validator)('number', {
                allowString: true,
                integer: true,
                gte: 1
            })]
        },
        pickupZipCode: {
            description: 'Pickup Zipcode',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('valid-zipcode', {
                message: '{description} is invalid'
            })]
        },
        categories: {
            description: "Categories",
            validators: [(0, _emberCpValidations.validator)('length', {
                min: 1,
                message: 'Must have at least one category'
            })]
        }
    });

    exports['default'] = _emberData['default'].Model.extend(Validations, {
        sessionManager: service(),
        notificationSender: service(),
        notificationMessages: service(),
        googleMapsApi: service(),
        gMaps: service('google-maps-api'),

        name: attr('string'),
        latLng: _ember['default'].computed('geoCoordinates', function () {
            var _get$split = this.get('geoCoordinates').split(',');

            var _get$split2 = _slicedToArray(_get$split, 2);

            var lat = _get$split2[0];
            var lng = _get$split2[1];

            return {
                lat: lat, lng: lng
            };
        }),
        description: attr('string', {
            defaultValue: function defaultValue() {
                return '';
            }
        }),
        price: attr('number'),
        images: hasMany('image', { cascadeDelete: true }),
        comments: hasMany('comment'),

        isSoldTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var isPendingTransaction, claims;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('isPendingTransaction');

                    case 2:
                        isPendingTransaction = context$1$0.sent;

                        if (!isPendingTransaction) {
                            context$1$0.next = 5;
                            break;
                        }

                        return context$1$0.abrupt('return', false);

                    case 5:
                        context$1$0.next = 7;
                        return this.get('claim');

                    case 7:
                        claims = context$1$0.sent;
                        return context$1$0.abrupt('return', claims.any(function (c) {
                            return c.get('accepted') && c.get('claimFinalizedAt') > new Date(0);
                        }));

                    case 9:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        isSold: _ember['default'].computed('claim.@each.accepted', function () {
            return this.get('isSoldTask').perform();
        }),

        isPendingTransactionTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var claims;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('claim');

                    case 2:
                        claims = context$1$0.sent;
                        return context$1$0.abrupt('return', claims.any(function (c) {
                            return c.get('accepted') && c.get('claimFinalizedAt').getTime() == new Date(0).getTime();
                        }));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        isPendingTransaction: _ember['default'].computed('claim.@each.accepted', function () {
            return this.get('isPendingTransactionTask').perform();
        }),

        acceptedClaim: _ember['default'].computed('claim.@each.accepted', function () {
            return this.get('acceptedClaimTask').perform();
        }),

        acceptedClaimTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var claims;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('claim');

                    case 2:
                        claims = context$1$0.sent;
                        return context$1$0.abrupt('return', claims.find(function (c) {
                            return c.get('accepted');
                        }));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        finalizeTransaction: function finalizeTransaction() {
            return this.get('finalizeTransactionTask').perform();
        },

        finalizeTransactionTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var acceptedClaim, sender, buyer, seller;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('acceptedClaim');

                    case 2:
                        acceptedClaim = context$1$0.sent;

                        acceptedClaim.set('claimFinalizedAt', new Date());

                        // send a notification to the buyer
                        sender = this.get('notificationSender');
                        context$1$0.next = 7;
                        return acceptedClaim.get('user');

                    case 7:
                        buyer = context$1$0.sent;
                        context$1$0.next = 10;
                        return this.get('user');

                    case 10:
                        seller = context$1$0.sent;
                        context$1$0.next = 13;
                        return sender.promptToRateUser({
                            modelId: acceptedClaim.get('id'),
                            heading: 'Please rate ' + seller.get('nameLastInitial'),
                            text: 'Please rate ' + seller.get('nameLastInitial'),
                            routeName: 'rating.buyerToSellerRating',
                            routeSegment: acceptedClaim.get('id')
                        }, buyer);

                    case 13:
                        context$1$0.next = 15;
                        return acceptedClaim.save();

                    case 15:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 16:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        /**
         * Could be multiple claims, only one of which could be accepted
         */
        claim: hasMany('claim', { inverse: 'item', cascadeDelete: true }),

        transactionComplete: attr('boolean', {
            'default': function _default() {
                return false;
            }
        }),

        user: belongsTo('user', {
            async: true
        }),

        acceptedPaymentMethods: attr('string'),

        pickupZipCode: attr('string'),
        pickRadius: attr('number', {
            defaultValue: function defaultValue() {
                return 10;
            }
        }),
        priceIsNegotiable: attr('boolean', false),
        geoCoordinates: attr('string'),
        categories: hasMany('category'),
        timesViewed: attr('number', {

            defaultValue: function defaultValue() {
                return 0;
            }
        }),

        createdAt: attr('date', {
            defaultValue: function defaultValue() {
                return new Date();
            }
        }),

        publishStart: attr('date', {
            defaultValue: function defaultValue() {
                // default is not published
                return new Date(0);
            }
        }),

        favorited: _ember['default'].computed('sessionManager.user.favorites.[]', function () {
            var _this = this;

            // check if the current user favorites
            return _emberData['default'].PromiseObject.create({
                promise: this.get('sessionManager.user.favorites').then(function (favorites) {
                    return favorites.contains(_this);
                })
            });
        }),

        dbPrimaryImage: belongsTo('image', { inverse: false }),

        pImage: null,

        setPrimaryImage: function setPrimaryImage(image) {
            this.set('dbPrimaryImage', image);
            return this.save();
        },

        primaryImageTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var primaryImage;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('dbPrimaryImage');

                    case 2:
                        primaryImage = context$1$0.sent;

                        if (!primaryImage) {
                            context$1$0.next = 5;
                            break;
                        }

                        return context$1$0.abrupt('return', primaryImage);

                    case 5:
                        return context$1$0.abrupt('return', this.get('images.firstObject'));

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        primaryImage: _ember['default'].computed('dbPrimaryImage', function () {
            return this.get('primaryImageTask').perform();
        }),

        /**
         * Short description
         */
        truncatedDescription: _ember['default'].computed('description', function () {
            var sanitizedDescription = this.get('description').replace(/(<([^>]+)>)/ig, "");
            return (0, _npmTruncate['default'])(sanitizedDescription, 250);
        }),

        displayName: _ember['default'].computed('isClaimed', function () {
            return this.get('name');
        }),

        claimStatusText: _ember['default'].computed('isClaimed', 'claim.@each.claimFinalizedAt', function () {
            return this.get('claimStatusTextTask').perform();
        }),

        claimStatusTextTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var isPendingTransaction, isSold, numberOfClaims;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('isPendingTransaction');

                    case 2:
                        isPendingTransaction = context$1$0.sent;

                        if (!isPendingTransaction) {
                            context$1$0.next = 5;
                            break;
                        }

                        return context$1$0.abrupt('return', 'Pending Transaction');

                    case 5:
                        context$1$0.next = 7;
                        return this.get('isSold');

                    case 7:
                        isSold = context$1$0.sent;

                        if (!isSold) {
                            context$1$0.next = 10;
                            break;
                        }

                        return context$1$0.abrupt('return', 'Finalized');

                    case 10:
                        numberOfClaims = this.get('claim.length');
                        return context$1$0.abrupt('return', numberOfClaims + ' claims');

                    case 12:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        /**
         * Item can be only be modified by the user who owns the item
         */
        canBeModified: _ember['default'].computed('user', 'sessionManager.user', function () {
            return this.get('user.id') === this.get('sessionManager.user.id');
        }),

        isMyItem: _ember['default'].computed.alias('canBeModified'),

        /**
         * Currently logged in user has claimed the item
         */
        claimedByCurrentUser: _ember['default'].computed('claim.[]', 'user', function () {
            var claims = this.get('claim');
            var ids = claims.map(function (c) {
                return c.get('user.id');
            });
            return ids.includes(this.get('sessionManager.user.id'));
        }),

        /**
         * User has claimed the item, but is not the first claim (in the wait list)
         */
        userInWaitListForItem: _ember['default'].computed('claim.[]', 'user', function () {
            if (!this.get('claim.length')) return false;

            var _get$sortBy = this.get('claim').sortBy('createdAt');

            var _get$sortBy2 = _toArray(_get$sortBy);

            var firstClaim = _get$sortBy2[0];

            var remainingClaims = _get$sortBy2.slice(1);

            var ids = remainingClaims.map(function (c) {
                return c.get('user.id');
            });
            return ids.includes(this.get('sessionManager.user.id'));
        }),

        favoritors: hasMany('user', { inverse: 'favorites' }),

        save: function save() {
            var _this2 = this;

            var wasNew = this.get('isNew');
            var changedAttributes = this.changedAttributes();
            // overwrite for notification purposes
            var parentPromise = this._super.apply(this, arguments);

            var sender = this.get('notificationSender');

            parentPromise.then(function () {
                return wasNew ? sender.itemNew(_this2) : sender.itemUpdated(_this2, changedAttributes);
            });

            return parentPromise;
        },

        isInPriceRange: function isInPriceRange(min, max) {
            var price = this.get('price');
            return price >= min && price <= max;
        },

        validClaims: _ember['default'].computed('claim.[]', 'item.claim.@each.rejected', function () {
            return this.get('validClaimsTask').perform();
        }),

        validClaimsTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var claims;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.prev = 0;
                        context$1$0.next = 3;
                        return this.get('claim');

                    case 3:
                        claims = context$1$0.sent;
                        return context$1$0.abrupt('return', claims.sortBy('createdAt').filter(function (c) {
                            return !c.get('rejected');
                        }));

                    case 7:
                        context$1$0.prev = 7;
                        context$1$0.t0 = context$1$0['catch'](0);

                    case 9:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this, [[0, 7]]);
        })),

        popularityScore: _ember['default'].computed('timesViewed', 'favoritors.[]', function () {
            return this.get('favoritors.length') * 3 + (this.get('timesViewed') || 0);
        }),

        hasExpired: _ember['default'].computed('publishStart', function () {
            var publishStart = (0, _npmMoment['default'])(this.get('publishStart'));
            var today = (0, _npmMoment['default'])();
            return today.diff(publishStart, 'days') > parseInt(_hyveFrontendConfigEnvironment['default'].numDaysBeforeItemsExpire, 10);
        }),

        isPosted: _ember['default'].computed('isSold', 'hasExpired', 'acceptedClaim', function () {
            return (0, _npmCo['default'])(regeneratorRuntime.mark(function callee$1$0() {
                var isSold, acceptedClaim, today, claimedDate, dayDifference;
                return regeneratorRuntime.wrap(function callee$1$0$(context$2$0) {
                    while (1) switch (context$2$0.prev = context$2$0.next) {
                        case 0:
                            context$2$0.next = 2;
                            return this.get('isSold');

                        case 2:
                            isSold = context$2$0.sent;

                            if (!isSold) {
                                context$2$0.next = 13;
                                break;
                            }

                            context$2$0.next = 6;
                            return this.get('acceptedClaim');

                        case 6:
                            acceptedClaim = context$2$0.sent;
                            today = (0, _npmMoment['default'])();
                            claimedDate = (0, _npmMoment['default'])(acceptedClaim.get('claimAcceptedAt'));
                            dayDifference = today.diff(claimedDate, 'days');
                            return context$2$0.abrupt('return', dayDifference <= 3);

                        case 13:
                            return context$2$0.abrupt('return', !this.get('hasExpired'));

                        case 14:
                        case 'end':
                            return context$2$0.stop();
                    }
                }, callee$1$0, this);
            }).bind(this));
        }),

        confirmModal: _ember['default'].inject.service(),

        unpost: function unpost() {
            var _this3 = this;

            var confirmModal = this.get('confirmModal');
            return confirmModal.confirm('Confirm Action', 'Drafting an item then re-posting an item will count against your posts per month').then(function () {
                _this3.set('publishStart', new Date(0));
                return _this3.save().then(function () {
                    _this3.get('notificationMessages').info('Successfully drafted', {
                        autoClear: true
                    });
                });
            });
        },

        post: function post() {
            var _this4 = this;

            // make sure the item is already saved
            var savedPromise = this.get('isNew') ? this.save() : _ember['default'].RSVP.Promise.resolve();

            return savedPromise.then(function () {
                return $.post(_hyveFrontendConfigEnvironment['default'].publishEndpoint + '/' + _this4.get('id')).then(function () {
                    _this4.get('notificationMessages').info('Successfully Posted', {
                        autoClear: true
                    });
                }).fail(function (e) {
                    _this4.get('user').then(function (user) {
                        if (user.get('isOutOfPosts')) {
                            _this4.get('notificationMessages').error('You are out of posts for this month. Please visit your account screen to upgrade your subscription');
                        }
                    });
                });
            });
        },

        imagesInOrder: _ember['default'].computed('images.@each.order', function () {
            return this.get('imagesInOrderTask').perform();
        }),

        imagesInOrderTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var images;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('images');

                    case 2:
                        images = context$1$0.sent;
                        return context$1$0.abrupt('return', images.sortBy('order'));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});

// get all claims on this task, if any claims are accepted the item is sold

// after 3 days of a claim accepted the item no longer appears in this list