define('hyve-frontend/components/mobile-messages', ['exports', 'ember', 'ember-concurrency', 'ember-gestures/mixins/recognizers'], function (exports, _ember, _emberConcurrency, _emberGesturesMixinsRecognizers) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend(_emberGesturesMixinsRecognizers['default'], {

        recognizers: 'swipe',

        store: service(),

        sessionManager: service(),

        activeGroups: _ember['default'].computed('sessionManager.user.visibleChatGroups.[]', function () {
            return this.get('activeGroupsTask').perform();
        }),

        activeGroupsTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('sessionManager.user.visibleChatGroups');

                    case 2:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 3:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});