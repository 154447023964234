define('hyve-frontend/components/num-posts-remaining', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        sessionManager: _ember['default'].inject.service(),

        numberOfPostsPerMonth: _ember['default'].computed('sessionManager.user.plan', function () {
            return this.get('sessionManager.user.plan.metadata.posts');
        })

    });
});