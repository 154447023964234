define('hyve-frontend/components/userprofile-flyout', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;

    function isDescendant(parent, child) {
        var node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    exports['default'] = _ember['default'].Component.extend({
        open: false,

        sessionManager: service('session-manager'),

        // component didMount
        didInsertElement: function didInsertElement() {
            var _this = this;

            // setup listener
            this._super.apply(this, arguments);
            this._clickListener = function (e) {
                return _this.onOutsideClick(e);
            };
            document.body.addEventListener('click', this._clickListener);
        },

        didDestroyElement: function didDestroyElement() {
            document.body.removeEventListener('click', this._clickListener);
        },

        onOutsideClick: function onOutsideClick(e) {

            if (!isDescendant(this.$().get(0), e.target)) {
                this.set('open', false);
            }
        },

        actions: {
            toggleOpen: function toggleOpen(e) {
                this.toggleProperty('open');
            },
            logout: function logout() {
                this.get('sessionManager').signOut();
            },
            login: function login() {
                this.get('sessionManager').signIn('facebook');
            }
        }
    });
});