define('hyve-frontend/mixins/cascade-delete-mixin', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {

    function flatten(arr) {
        var result = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

        for (var i = 0, _length = arr.length; i < _length; i++) {
            var value = arr[i];
            if (Array.isArray(value)) {
                flatten(value, result);
            } else {
                result.push(value);
            }
        }
        return result;
    }

    exports['default'] = _ember['default'].Mixin.create({

        getHasManyRelationshipItems: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(snapshot, name, descriptor) {
            var relationshipName, relationship;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        relationshipName = descriptor.key;
                        context$1$0.next = 3;
                        return snapshot.record.hasMany(relationshipName).load();

                    case 3:
                        relationship = context$1$0.sent;
                        return context$1$0.abrupt('return', relationship.toArray());

                    case 5:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        getBelongsToRelationshipItem: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(snapshot, name, descriptor) {
            var relationshipName, item;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        relationshipName = descriptor.key;
                        context$1$0.next = 3;
                        return snapshot.record.belongsTo(relationshipName).load();

                    case 3:
                        item = context$1$0.sent;
                        return context$1$0.abrupt('return', [item]);

                    case 5:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        deleteRecord: function deleteRecord(store, type, snapshot) {
            var _this = this;

            var promises = [];
            var parentDelete = this._super;

            snapshot.record.eachRelationship(function (name, descriptor) {
                var options = descriptor.options;
                var kind = descriptor.kind;

                if (options.cascadeDelete && kind === 'hasMany') {
                    promises.push(_this.get('getHasManyRelationshipItems').perform(snapshot, name, descriptor));
                }

                if (options.cascadeDelete && kind === 'belongsTo') {
                    promises.push(_this.get('getBelongsToRelationshipItem').perform(snapshot, name, descriptor));
                }
            });

            return _ember['default'].RSVP.Promise.all(promises).then(function (arrayOfArrayOfItemsToDelete) {
                return parentDelete.apply(_this, [store, type, snapshot]).then(function (response) {
                    var itemsToDelete = flatten(arrayOfArrayOfItemsToDelete);
                    var childDeletePromises = itemsToDelete.map(function (childRecord) {
                        return childRecord.destroyRecord();
                    });
                    return _ember['default'].RSVP.Promise.all(childDeletePromises).then(function () {
                        return response;
                    });
                });
            });
        }

    });
});