define('hyve-frontend/services/session-manager', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    var Evented = _ember['default'].Evented;
    var Promise = _ember['default'].RSVP.Promise;
    exports['default'] = _ember['default'].Service.extend(Evented, {
        session: service(),
        store: service(),
        eventBus: service(),
        notificationSender: service(),
        user: null,
        confirmModal: service(),
        inviteCodeGenerator: service(),

        firebaseUserData: null,

        referredUserTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(userId) {
            var user;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('store').findRecord('user', userId);

                    case 2:
                        user = context$1$0.sent;

                    case 3:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        _writeUserToSession: function _writeUserToSession(user) {
            var _this = this;

            user.set('online', true);
            this.set('user', user);
            return user.save().then(function (person) {
                person.ref().child('online').onDisconnect().set(false);
                _this.get('eventBus').publish('hasSessionUser', person);
                _this.get('user.updateFacebookTask').perform();
                return true;
            });
        },

        createNewUserTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(userData) {
            var data, newUser, userWhoRefereed, notificationSender, inviteCode;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        data = this.get('firebaseUserData');
                        newUser = this.get('store').createRecord('user');

                        newUser.set('online', true);

                        context$1$0.next = 5;
                        return this.get('store').findRecord('user', userData.userWhoReferred.id);

                    case 5:
                        userWhoRefereed = context$1$0.sent;

                        newUser.set('userWhoReferred', userWhoRefereed);
                        userWhoRefereed.get('referredUsers').pushObject(newUser);
                        context$1$0.next = 10;
                        return userWhoRefereed.save();

                    case 10:
                        context$1$0.next = 12;
                        return newUser.setFromSession(data, {
                            email: userData.email,
                            photoURL: data.currentUser.photoURL,
                            defaultSellZipCode: userData.defaultSellZipCode
                        });

                    case 12:
                        notificationSender = this.get('notificationSender');

                        notificationSender.newUserCreated({
                            heading: 'A new user signed up for BeeSwappy',
                            text: '\n                A new user signed up for BeeSwappy\n                Name: ' + newUser.get('firstName') + ' ' + newUser.get('lastName') + '\n                Email: ' + newUser.get('email') + '\n                Zip: ' + userData.defaultSellZipCode + '\n                Referred By: ' + userWhoRefereed.get('nameLastInitial') + '\n                Referring User Profile: https://' + window.location.host + '/profile/' + userWhoRefereed.get('id') + '\n                To take action against this user go to <a href="https://' + window.location.host + '/admin/users">users</a>\n                '
                        });

                        // generate a new code
                        context$1$0.next = 16;
                        return this.get('inviteCodeGenerator').generateCode();

                    case 16:
                        inviteCode = context$1$0.sent;

                        newUser.set('inviteCode', inviteCode);
                        context$1$0.next = 20;
                        return newUser.save();

                    case 20:

                        this.set('user', newUser);
                        // send admin notification for a new user
                        newUser.ref().child('online').onDisconnect().set(false);

                        return context$1$0.abrupt('return', true);

                    case 23:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        createNewUser: function createNewUser(userData) {
            return this.get('createNewUserTask').perform(userData);
        },

        promptForSignupInformation: function promptForSignupInformation() {

            var router = _ember['default'].getOwner(this).lookup('router:main');
            router.transitionTo('signup-information');
        },

        signIn: function signIn(provider, signUp) {
            var _this2 = this;

            var settings = {
                scope: 'user_location,user_friends'
            };

            if (this.get('session.isAuthenticated')) {
                return this.get('session').close().then(function () {
                    return _this2.signIn(provider, signUp);
                });
            }

            // const isIphone = navigator.standalone || false;

            var isIphone = function isIphone() {
                var ua = navigator.userAgent || navigator.vendor || window.opera;
                return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1 || navigator.standalone === true;
            };
            return this.get('session').open('firebase', { provider: provider, settings: settings, redirect: !signUp }).then(function (data) {
                return _this2.get('store').query('user', {
                    orderBy: 'uid',
                    equalTo: data.uid
                }).then(function (people) {
                    return new Promise(function (resolve, reject) {
                        if (!people.get('length')) {
                            _this2.set('firebaseUserData', data);
                            reject();
                            _this2.promptForSignupInformation();
                        } else {
                            return _this2._writeUserToSession(people.get('firstObject'));
                        }
                    }).then(function () {
                        return _this2.trigger('sessionAuthenticated');
                    })['catch'](function () {
                        console.log('prompting for information...');
                    });
                });
            })['catch'](function (e) {

                try {
                    window.localStorage.setItem('abc', '111');
                } catch (e) {
                    var confirmModal = _this2.get('confirmModal');
                    // could not log the user in because local storage is "above the quota"
                    // https://stackoverflow.com/questions/14555347/html5-localstorage-error-with-safari-quota-exceeded-err-dom-exception-22-anem
                    confirmModal.confirm('Error logging in', 'There has been an error in attempting to log you in. \nPlease make sure your browser is up to date or try a different browser. \nHave questions? Please contact us at admins@beeswappy.com.');
                }
            });
        },

        signOut: function signOut() {
            var _this3 = this;

            var user = this.get('user');
            user.set('online', false);
            Promise.all([this.get('session').close(), user.save()]).then(function () {
                _this3.trigger('sessionUnauthenticated');
                document.location.reload();
            });
        },

        userCreated: function userCreated() {
            var user = this.get('user');
            if (user) {
                return !user.get('isNew');
            }
            return false;
        }

    });
});