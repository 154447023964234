define('hyve-frontend/app', ['exports', 'ember', 'hyve-frontend/resolver', 'ember-load-initializers', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendResolver, _emberLoadInitializers, _hyveFrontendConfigEnvironment) {

    var App = undefined;

    if (_ember['default'].Debug && typeof _ember['default'].Debug.registerDeprecationHandler === 'function') {
        _ember['default'].Debug.registerDeprecationHandler(function (message, options, next) {
            if (options && options.until && options.until !== '2.9.0') {
                return;
            }
            next(message, options);
        });
    }

    _ember['default'].MODEL_FACTORY_INJECTIONS = true;

    App = _ember['default'].Application.extend({
        modulePrefix: _hyveFrontendConfigEnvironment['default'].modulePrefix,
        podModulePrefix: _hyveFrontendConfigEnvironment['default'].podModulePrefix,
        Resolver: _hyveFrontendResolver['default']
    });

    (0, _emberLoadInitializers['default'])(App, _hyveFrontendConfigEnvironment['default'].modulePrefix);

    exports['default'] = App;
});