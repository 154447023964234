define('hyve-frontend/components/other-items-by-user', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        userHasOtherItems: _ember['default'].computed('otherItems', function () {
            return this.get('otherItems.length') > 0;
        }),
        otherItems: _ember['default'].computed('item.user.items.[]', function () {
            var _this = this;

            return this.get('item.user.items').filter(function (item) {
                return _this.get('item.id') != item.get('id');
            });
        })
    });
});