define('hyve-frontend/routes/item/new', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend({
        title: 'BeeSwappy - New Item',
        sessionManager: service(),
        notify: service(),
        model: function model() {
            var item = this.store.createRecord('item', {
                isClaimed: false
            });
            var user = this.get('sessionManager.user');
            item.set('user', user);
            return item;
        },
        actions: {
            willTransition: function willTransition() {
                var item = this.controller.get('model');
                if (item.get('isNew')) {
                    this.store.unloadRecord(item);
                }
            },
            createItem: function createItem(item) {
                var _this = this;

                item.save().then(function () {
                    _this.get('notify').info('Saved!');
                })['catch'](function (err) {
                    console.log(err);
                    debugger;
                });
            },
            cancel: function cancel() {
                var item = this.controller.get('model');
                item.destroy();
                window.history.go(-1);
            }
        }
    });
});