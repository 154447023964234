define('hyve-frontend/components/item-form', ['exports', 'ember', 'npm:moment'], function (exports, _ember, _npmMoment) {
    var service = _ember['default'].inject.service;
    var get = _ember['default'].get;
    exports['default'] = _ember['default'].Component.extend({
        notify: service(),
        notificationMessages: service(),
        sessionManager: service(),
        notificationSender: service(),
        confirmModal: service(),
        title: _ember['default'].computed('model.name', function () {
            return this.get('model.name') || 'Untitled Item';
        }),

        submitDisabled: false,

        gMaps: service('google-maps-api'),

        canUseGeoLocation: (function () {
            return 'geolocation' in window.navigator;
        })(),

        hasNoZip: _ember['default'].computed.alias('model.pickupZipCode'),
        buttons: [],

        toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'color', 'insertLink', 'video'],

        init: function init() {
            this._super.apply(this, arguments);
            this.fail = this._fail.bind(this);
        },

        didInsertElement: function didInsertElement() {
            var _this = this;

            // add the default zipcode and payment method
            var user = this.get('sessionManager.user');
            if (user.get('defaultSellZipCode')) {
                this.set('model.pickupZipCode', user.get('defaultSellZipCode'));
            }

            if (user.get('defaultPaymentMethods')) {
                this.set('model.acceptedPaymentMethods', user.get('defaultPaymentMethods'));
            }

            if (!this.get('hasNoZip')) {
                // if the model does not have a pickup location, use the current users location
                window.navigator.geolocation.getCurrentPosition(function (loc) {
                    var lat = _ember['default'].get(loc, 'coords.latitude');
                    var lng = _ember['default'].get(loc, 'coords.longitude');
                    _this.get('gMaps').reverseGeocode(lat, lng).then(function (zip) {
                        if (!_this.get('hasNoZip')) {
                            _this.set('model.pickupZipCode', zip);
                        }
                    });
                });
            }
        },

        showErrors: false,

        _fail: function _fail() {
            this.set('showErrors', true);
            _ember['default'].$('body, html').animate({
                scrollTop: this.$().position().top
            });
        },

        actions: {

            saveAndPublish: function saveAndPublish(item) {
                return this.actions.save.call(this, item).then(function () {
                    return item.post();
                });
            },

            saveAndDraft: function saveAndDraft() {
                var _this2 = this;

                var item = this.get('model');
                var fail = this.get('_fail');
                if (!item.get('validations.isValid')) {
                    // not valid
                    this.set('showErrors', true);
                    _ember['default'].$('body, html').animate({
                        scrollTop: this.$().position().top
                    });
                    return _ember['default'].RSVP.Promise.reject();
                } else {
                    return item.save().then(function () {
                        item.unpost().then(function () {
                            _ember['default'].$('body, html').animate({
                                scrollTop: _this2.$().position().top
                            });
                        });
                    });
                }
            },

            save: function save(item) {
                var _this3 = this;

                // check for errors
                if (!item.get('validations.isValid')) {
                    // not valid
                    this.set('showErrors', true);
                    _ember['default'].$('body, html').animate({
                        scrollTop: this.$().position().top
                    });
                    return _ember['default'].RSVP.Promise.reject();
                } else {
                    item.set('createdAt', (0, _npmMoment['default'])(item.get('createdAt'), 'D MMMM, YYYY').toDate());

                    return item.save().then(function () {
                        _this3.get('notificationMessages').info('Saved', {
                            autoClear: true
                        });
                        _this3.get('sessionManager.user.items').pushObject(item);
                        return _this3.get('sessionManager.user').save().then(function () {
                            _ember['default'].$('body, html').animate({
                                scrollTop: _this3.$().position().top
                            });
                        });
                    });
                }
            },

            uploadStart: function uploadStart() {
                this.set('submitDisabled', true);
            },

            onUploadEnd: function onUploadEnd() {
                this.set('submitDisabled', false);
            },

            cancel: function cancel() {
                window.history.back(-1);
            },
            removeImage: function removeImage(image) {
                var item = this.get('model');
                item.get('images').removeObject(image);
                item.save();
            },
            markImageAsPrimary: function markImageAsPrimary(image) {
                var item = this.get('model');
                return item.setPrimaryImage(image);
            },
            getZipFromLocation: function getZipFromLocation() {
                this.set('geoCodeButtonText', 'Working...');
            },
            reorderItems: function reorderItems(group) {
                group.forEach(function (image, index) {
                    image.set('order', index);
                    image.save();
                });
            },
            'delete': function _delete() {
                var _this4 = this;

                var item = this.get('model');
                this.get('confirmModal').confirm('Delete', 'Are you sure you want to delete? This action is not reversible').then(function () {
                    item.destroyRecord().then(function () {
                        // this is a pretty hacky way of reloading the main model
                        _this4.get('container').lookup('router:main').router.transitionTo('/items');
                        _this4.get('notify').alert(item.get('name') + ' deleted!');
                    })['catch'](function () {
                        _this4.get('notify').alert('Unable to delete');
                    });
                });
            }
        }
    });
});