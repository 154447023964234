define('hyve-frontend/components/generic-dropdown', ['exports', 'ember'], function (exports, _ember) {

    function isDescendant(parent, child) {
        var node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    exports['default'] = _ember['default'].Component.extend({

        classNames: 'dropdown',

        unit: '',

        dropdownItems: [],

        activeItem: null,

        open: false,

        displayedValue: _ember['default'].computed('activeItem', 'defaultValue', function () {
            if (this.get('activeItem')) {
                return this.get('activeItem.text') + this.get('unit');
            }

            return this.get('defaultValue') + this.get('unit');
        }),

        // component didMount
        didInsertElement: function didInsertElement() {
            var _this = this;

            // setup listener
            this._super.apply(this, arguments);
            this._clickListener = function (e) {
                return _this.onOutsideClick(e);
            };
            document.body.addEventListener('click', this._clickListener);
        },

        didDestroyElement: function didDestroyElement() {
            document.body.removeEventListener('click', this._clickListener);
        },

        actions: {
            toggleSelectedItem: function toggleSelectedItem(item) {
                if (this.get('activeItem') === item) {
                    this.set('activeItem', null);
                } else {
                    this.set('activeItem', item);
                }
                this.set('open', false);
                this.sendAction('onSelect', item);
            },
            toggleOpen: function toggleOpen() {
                this.toggleProperty('open');
            }
        },

        onOutsideClick: function onOutsideClick(e) {

            if (!isDescendant(this.$().get(0), e.target)) {
                this.set('open', false);
            }
        }

    });
});