define('hyve-frontend/components/item-card', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['z-depth-0'],

        notify: service('notification-messages'),
        sessionManager: service(),
        confirmModal: service(),

        classNameBindings: ['isMobile:mobile-card', 'isDesktop:card'],

        isMobile: _ember['default'].computed.alias('media.isMobile'),

        isDesktop: _ember['default'].computed.or('media.isDesktop', 'media.isTablet'),

        showAdminDeleteButton: _ember['default'].computed('sessionManager.user', 'item.canBeModified', function () {
            if (this.get('item.canBeModified')) return false;
            if (this.get('sessionManager.user.isAdmin')) return true;
        }),

        actions: {
            'delete': function _delete(item) {
                var _this = this;

                this.get('confirmModal').confirm('Confirm Delete', 'Are you sure you want to remove this item?').then(function () {
                    item.destroyRecord().then(function () {
                        _this.get('notify').success(item.get('name') + ' deleted!');
                        _this.get('container').lookup('router:main').router.refresh();
                    })['catch'](function () {
                        _this.get('notify').error('Unable to delete');
                    });
                });
            }
        }
    });
});