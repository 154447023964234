define('hyve-frontend/services/confirm-modal', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({

        data: {},

        open: false,

        resolver: null,
        rejector: null,

        _promise: null,

        confirm: function confirm(header, text) {
            var _this = this;

            this.set('data.header', header);
            this.set('data.text', text);
            this.set('open', true);

            var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
                _this.set('resolver', resolve);
                _this.set('rejector', reject);
            });

            this.set('promise', promise);
            return promise;
        },

        closeModal: function closeModal() {
            this._cleanUp();
        },

        openModal: function openModal(data) {
            var _this2 = this;

            this.set('data', data);
            this.set('open', true);
            var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
                _this2.set('resolver', resolve);
                _this2.set('rejector', reject);
            });

            this.set('promise', promise);
            return promise;
        },

        _cleanUp: function _cleanUp() {
            this.set('data', {});
            this.set('open', false);
        },

        accept: function accept() {
            this.get('resolver')();
            this._cleanUp();
        },

        reject: function reject() {
            this.get('rejector')();
            this._cleanUp();
        }

    });
});