define('hyve-frontend/routes/message/view', ['exports', 'ember', 'hyve-frontend/mixins/auth'], function (exports, _ember, _hyveFrontendMixinsAuth) {
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {

        model: function model(_ref) {
            var id = _ref.id;

            return this.store.findRecord('group', id);
        }

    });
});