define('hyve-frontend/components/report-button', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        open: false,
        report: null,
        store: service(),
        sessionManager: service(),
        notificationSender: service(),

        classNames: 'btn btn--flat red'.w(),

        init: function init() {
            this._super.apply(this, arguments);
            var report = this.get('store').createRecord('report', {
                by: this.get('sessionManager.user'),
                on: this.get('user')
            });
            this.set('report', report);
        },

        didDestroyElement: function didDestroyElement() {
            var report = this.get('report');
            if (report.get('isNew')) {
                report.deleteRecord();
            }
        },

        click: function click() {
            this.set('open', true);
        },
        actions: {
            closeModal: function closeModal() {
                this.set('open', false);
            },
            save: function save() {
                var _this = this;

                this.get('report').save().then(function () {
                    // trigger an event
                    var sender = _this.get('notificationSender');
                    sender.userReported({
                        heading: _this.get('user.nameLastInitial') + ' has been reported',
                        routeName: 'admin.reports',
                        text: '\n                        ' + _this.get('sessionManager.user.nameLastInitial') + ' reported ' + _this.get('user.nameLastInitial') + '\n                        Message:\n                        ' + _this.get('report.reason') + '\n                        \n                        Go to the <a href="https://' + window.location.host + '/admin/reports">reports page</a> to take action\n                        '
                    }).then(function () {
                        _this.set('open', false);
                    });
                });
            }
        }
    });
});