define('hyve-frontend/models/claim', ['exports', 'ember', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations', 'ember-concurrency'], function (exports, _ember, _emberDataModel, _emberDataAttr, _emberDataRelationships, _emberCpValidations, _emberConcurrency) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    var service = _ember['default'].inject.service;

    var Validations = (0, _emberCpValidations.buildValidations)({
        message: {
            description: 'Message',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        },
        pickupTime: {
            description: 'Pickup time',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        }
    });

    exports['default'] = _emberDataModel['default'].extend(Validations, {
        user: (0, _emberDataRelationships.belongsTo)('user'),
        item: (0, _emberDataRelationships.belongsTo)('item', { inverse: 'claim' }),
        notificationSender: service(),
        sessionManager: service(),

        message: (0, _emberDataAttr['default'])('string'),
        pickupLocation: (0, _emberDataAttr['default'])('string'),
        pickupTime: (0, _emberDataAttr['default'])('string'),

        rejected: (0, _emberDataAttr['default'])('boolean', false),
        accepted: (0, _emberDataAttr['default'])('boolean', false),
        rejectionReason: (0, _emberDataAttr['default'])('string', ''),
        chatSent: (0, _emberDataAttr['default'])('boolean', false),

        /**
         * Rating from seller on buyer
         */
        buyerToSellerRating: (0, _emberDataRelationships.belongsTo)('rating'),

        /**
         * Rating on buyer form seller
         */
        sellerToBuyerRating: (0, _emberDataRelationships.belongsTo)('rating'),

        /**
         * Has this claim been finalized, E.g. the item has been exchanged
         */
        claimFinalizedAt: (0, _emberDataAttr['default'])('date', {
            defaultValue: function defaultValue() {
                return new Date(0);
            }
        }),
        claimAcceptedAt: (0, _emberDataAttr['default'])('date', {
            defaultValue: function defaultValue() {
                return new Date(0);
            }
        }),

        createdAt: (0, _emberDataAttr['default'])('date', {
            defaultValue: function defaultValue() {
                return new Date();
            }
        }),

        canBeAccepted: _ember['default'].computed('item.isClaimed', function () {
            return !this.get('item.isClaimed');
        }),

        rejectWithReason: function rejectWithReason(reason) {
            var _this = this;

            this.set('rejected', true);
            this.set('rejectionReason', reason);
            this.set('accepted', false);

            this.save().then(function () {
                var sender = _this.get('notificationSender');
                _ember['default'].RSVP.Promise.all([_this.get('item'), _this.get('user')]).then(function (_ref) {
                    var _ref2 = _slicedToArray(_ref, 2);

                    var item = _ref2[0];
                    var user = _ref2[1];

                    var loggedInUser = _this.get('sessionManager.user');
                    sender.claimRejected({
                        modelId: item.get('id'),
                        heading: loggedInUser.get('nameLastInitial') + ' has dismissed your claim for ' + item.get('name'),
                        text: reason,
                        routeName: 'item.view',
                        routeSegment: item.get('id')
                    }, user);
                });
            });
        },

        /**
         * claim has been accepted
         */
        acceptClaim: function acceptClaim() {
            return this.get('acceptClaimTask').perform();
        },

        acceptClaimTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var item;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('item');

                    case 2:
                        item = context$1$0.sent;

                        this.set('accepted', true);
                        this.set('claimAcceptedAt', new Date());
                        context$1$0.next = 7;
                        return this.save();

                    case 7:
                        context$1$0.next = 9;
                        return item.save();

                    case 9:
                        context$1$0.next = 11;
                        return this.get('sendNotificationThatClaimWasAcceptedTask').perform(item);

                    case 11:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        sendNotificationThatClaimWasAcceptedTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(item) {
            var user, sender, loggedInUser;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('user');

                    case 2:
                        user = context$1$0.sent;
                        sender = this.get('notificationSender');
                        loggedInUser = this.get('sessionManager.user');
                        context$1$0.next = 7;
                        return sender.claimAccepted({
                            modelId: item.get('id'),
                            heading: 'Your claim has been accepted',
                            text: loggedInUser.get('nameLastInitial') + ' has accepted your claim for ' + item.get('name'),
                            routeName: 'item.view',
                            routeSegment: item.get('id')
                        }, user);

                    case 7:
                        return context$1$0.abrupt('return', this.get('sendNotificationThatItemStatusChanged').perform(item));

                    case 8:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        sendNotificationThatItemStatusChanged: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(item) {
            var sender;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        sender = this.get('notificationSender');
                        context$1$0.next = 3;
                        return sender.itemChangedStatus({
                            modelId: item.get('id'),
                            heading: '' + item.get('name'),
                            text: 'Item status has changed: The claim has been accepted',
                            routeName: 'item.view',
                            routeSegment: item.get('id'),
                            model: 'item'
                        });

                    case 3:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        unreject: function unreject() {
            this.set('rejected', false);
            this.save();
        },

        unaccept: function unaccept() {
            var _this2 = this;

            this.get('item').then(function (item) {
                item.set('claimedBy', null);
                _this2.set('accepted', false);
                _this2.set('claimFinalizedAt', new Date(0));
                _this2.save();
                item.save().then(function () {
                    var sender = _this2.get('notificationSender');
                    _ember['default'].RSVP.Promise.all([_this2.get('item'), _this2.get('user')]).then(function (_ref3) {
                        var _ref32 = _slicedToArray(_ref3, 2);

                        var item = _ref32[0];
                        var user = _ref32[1];

                        var loggedInUser = _this2.get('sessionManager.user');

                        sender.claimUnaccepted({
                            modelId: item.get('id'),
                            heading: 'Claim has been unaccepted',
                            text: loggedInUser.get('nameLastInitial') + ' has unaccepted your claim for ' + item.get('name'),
                            routeName: 'item.view',
                            routeSegment: item.get('id')
                        }, user);
                    });
                });
            });
        }
    });
});