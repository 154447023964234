define('hyve-frontend/components/pickup-map', ['exports', 'ember'], function (exports, _ember) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        classNames: ['gmap'],

        radius: 10, // in miles

        gMaps: service('google-maps-api'),

        _prevCoords: null,

        map: null,

        didInsertElement: function didInsertElement() {
            this.renderMap();
        },

        milesToMeters: function milesToMeters(miles) {
            return miles / 0.00062137;
        },

        didUpdate: function didUpdate() {
            this.renderMap();
        },

        renderMap: function renderMap() {
            var _this = this;

            var dom = this.$()[0];

            var _get$split = this.get('coordinates').split(',');

            var _get$split2 = _slicedToArray(_get$split, 2);

            var lat = _get$split2[0];
            var lng = _get$split2[1];

            this.get('gMaps').gmapsInit().then(function (_ref) {
                var Map = _ref.Map;
                var Circle = _ref.Circle;

                var map = new Map(dom, {
                    center: { lat: Number(lat), lng: Number(lng) },
                    zoom: 8,
                    scrollwheel: false,
                    gestureHandling: 'cooperative'
                });

                var circle = new Circle({
                    strokeColor: '#388E3C',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#388E3C',
                    fillOpacity: 0.35,
                    map: map,
                    center: { lat: Number(lat), lng: Number(lng) },
                    radius: _this.milesToMeters(_this.get('radius'))
                });

                map.fitBounds(circle.getBounds());
                _this.set('map', map);
            });
        }

    });
});