define('hyve-frontend/components/current-payment-details', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        sessionManager: _ember['default'].inject.service(),

        last4: _ember['default'].computed.readOnly('sessionManager.user.creditCardLast4'),

        logo: _ember['default'].computed('sessionManager.user.creditCardBrand', function () {
            return (this.get('sessionManager.user.creditCardBrand') || '').toLowerCase().replace(/[^A-Za-z]/, '');
        }),

        hasPaymentDetails: _ember['default'].computed('sessionManager.user.creditCardLast4', 'sessionManager.user.creditCardBrand', function () {
            return this.get('sessionManager.user.creditCardBrand') && this.get('sessionManager.user.creditCardLast4');
        })

    });
});