define('hyve-frontend/models/report', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    var belongsTo = _emberData['default'].belongsTo;
    exports['default'] = _emberData['default'].Model.extend({

        by: belongsTo('user', { inverse: null }),
        on: belongsTo('user', { inverse: null }),
        reason: attr('string'),
        createdAt: attr('date', {
            defaultValue: function defaultValue() {
                return new Date();
            }
        }),
        read: attr('boolean', {
            defaultValue: function defaultValue() {
                return false;
            }
        })

    });
});