define('hyve-frontend/components/upload/ProgressUploader', ['exports', 'ember', 'ember-uploader', 'hyve-frontend/config/environment', 'hyve-frontend/utils/thumbnailUrls'], function (exports, _ember, _emberUploader, _hyveFrontendConfigEnvironment, _hyveFrontendUtilsThumbnailUrls) {
    var run = _ember['default'].run;
    var Promise = _ember['default'].RSVP.Promise;
    var $ = _ember['default'].$;

    /**
     * Customize the progress uploader for signing.
     * Then wait for all images to finish thumbnailing before saying the image has finished uploading.
     */
    exports['default'] = _emberUploader['default'].S3Uploader.extend({
        filename: '',

        image: null,

        timesToTry: 100,

        isThumbnailing: false,

        sendPutToAmazon: function sendPutToAmazon(file, signedRequest) {
            var _this = this;

            return new Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.open('PUT', signedRequest);
                xhr.setRequestHeader('x-amz-acl', 'public-read');

                xhr.upload.onprogress = function (e) {
                    return _this.didProgress(e);
                };

                xhr.onload = function (_) {
                    if (xhr.status === 200) {
                        run(null, resolve);
                    } else {
                        reject(xhr.status);
                    }
                };
                xhr.send(file);
            });
        },

        upload: function upload(file) {
            var _this2 = this;

            var extra = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

            return this.sign(file, extra).then(function (json) {

                _ember['default'].set(_this2, 'isUploading', true);
                return _this2.sendPutToAmazon(file, json.signedRequest).then(function () {
                    // wait for thumbnails to happen
                    _this2.set('isThumbnailing', true);
                    _this2.waitForThumbnails(json.url).then(function () {
                        _this2.set('isThumbnailing', false);
                        _this2.didUpload(json.url);
                    });
                });
            });
        },

        /**
         *
         * @return Promise
         */
        waitForThumbnails: function waitForThumbnails(baseUrl) {
            var _this3 = this;

            var thumbObj = (0, _hyveFrontendUtilsThumbnailUrls['default'])(baseUrl);
            thumbObj.length = Object.keys(thumbObj).length;
            var thumbnails = Array.from(thumbObj);

            var promises = thumbnails.map(function (url) {
                return _this3.pollForThumbnails(url);
            });
            return Promise.all(promises);
        },

        checkUrl: function checkUrl(url) {
            return new Promise(function (resolve, reject) {
                $.get(url).then(resolve).fail(reject);
            });
        },

        pollForThumbnails: function pollForThumbnails(url) {
            var _this4 = this;

            var timesTried = 0;
            return new Promise(function (resolve, reject) {
                var interval = setInterval(function () {
                    _this4.checkUrl(url).then(function () {
                        resolve(url);
                        clearInterval(interval);
                    })['catch'](function () {
                        if (timesTried >= _this4.get('timesToTry')) {
                            reject();
                            clearInterval(interval);
                        } else {
                            timesTried++;
                        }
                    });
                }, 1000);
            });
        }

    });
});