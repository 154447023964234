define('hyve-frontend/mixins/auth-admin', ['exports', 'ember'], function (exports, _ember) {

    var authRoute = 'login';
    var notAuthRoute = 'not-authorized';

    var service = _ember['default'].inject.service;
    var Mixin = _ember['default'].Mixin;
    exports['default'] = Mixin.create({

        /**
         * SessionManager service from app
         */
        sessionManager: service(),

        /**
         * Check whether the service is authenticated AND
         * the currently logged in user isAdmin
         */
        beforeModel: function beforeModel(transition) {
            if (!this.get('sessionManager.session.isAuthenticated')) {
                // save the attempted transition to apply later
                this.set('sessionManager.attemptedTransition', transition);
                return this.transitionTo(authRoute);
            }

            // check that user is an admin
            if (!this.get('sessionManager.user.isAdmin')) {
                return this.transitionTo(notAuthRoute);
            }

            return this._super.apply(this, arguments);
        }

    });
});