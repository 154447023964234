define('hyve-frontend/components/mobile-my-item', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        notify: service(),
        confirmModal: service(),

        actions: {
            'delete': function _delete(item) {
                var _this = this;

                this.get('confirmModal').confirm('Confirm delete', 'Are you sure you want to delete?').then(function () {
                    item.destroyRecord(item).then(function (item) {
                        // this is a pretty hacky way of reloading the main model
                        _this.get('container').lookup('router:main').router.refresh();
                        _this.get('notify').alert(item.get('name') + ' deleted!');
                    })['catch'](function () {
                        _this.get('notify').alert('Unable to delete');
                    });
                });
            },

            finalizeTransaction: function finalizeTransaction(item) {
                var _this2 = this;

                item.finalizeTransaction().then(function (claim) {
                    // transition to prompt for review
                    // /rating/claimId/buyer-rating
                    _this2.get('container').lookup('router:main').transitionTo('rating.sellerToBuyerRating', claim.get('id'));
                });
            },

            post: function post() {
                return this.get('item').post();
            },

            unpost: function unpost() {
                return this.get('item').unpost();
            }
        }
    });
});