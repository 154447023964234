define('hyve-frontend/components/comment-item', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        item: null,
        replying: false,
        classNames: ['comment-item'],
        actions: {
            reply: function reply() {
                this.set('replying', true);
            },
            closeReply: function closeReply() {
                this.set('replying', false);
            }
        }
    });
});