define('hyve-frontend/services/profile-image-updater', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {
    var $ = _ember['default'].$;
    exports['default'] = _ember['default'].Service.extend({

        thatHaveUpdated: _ember['default'].A(),
        sessionManager: _ember['default'].inject.service(),
        fbApi: _ember['default'].inject.service(),

        add: function add(facebookId, firebaseId) {
            if (!this.get('thatHaveUpdated').contains(facebookId)) {
                this.get('thatHaveUpdated').push(facebookId);
                this.updateUser(facebookId, firebaseId);
            }
        },

        updateUser: function updateUser(facebookId, firebaseId) {
            $.post(_hyveFrontendConfigEnvironment['default'].FB.profilePictureUpdate, { facebookId: facebookId, firebaseId: firebaseId });
        }

    });
});