define('hyve-frontend/components/rate-user', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        classNames: 'btn btn--flat blue'.w(),

        buttonText: 'Rate User',

        click: function click() {
            this.set('open', true);
        },

        actions: {
            closeModal: function closeModal() {
                this.set('open', false);
            },
            afterRatingSaved: function afterRatingSaved() {
                this.set('open', false);
            }
        }

    });
});