define('hyve-frontend/initializers/ember-stripe-service', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    application.register('config:ember-stripe-service', _hyveFrontendConfigEnvironment['default'], { instantiate: false });
    application.inject('service:stripe', 'config', 'config:ember-stripe-service');

    if (_hyveFrontendConfigEnvironment['default'].LOG_STRIPE_SERVICE) {
      _ember['default'].Logger.info('StripeService: initialize');
    }

    if (!_hyveFrontendConfigEnvironment['default'].stripe.publishableKey) {
      throw new _ember['default'].Error('StripeService: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js');
    }

    Stripe.setPublishableKey(_hyveFrontendConfigEnvironment['default'].stripe.publishableKey);
  }

  exports['default'] = {
    name: 'ember-stripe-stripe',
    initialize: initialize
  };
});
/* global Stripe */