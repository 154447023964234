define('hyve-frontend/components/mobile-group-link', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: ['dismissOpen'],
        sessionManager: service(),
        dismissOpen: false,

        swipeLeft: function swipeLeft() {
            this.set('dismissOpen', true);
        },

        swipeRight: function swipeRight() {
            this.set('dismissOpen', false);
        },

        foreignUsersTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var members;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('group.members');

                    case 2:
                        members = context$1$0.sent;
                        return context$1$0.abrupt('return', members.filter(function (m) {
                            return m.get('uid') != _this.get('sessionManager.user.uid');
                        }));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        foreignUsers: _ember['default'].computed('users.members.[]', function () {
            return this.get('foreignUsersTask').perform();
        }),

        lastMessage: _ember['default'].computed('group.messages.[]', 'group', function () {
            return this.get('group.messages.lastObject');
        }),

        actions: {
            close: function close() {
                this.get('sessionManager.user.visibleChatGroups').removeObject(this.get('group'));
                this.get('sessionManager.user').save();
                return false;
            }
        }

    });
});