define('hyve-frontend/routes/item/edit', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend({
        titleToken: function titleToken(model) {
            return model.get('name');
        },
        title: function title(tokens) {
            return 'BeeSwappy - Edit ' + tokens.join(' ');
        },
        notify: service(),
        model: function model(params) {
            return this.store.findRecord('item', params.id);
        }
    });
});