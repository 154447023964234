define('hyve-frontend/components/mutual-friends', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        numberMutualFriends: _ember['default'].computed.alias('mutualFriends.length'),

        fb: service('fb-api'),

        mutualFriends: _ember['default'].computed('user', function () {
            return this.get('mutualFriendsTask').perform();
        }),

        mutualFriendsTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var fn;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        fn = this.get('user.getMutualFriends');

                        if (!fn) {
                            context$1$0.next = 5;
                            break;
                        }

                        return context$1$0.abrupt('return', fn.call(this.get('user')));

                    case 5:
                        return context$1$0.abrupt('return', []);

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        mutualFriendsLengthTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var mutualFriends;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('mutualFriends');

                    case 2:
                        mutualFriends = context$1$0.sent;
                        return context$1$0.abrupt('return', mutualFriends.get('length'));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        mutualFriendsLength: _ember['default'].computed('mutualFriends.[]', function () {
            return this.get('mutualFriendsLengthTask').perform();
        }),

        pluralizedFriend: _ember['default'].computed('mutualFriends.[]', function () {
            if (this.get('mutualFriends.length') == 1) {
                return 'friend';
            }
            return 'friends';
        }),

        mutualFriendsPermissionGranted: _ember['default'].computed('user', 'fb.reRequestedPerms.[]', function () {
            return this.get('mutualFriendsPermissionGrantedTask').perform();
        }),

        mutualFriendsPermissionGrantedTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('fb').hasUserFriendsPermissions();

                    case 2:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 3:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        mutualFriendsError: _ember['default'].computed.alias('user.mutualFriendsError'),

        actions: {
            grantMutualFriendPerm: function grantMutualFriendPerm() {
                var _this = this;

                this.get('fb').login(function (response) {
                    _this.get('fb.reRequestedPerms').pushObject('friends');
                    console.log('re request perms' + response);
                }, {
                    scope: 'user_friends',
                    auth_type: 'rerequest'
                });
            }
        }

    });
});