define('hyve-frontend/services/notification-click-message', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Service.extend({

        sessionManager: service(),
        store: service(),
        chat: service(),

        call: function call(methodName) {
            var args = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

            var method = this.get(methodName);
            if (typeof method.perform === 'function') {
                var _get;

                (_get = this.get(methodName)).perform.apply(_get, _toConsumableArray(args));
            }
        },

        // methods
        openChatGroup: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(groupId) {
            var user, group;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        user = this.get('sessionManager.user');
                        context$1$0.next = 3;
                        return this.get('store').findRecord('group', groupId);

                    case 3:
                        group = context$1$0.sent;

                        // push the group onto the users visible chat group
                        user.get('visibleChatGroups').pushObject(group);
                        context$1$0.next = 7;
                        return user.save();

                    case 7:
                        this.get('chat').broadcastOpen(group);

                    case 8:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        transitionToRoute: function transitionToRoute(route, segment) {
            // transition to a route, and route segment
        }

    });
});

// open the group for this user