define('hyve-frontend/routes/admin/users', ['exports', 'ember', 'hyve-frontend/mixins/auth'], function (exports, _ember, _hyveFrontendMixinsAuth) {
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {
        session: _ember['default'].inject.service(),
        confirmModal: _ember['default'].inject.service(),

        model: function model() {
            return this.store.query('user', {}).then(function (users) {
                return users.filter(function (user) {
                    return user.get('facebookId');
                });
            });
        },

        actions: {
            'delete': function _delete(user) {
                var _this = this;

                this.get('confirmModal').confirm('Confirm user delete', 'Are you sure you want to delete ' + user.get('nameLastInitial') + '? This action is not reversible').then(function () {
                    user.destroyRecord().then(function () {
                        _this.refresh();
                    });
                });
            },
            ban: function ban(user) {
                user.ban();
            },
            unban: function unban(user) {
                user.unban();
            }
        }
    });
});