define('hyve-frontend/services/modal', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        init: function init() {
            this.shownModal = null;
        },
        show: function show(component) {
            var options = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

            var target = this.get('target');
            if (target) {
                target.show();
            } else {
                this.shownModal = component;
            }
        },
        setTarget: function setTarget(target) {
            this.set('target', target);
            if (target) {
                if (this.shownModal) {
                    target.show(this.shownModal);
                    this.shownModal = null;
                }
            }
        }
    });
});