define('hyve-frontend/routes/admin/reports', ['exports', 'ember', 'hyve-frontend/mixins/auth'], function (exports, _ember, _hyveFrontendMixinsAuth) {
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {
        model: function model() {
            return this.store.findAll('report');
        },
        actions: {
            ban: function ban(user) {
                user.ban();
            },
            unban: function unban(user) {
                user.unban();
            },
            deleteReport: function deleteReport(report) {
                report.destroyRecord();
            }
        }
    });
});