define('hyve-frontend/components/item-categories', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        groups: [],

        store: service(),

        categories: _ember['default'].computed('item.categories.[]', function () {
            return this.get('categoriesTask').perform();
        }),

        categoriesTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var allCategories;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('store').findAll('category');

                    case 2:
                        allCategories = context$1$0.sent;
                        return context$1$0.abrupt('return', _ember['default'].RSVP.filter(allCategories.toArray(), function (cat) {
                            return cat.get('parent').then(function (parent) {
                                return !parent;
                            });
                        }));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            toggleInList: function toggleInList(cat) {
                // check if the category is already in the items list
                var item = this.get('item');
                item.get('categories').then(function (categories) {
                    if (categories.contains(cat)) {
                        // remove
                        item.get('categories').removeObject(cat);
                    } else {
                        // add
                        item.get('categories').pushObject(cat);
                    }
                });
            }
        }

    });
});