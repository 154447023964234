define('hyve-frontend/models/rating', ['exports', 'ember-data'], function (exports, _emberData) {
    var attr = _emberData['default'].attr;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    var belongsTo = _emberData['default'].belongsTo;
    exports['default'] = _emberData['default'].Model.extend({
        author: belongsTo('user', { inverse: null }),
        on: belongsTo('user', { inverse: 'rawRatings' }),
        value: attr('number')
    });
});