define("hyve-frontend/templates/application", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 6
          }
        },
        "moduleName": "hyve-frontend/templates/application.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "site-wrapper");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "sidemenu-outlet");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("main");
        dom.setAttribute(el2, "class", "site-content");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "chat-bar");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [11]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(element0, 3, 3);
        morphs[1] = dom.createMorphAt(element0, 5, 5);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
        morphs[3] = dom.createMorphAt(element0, 9, 9);
        morphs[4] = dom.createMorphAt(element1, 1, 1);
        morphs[5] = dom.createMorphAt(element1, 3, 3);
        morphs[6] = dom.createMorphAt(element0, 13, 13);
        morphs[7] = dom.createMorphAt(element0, 15, 15);
        morphs[8] = dom.createMorphAt(element0, 17, 17);
        morphs[9] = dom.createMorphAt(element0, 19, 19);
        return morphs;
      },
      statements: [["inline", "content-backdrop", [], ["menuId", "main"], ["loc", [null, [5, 4], [5, 38]]], 0, 0], ["inline", "main-navigation", [], ["onLogin", "transitionToLoginRoute"], ["loc", [null, [7, 4], [7, 57]]], 0, 0], ["content", "outlet", ["loc", [null, [10, 8], [10, 18]]], 0, 0, 0, 0], ["content", "main-footer", ["loc", [null, [13, 4], [13, 21]]], 0, 0, 0, 0], ["content", "chat-groups", ["loc", [null, [16, 8], [16, 25]]], 0, 0, 0, 0], ["content", "chat-userlist", ["loc", [null, [17, 8], [17, 28]]], 0, 0, 0, 0], ["content", "mobile-nav-bar", ["loc", [null, [20, 4], [20, 24]]], 0, 0, 0, 0], ["inline", "notification-container", [], ["position", "top-right"], ["loc", [null, [22, 4], [22, 51]]], 0, 0], ["content", "md-modal-container", ["loc", [null, [23, 4], [23, 26]]], 0, 0, 0, 0], ["content", "confirm-modal", ["loc", [null, [24, 4], [24, 23]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});