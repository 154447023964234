define('hyve-frontend/components/logo-options', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        tagName: 'a',

        currentIndex: 0,

        options: [{
            url: '/images/logo-options/beeswappy-logo-1.svg'
        }, {
            url: '/images/logo-options/beeswappy-logo-2.svg'
        }, {
            url: '/images/logo-options/beeswappy-logo-3.svg'
        }, {
            url: '/images/logo-options/beeswappy-logo-4.svg'
        }, {
            url: '/images/hyve-logo.svg'
        }],

        currentOption: _ember['default'].computed('currentIndex', function () {
            console.log(this.get('options')[this.get('currentIndex')]);
            return this.get('options')[this.get('currentIndex')];
        }),

        click: function click() {
            var length = this.get('options.length');
            var current = this.get('currentIndex');
            var newIndex = current + 1 > length - 1 ? 0 : current + 1;
            this.set('currentIndex', newIndex);
        }

    });
});