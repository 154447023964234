define('hyve-frontend/validators/zip-code-in-target-area', ['exports', 'ember-cp-validations/validators/base', 'ember', 'npm:geolib', 'hyve-frontend/config/environment'], function (exports, _emberCpValidationsValidatorsBase, _ember, _npmGeolib, _hyveFrontendConfigEnvironment) {

    var ZipCodeInTargetArea = _emberCpValidationsValidatorsBase['default'].extend({

        gMaps: _ember['default'].inject.service('google-maps-api'),

        // isValidating: false,

        validate: function validate(value /*, options, model, attribute*/) {
            console.log(value);
            var geoCoder = this.get('gMaps');
            // this.set('isValiding', true);
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {

                return geoCoder.geocode(value).then(function (location) {
                    var _env$targetZipCoordinates = _hyveFrontendConfigEnvironment['default'].targetZipCoordinates;
                    var lat = _env$targetZipCoordinates.lat;
                    var lng = _env$targetZipCoordinates.lng;
                    var radius = _env$targetZipCoordinates.radius;

                    var distance = _npmGeolib['default'].getDistance({ latitude: location.lat(), longitude: location.lng() }, { latitude: lat, longitude: lng });

                    // this.set('isValiding', false);
                    if (distance > radius) {
                        // we are outside the radius
                        reject('Beeswappy is not in your zip code at this time.');
                    }
                    return true;
                });
            });
        }
    });

    ZipCodeInTargetArea.reopenClass({
        /**
         * Define attribute specific dependent keys for your validator
         *
         * @param {String}  attribute   The attribute being evaluated
         * @param {Unknown} options     Options passed into your validator
         * @return {Array}
         */
        getDependentsFor: function getDependentsFor() /* attribute, options */{
            return [];
        }
    });

    exports['default'] = ZipCodeInTargetArea;
});