define('hyve-frontend/mixins/auth-application', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

    var A = _ember['default'].A;
    var service = _ember['default'].inject.service;
    var bind = _ember['default'].run.bind;
    var get = _ember['default'].get;
    exports['default'] = _ember['default'].Mixin.create({

        sessionManager: service(),
        fb: service('fb-api'),
        eventBus: service(),

        session: service(),

        store: service(),

        init: function init() {
            this._super.apply(this, arguments);
            this._subscribeToSessionEvents();
        },

        beforeModel: function beforeModel() {
            return this.get('session').fetch()['catch'](function () {});
        },

        model: function model() {
            return this._restoreExistingSession();
        },

        /**
         * From the torii session check for an existing uid,
         * then restore the currently logged in user in the session
         * only happens on startup
         * @private
         */
        _restoreExistingSession: function _restoreExistingSession() {
            var _this = this;

            var uid = this.get('session.uid');
            var store = this.get('store');
            if (!uid) {
                return;
            }

            return store.query('user', { orderBy: 'uid', equalTo: uid }).then(function (people) {
                if (people.get('length')) {

                    people.get('firstObject').set('online', true);

                    var sessionManager = _this.get('sessionManager');
                    sessionManager.set('user', people.get('firstObject'));
                    sessionManager.get('user.updateFacebookTask').perform();

                    sessionManager.set('session', _this.get('session'));
                    _this.get('eventBus').publish('hasSessionUser', people.get('firstObject'));
                    // add this user to the rollbar config if Rollbar exists
                    if (window.Rollbar) {
                        var person = people.get('firstObject');
                        Rollbar.configure({
                            payload: {
                                person: {
                                    id: person.get('id'),
                                    username: person.get('firstName') + ' ' + person.get('lastName'),
                                    email: person.get('email')
                                }
                            }
                        });
                    }

                    return people.get('firstObject').save().then(function (person) {
                        person.ref().child('online').onDisconnect().set(false);

                        return person;
                    });
                } else {
                    return null;
                }
            });
        },

        /**
         * Setup listeners for session manager events
         * @private
         */
        _subscribeToSessionEvents: function _subscribeToSessionEvents() {
            var _this2 = this,
                _arguments = arguments;

            A([['sessionAuthenticated', 'sessionAuthenticated'], ['sessionUnauthenticated', 'sessionUnauthenticated']]).forEach(function (_ref) {
                var _ref2 = _slicedToArray(_ref, 2);

                var event = _ref2[0];
                var method = _ref2[1];

                _this2.get('sessionManager').on(event, bind(_this2, function () {
                    _this2[method].apply(_this2, _arguments);
                }));
            });
        },

        sessionAuthenticated: function sessionAuthenticated() {
            // check if there was an attempted transition
            var attemptedTransition = this.get('sessionManager.attemptedTransition');
            if (attemptedTransition) {
                attemptedTransition.retry();
                this.set('sessionManager.attemptedTransition', null);
            } else {
                this.transitionTo('index');
            }
        },

        sessionUnauthenticated: function sessionUnauthenticated() {
            document.location.reload();
        }

    });
});