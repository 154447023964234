define('hyve-frontend/routes/edit-profile', ['exports', 'ember', 'hyve-frontend/mixins/auth'], function (exports, _ember, _hyveFrontendMixinsAuth) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {

        sessionManager: service(),
        notifications: service('notification-messages'),

        title: 'BeeSwappy - Edit Profile',

        model: function model() {
            return this.get('sessionManager.user');
        },

        actions: {
            save: function save() {
                var _this = this;

                return this.currentModel.save().then(function () {
                    _this.router.transitionTo('items');
                    _this.get('notifications').success('Saved Profile Successufully', {
                        autoClear: true
                    });
                });
            }
        }
    });
});