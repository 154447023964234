define('hyve-frontend/components/item-claim-modal', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Component.extend({
        sessionManager: service(),
        store: service(),
        notificationSender: service(),
        claim: null,

        init: function init() {
            this._super.apply(this, arguments);
            var claim = this.get('store').createRecord('claim');
            this.set('claim', claim);
        },

        actions: {
            save: function save() {
                var _this = this;

                var claim = this.get('claim');
                var user = this.get('sessionManager.user');
                var item = this.get('item');

                user.claimItem(item, claim).then(function () {
                    _this.sendAction('close');
                    var sender = _this.get('notificationSender');
                    item.get('user').then(function (ownerUser) {

                        var text = 'Pickup Location ' + claim.get('pickupLocation') + '; Pickup time: ' + claim.get('pickupTime');

                        sender.claimSubmitted({
                            modelId: item.get('id'),
                            heading: user.get('nameLastInitial') + ' has submitted a claim for ' + item.get('name'),
                            text: text,
                            routeName: 'item.view',
                            routeSegment: item.get('id')
                        }, ownerUser);
                    });
                });
            }
        }
    });
});