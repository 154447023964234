define('hyve-frontend/services/stripe-account', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {
    var get = _ember['default'].get;
    exports['default'] = _ember['default'].Service.extend({

        sessionManager: _ember['default'].inject.service(),

        updateCustomerData: function updateCustomerData(customer) {
            throw new Error('Refusing to write ot user object from frontend');
        },

        createCustomerPromise: function createCustomerPromise() {
            var _this = this,
                _arguments = arguments;

            return new Promise(function (resolve, reject) {
                _this.createCustomer.apply(_this, _arguments).then(resolve).fail(reject);
            });
        },

        createCustomer: function createCustomer(stripeToken, customerEmail, planId, autoRenew) {
            return _ember['default'].$.ajax({
                url: _hyveFrontendConfigEnvironment['default'].stripeSubscription.createUrl,
                method: 'POST',
                data: {
                    stripeToken: stripeToken,
                    customerEmail: customerEmail,
                    userId: this.get('sessionManager.user.id'),
                    plan: planId,
                    renew: autoRenew
                }
            });
        },

        updateCustomer: function updateCustomer(stripeToken, customerEmail) {

            return _ember['default'].$.ajax({
                url: _hyveFrontendConfigEnvironment['default'].stripeSubscription.updateUrl,
                method: 'POST',
                data: {
                    stripeToken: stripeToken,
                    customerEmail: customerEmail,
                    stripeCustomerId: this.get('sessionManager.user.stripeCustomerId'),
                    userId: this.get('sessionManager.user.id')
                }
            });
        },

        reactivateSubscription: function reactivateSubscription() {
            return _ember['default'].$.ajax({
                url: _hyveFrontendConfigEnvironment['default'].stripeSubscription.reactivateSubscription,
                method: 'POST',
                data: {
                    stripeCustomerId: this.get('sessionManager.user.stripeCustomerId'),
                    userId: this.get('sessionManager.user.id')
                }
            });
        },

        cancelSubscription: function cancelSubscription() {
            return _ember['default'].$.ajax({
                url: _hyveFrontendConfigEnvironment['default'].stripeSubscription.cancelSubscription,
                method: 'POST',
                data: {
                    stripeCustomerId: this.get('sessionManager.user.stripeCustomerId'),
                    userId: this.get('sessionManager.user.id')
                }
            });
        },

        switchPlan: function switchPlan(newPlanId) {
            return _ember['default'].$.ajax({
                url: _hyveFrontendConfigEnvironment['default'].stripeSubscription.switchPlan + newPlanId,
                method: 'POST',
                data: {
                    firebaseUserId: this.get('sessionManager.user.id')
                }
            });
        }

    });
});