define('hyve-frontend/components/validated-form', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        showErrors: false,

        actions: {
            submit: function submit() {
                // validate the model, show errors if they are present
                if (!this.get('model.validations.isValid')) {
                    this.set('showErrors', true);
                    // scroll to the top of the form to show errors

                    _ember['default'].$('body, html').animate({
                        scrollTop: this.$().position().top
                    });
                } else {
                    this.sendAction('on-submit');
                }
            }
        }
    });
});