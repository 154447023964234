define('hyve-frontend/routes/rating/seller-to-buyer-rating', ['exports', 'ember', 'hyve-frontend/mixins/auth'], function (exports, _ember, _hyveFrontendMixinsAuth) {
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {

        notifications: _ember['default'].inject.service('notification-messages'),
        sessionManager: _ember['default'].inject.service(),

        model: function model(_ref) {
            var claimId = _ref.claimId;

            var claimPromise = this.store.findRecord('claim', claimId);
            var userPromise = claimPromise.then(function (claim) {
                return claim.get('user');
            });
            var itemPromise = claimPromise.then(function (claim) {
                return claim.get('item');
            });

            return RSVP.hash({
                claim: claimPromise,
                user: userPromise,
                item: itemPromise
            });
        },

        afterModel: function afterModel(model, transition) {
            var _this = this;

            if (this.get('sessionManager.user') === model.user) {
                this.router.replaceWith('items');
                this.get('notifications').warning('You cannot rate yourself', {
                    autoClear: true,
                    clearDuration: 5000
                });
            }

            // check if the model aready has a sellerToBuyerRating
            model.claim.get('sellerToBuyerRating').then(function (sellerToBuyerRating) {
                if (sellerToBuyerRating) {
                    _this.router.replaceWith('items');
                    _this.get('notifications').warning('You have already rated ' + model.user.get('nameLastInitial') + ' on ' + model.item.get('name'), {
                        autoClear: true,
                        clearDuration: 5000
                    });
                }
            });
        },

        actions: {
            afterRatingSaved: function afterRatingSaved(ratingRecord) {
                var _this2 = this;

                var _currentModel = this.currentModel;
                var claim = _currentModel.claim;
                var user = _currentModel.user;

                claim.set('sellerToBuyerRating', ratingRecord);
                claim.save().then(function () {

                    _this2.router.transitionTo('items');

                    _this2.get('notifications').info('Thank you! Your rating for ' + user.get('nameLastInitial') + ' has been saved', {
                        cssClasses: 'brand-info-notification',
                        autoClear: true,
                        clearDuration: 5000
                    });
                });
            }
        }

    });
});