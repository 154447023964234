define("hyve-frontend/initializers/side-menu", ["exports", "ember-side-menu/initializers/side-menu"], function (exports, _emberSideMenuInitializersSideMenu) {
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberSideMenuInitializersSideMenu["default"];
    }
  });
  Object.defineProperty(exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _emberSideMenuInitializersSideMenu.initialize;
    }
  });
});