define('hyve-frontend/components/notifications/top-bar', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;

    function isDescendant(parent, child) {
        var node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    exports['default'] = _ember['default'].Component.extend({

        sessionManager: service(),
        store: service(),
        analytics: service(),

        classNames: ['top-bar-notification'],

        count: 0,

        updateCount: _ember['default'].on('init', _ember['default'].observer('sessionManager.user.notifications.[]', 'sessionManager.user.notifications.@each.read', function () {
            var _this = this;

            this.get('sessionManager.user.notifications').then(function (notifications) {
                var unreadCount = (notifications || []).filter(function (n) {
                    return n.get('read') === false;
                }).length;
                _this.set('count', unreadCount);
            });
        })),

        open: false,

        _clickListener: null,

        didInsertElement: function didInsertElement() {
            var _this2 = this;

            this._super.apply(this, arguments);
            this._clickListener = function (e) {
                return _this2.onOutsideClick(e);
            };
            document.body.addEventListener('click', this._clickListener);
        },

        onOutsideClick: function onOutsideClick(e) {
            if (!isDescendant(this.$().get(0), e.target)) {
                this.set('open', false);
            }
        },

        didDestroyElement: function didDestroyElement() {
            document.body.removeEventListener(this._clickListener);
            console.log('removed');
        },

        /**
         * This is a crap way to order notifications
         * but i'm having difficulty ordering against the notification -> event relationship
         * this only works because Firebase keys are based on timestamp, and it will order based on creation
         * simply reverse the array. If notifications are generated in a different order than events this order will be wrong
         */
        notifications: _ember['default'].computed('sessionManager.user.notifications.[]', function () {
            return this.get('sessionManager.user.notifications').toArray().reverse();
        }),

        actions: {

            toggleOpen: function toggleOpen() {
                this.toggleProperty('open');
                if (this.get('open')) {
                    this.get('analytics').openNotifications();
                }
            },

            addNotification: function addNotification() {
                var notification = this.get('store').createRecord('notification', {
                    user: this.get('sessionManger.user'),
                    heading: 'Item Price Changed',
                    text: 'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
                });
                var user = this.get('sessionManager.user');
                user.get('notifications').pushObject(notification);
                user.save().then(function () {
                    notification.save().then(function () {
                        console.log('notificationSaved');
                    });
                });
            }
        }

    });
});