define('hyve-frontend/components/avatar-image', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        profileImageUpdater: _ember['default'].inject.service(),

        style: _ember['default'].computed('width', 'height', function () {
            return _ember['default'].String.htmlSafe('height: ' + this.get('height') + '; width: ' + this.get('width'));
        }),

        attributeBindings: ['image:src', 'style:style'],
        tagName: 'img',
        classNames: ['avatar'],

        height: _ember['default'].computed('width', function () {
            var size = this.get('width').replace(/[^\d]/g, '');
            return size * 1.15 + 'px';
        }).readOnly(),

        width: '20px',

        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);
            var img = new Image();
            img.addEventListener('error', function () {
                if (_this.get('user')) {
                    // if there is a user and the image load failed trigger the backend to update
                    _this.get('profileImageUpdater').add(_this.get('user.facebookId'), _this.get('user.id'));
                }
            });
            img.src = this.get('image');
        }

    });
});