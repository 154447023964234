define('hyve-frontend/services/event-bus', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
        publish: function publish() {
            return this.trigger.apply(this, arguments);
        },
        subscribe: function subscribe() {
            return this.on.apply(this, arguments);
        },
        unsubscribe: function unsubscribe() {
            return this.off.apply(this, arguments);
        }
    });
});