define('hyve-frontend/components/main-footer', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({
        session: service('sessionManager'),
        tagName: 'footer',
        classNames: ['page-footer'],
        year: new Date().getFullYear(),
        inviteFriendsService: service(),
        actions: {
            invite: function invite() {
                var mailToLink = this.get('inviteFriendsService.mailToLink');
                window.location = mailToLink;
                this.get('inviteFriendsService').triggerConfirmModal();
            }
        }
    });
});