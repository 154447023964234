define("hyve-frontend/templates/terms-of-use", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.7.0",
          "loc": {
            "source": null,
            "start": {
              "line": 91,
              "column": 8
            },
            "end": {
              "line": 91,
              "column": 51
            }
          },
          "moduleName": "hyve-frontend/templates/terms-of-use.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("privacy policy");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 94,
            "column": 6
          }
        },
        "moduleName": "hyve-frontend/templates/terms-of-use.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container generic-page");
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createTextNode("BEESWAPPY, LLC");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        var el3 = dom.createTextNode("TERMS OF USE");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("By accessing and/or using any strongBeeSwappy, LLC groups, platforms, pages, websites or content therefrom\n        (together,\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("strong");
        var el4 = dom.createTextNode("“BeeSwappy”");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("strong");
        var el4 = dom.createTextNode("“we,”");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("strong");
        var el4 = dom.createTextNode("“our”");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" or ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("strong");
        var el4 = dom.createTextNode("“us”");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("), you agree\n        to these Terms of Use (");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("strong");
        var el4 = dom.createTextNode("“TOU”");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("), last updated March 1, 2017, and as\n        may be updated from time to time at our sole discretion and without prior notice.\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "underline");
        var el4 = dom.createTextNode("LICENSE.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" If you are 18 or older, we grant you a limited, revocable, nonexclusive,\n        non-assignable,\n        non-sublicensable license to access BeeSwappy in compliance with the TOU; unlicensed access is unauthorized. You\n        agree not to license, distribute, make derivative works, display, sell, or “frame” content from BeeSwappy,\n        excluding content you create and sharing with friends/family. You grant us a perpetual, irrevocable, unlimited,\n        worldwide, fully paid/sublicensable license to use, copy, perform, display, distribute, and make derivative\n        works from content you post.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "underline");
        var el4 = dom.createTextNode("USE.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" You agree not to use or provide software or services that interact or\n        interoperate with BeeSwappy. Robots,\n        spiders, scripts, scrapers, crawlers, etc. are prohibited, as are misleading, unsolicited, unlawful, and/or spam\n        postings. You agree not to collect users’ personal and/or contact information.\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "underline");
        var el4 = dom.createTextNode("MODERATION.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" You agree we may moderate BeeSwappy access and use in our sole\n        discretion, e.g. by blocking,\n        filtering, deletion, delay, omission, verification, and/or access/account/license termination. You agree (1) not\n        to bypass said moderation, (2) that we are not liable for moderating, not moderating, or representations as to\n        moderating, and (3) nothing we say or do waives our right to moderate, or not. All site rules, see [insert\n        link], are incorporated herein.\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "underline");
        var el4 = dom.createTextNode("SALES.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" You authorize us to charge your account for BeeSwappy annual fees upon\n        submission of same. To the extent\n        permitted by law, fees are nonrefundable, even if your access to BeeSwappy is terminated.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "underline");
        var el4 = dom.createTextNode("DISCLAIMER.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" MANY JURISDICTIONS HAVE LAWS PROTECTING CONSUMERS AND OTHER CONTRACT\n        PARTIES, LIMITING THEIR ABILITY\n        TO WAIVE CERTAIN RIGHTS AND RESPONSIBILITIES. WE RESPECT SUCH LAWS; NOTHING HEREIN SHALL WAIVE RIGHTS OR\n        RESPONSIBILITIES THAT CANNOT BE WAIVED.\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("To the extent permitted by law, (1) we make no promise as to BeeSwappy, its completeness, accuracy, availability,\n        timeliness, propriety, security or reliability; (2) your access and use are at your own risk, and BeeSwappy is\n        provided “AS-IS” and “AS AVAILABLE”; (3) we are not liable for any harm resulting from (a) user content; (b)\n        user conduct, e.g. illegal conduct; (c) your BeeSwappy use; or (d) our representations; (4) WE AND OUR OFFICERS,\n        MEMBERS, EMPLOYEES, CONTRACTORS, AFFILIATES AND AGENTS (“BEESWAPPY ENTITIES”), DISCLAIM ALL WARRANTIES &\n        CONDITIONS, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE, OR NON-INFRINGEMENT; (5) WE\n        DO NOT WARRANT OR ASSUME ANY RESPONSIBILITY FOR ANY PRODUCT OF SERVICE OFFERED OR ADVERTISED BY ANY THIRD PARTY\n        THROUGH BEESWAPPY, AND WE SHALL NOT BE A PARTY TO ANY TRANSACTION THAT YOU MAY ENTER INTO WITH ANY BUYER OR\n        SELLER; (6) BEESWAPPY ENTITIES ARE NOT LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE\n        DAMAGES, OR ANY LOSS (E.G. OF PROFIT, REVENUE, DATA, OR GOODWILL); AND (7) IN NO EVENT SHALL OUR TOTAL LIABILITY\n        EXCEED WHAT YOU PAID US IN THE PAST YEAR.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "underline");
        var el4 = dom.createTextNode("RELEASE.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" Because we do not control buyers, sellers or any members of BeeSwappy,\n        you agree that you bear all risk\n        and you agree to release us (and our officers, members, agents, employees, contractors and affiliates) and our\n        designees from claims, demands and damages (actual and consequential) of every kind and nature, now and in the\n        future, arising out of or in any way connected with your use of BeeSwappy.\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "underline");
        var el4 = dom.createTextNode("CLAIMS.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" You agree (1) that any claim, cause of action or dispute (“Claim”) arising\n        out of or related to the TOU\n        or your BeeSwappy use is governed by Tennessee law regardless of your location or any conflict or choice of law\n        principle; (2) that Claims must be resolved exclusively by a state or federal court in Shelby County, Tennessee\n        (except we may seek injunctive remedy anywhere); (3) to submit to personal jurisdiction of said courts; (4) that\n        any Claim must be filed within one (1) year after it arose or be forever barred; (5) not to bring or take part\n        in a class action against BeeSwappy Entities; (6) to indemnify BeeSwappy Entities for any damage, loss and\n        expense (including, without limitation, attorneys’ fees) arising from claims related to your BeeSwappy use; and\n        (7) that you are liable for TOU breaches by any affiliates or agents paid by you, directly or indirectly.\n        Although we provide rules for member conduct, we do not control or direct members’ actions on BeeSwappy and are\n        not responsible for the content or information that members post, transmit or share on BeeSwappy. We are not\n        responsible for any offensive, inappropriate, obscene, unlawful or otherwise objectionable content or\n        information that may be encountered on BeeSwappy. We are not responsible for the conduct, whether online or\n        offline, of any member of BeeSwappy. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS OF USE, YOU ARE\n        WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION WITH RESPECT TO ANY CLAIMS ARISING IN\n        RELATION TO BEESWAPPY, OR PRODUCTS OR SERVICES OFFERED THROUGH BEESWAPPY.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "underline");
        var el4 = dom.createTextNode("MISCELLANEOUS.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" Users complying with prior written licenses may access BeeSwappy\n        thereby until authorization is\n        terminated; otherwise, this is the exclusive and entire agreement between us. Your access and/or use of\n        BeeSwappy after any amendments or updates to the TOU shall signify your acceptance of such revised terms.\n        Members must comply with all applicable laws when using or accessing BeeSwappy. Members also agree not to post\n        false, inaccurate, misleading, defamatory, or libelous content on BeeSwappy. If a TOU term is unenforceable,\n        other terms are unaffected. All content provided by BeeSwappy such as text, graphics and logos, and the name\n        “BeeSwappy” and derivatives thereof, is the property of BeeSwappy, LLC and is protected by United States\n        trademark and copyright laws. For information about how we collect, use and store data, please refer to our\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 23]), 2, 2);
        return morphs;
      },
      statements: [["block", "link-to", ["privacy-policy"], [], 0, null, ["loc", [null, [91, 8], [91, 63]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});