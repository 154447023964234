define('hyve-frontend/initializers/materialize-setup', ['exports'], function (exports) {
    exports.initialize = initialize;

    function initialize() /* application */{
        if (window && window.validate_field) {
            window.validate_field = function () {};
        }
    }

    exports['default'] = {
        name: 'materialize-setup',
        initialize: initialize
    };
});