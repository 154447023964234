define('hyve-frontend/services/invite-code-generator', ['exports', 'ember', 'npm:node-code-generator', 'ember-concurrency'], function (exports, _ember, _npmNodeCodeGenerator, _emberConcurrency) {
    exports['default'] = _ember['default'].Service.extend({

        firebase: _ember['default'].inject.service(),

        generateCode: function generateCode() {
            return this.get('generateCodeTask').perform();
        },

        generateCodeTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var codes, generator, pattern, options, generatedCodes;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('getExistingCodesTask').perform();

                    case 2:
                        codes = context$1$0.sent;
                        generator = new _npmNodeCodeGenerator['default']();
                        pattern = '*****';
                        options = {
                            sparsity: 100,
                            existingCodesLoader: function existingCodesLoader() {
                                return codes;
                            }
                        };
                        generatedCodes = generator.generateCodes(pattern, 1, options);
                        return context$1$0.abrupt('return', generatedCodes[0]);

                    case 8:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        getExistingCodesTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var firebase, usersSn, usersObjects, codes;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        firebase = this.get('firebase');
                        context$1$0.next = 3;
                        return firebase.child('users').once('value');

                    case 3:
                        usersSn = context$1$0.sent;
                        usersObjects = usersSn.val();
                        codes = Object.keys(usersObjects).map(function (key) {
                            var user = usersObjects[key];
                            return user.inviteCode;
                        });
                        return context$1$0.abrupt('return', codes);

                    case 7:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});