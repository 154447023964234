define('hyve-frontend/routes/admin/category/edit', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return this.store.findRecord('categoryGroup', params.id);
        },
        deactivate: function deactivate() {
            this.currentModel.rollbackAttributes();
        },
        actions: {
            saveComplete: function saveComplete() {
                this.transitionTo('admin.categories');
            }
        }
    });
});