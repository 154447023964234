define('hyve-frontend/routes/index', ['exports', 'ember', 'hyve-frontend/mixins/reset-scroll'], function (exports, _ember, _hyveFrontendMixinsResetScroll) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsResetScroll['default'], {

        sessionManager: service(),
        session: service(),

        model: function model() {
            return this.get('sessionManager.user');
        },

        afterModel: function afterModel() {
            var user = this.model();
            if (user && user.get('hasValidSubscription') && this.get('session.isAuthenticated')) {
                this.transitionTo('items');
            }
        },

        actions: {
            login: function login(signUp) {
                var _this = this;

                this.get('sessionManager').signIn('facebook', signUp).then(function () {
                    _this.refresh();
                });
            }
        }

    });
});