define('hyve-frontend/initializers/rollbar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = {
    name: 'rollbar',
    initialize: function initialize() {
      var errorLogger = _ember['default'].Logger.error;
      _ember['default'].Logger.error = function () {
        var args = Array.prototype.slice.call(arguments),
            err = isError(args[0]) ? args[0] : new Error(stringify(args));

        if (window.Rollbar) {
          Rollbar.error.call(Rollbar, err);
        }
        errorLogger.apply(this, arguments);
      };
      var warnLogger = _ember['default'].Logger.warn;
      _ember['default'].Logger.warn = function () {
        if (window.Rollbar) {
          Rollbar.warning.apply(Rollbar, arguments);
        }
        warnLogger.apply(this, arguments);
      };
      var infoLogger = _ember['default'].Logger.info;
      _ember['default'].Logger.info = function () {
        if (window.Rollbar) {
          Rollbar.info.apply(Rollbar, arguments);
        }
        infoLogger.apply(this, arguments);
      };
      var debugLogger = _ember['default'].Logger.debug;
      _ember['default'].Logger.debug = function () {
        if (window.Rollbar) {
          Rollbar.debug.apply(Rollbar, arguments);
        }
        debugLogger.apply(this, arguments);
      };
    }
  };

  var stringify = function stringify(object) {
    return JSON ? JSON.stringify(object) : object.toString();
  };

  var isError = function isError(object) {
    return _ember['default'].typeOf(object) === 'error';
  };
});
/* global Rollbar */