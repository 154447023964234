define('hyve-frontend/components/filterable-list', ['exports', 'ember', 'ember-concurrency', 'npm:geolib', 'npm:fuse.js'], function (exports, _ember, _emberConcurrency, _npmGeolib, _npmFuseJs) {

    var fuseOptions = {
        shouldSort: true,
        threshold: 0.2,
        location: 0,
        distance: 50,
        maxPatternLength: 32,
        minMatchCharLength: 3,
        keys: ['name', 'user.firstName', 'user.lastName'],
        getFn: function getFn(item, path) {
            return item.get(path);
        }
    };

    var service = _ember['default'].inject.service;
    var Promise = _ember['default'].RSVP.Promise;
    exports['default'] = _ember['default'].Component.extend({

        googleMapsApi: service(),
        analytics: service(),
        localStorage: service(),

        appliedFilterCategories: _ember['default'].A(),

        filterGroups: _ember['default'].A(),

        store: service(),

        distanceThreshold: 10,

        rawSearchText: '',

        _searchText: '',

        sortByValue: 'publishStart',

        geoCodeCache: new Map(),

        currentPage: 1,

        perPage: 16,

        localStorageKey: 'filterableList',

        getGeoCodeResult: function getGeoCodeResult(zip) {
            var _this = this;

            return new Promise(function (resolve, reject) {
                if (_this.get('geoCodeCache').has(zip)) {
                    resolve(_this.get('geoCodeCache').get(zip));
                }
                _this.get('googleMapsApi').geocode(zip).then(function (result) {
                    _this.get('geoCodeCache').set(zip, result);
                    resolve(result);
                });
            });
        },

        updateSearchText: _ember['default'].observer('rawSearchText', function () {
            var _this2 = this;

            _ember['default'].run.debounce(function () {
                _this2.set('_searchText', _this2.get('rawSearchText'));
                _this2.updateLocalStorage();
            }, 600);
            _ember['default'].run.debounce(function () {
                _this2.get('analytics').textSearch();
            }, 5000);
        }),

        uniqItems: function uniqItems(items) {
            var seenIds = [];
            return items.filter(function (i) {
                var id = i.get('id');
                if (seenIds.includes(id)) {
                    return false;
                } else {
                    seenIds.push(id);
                    return true;
                }
            });
        },

        dropdowns: _ember['default'].computed('items', function () {
            return this.get('dropdownTask').perform();
        }),

        dropdownTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var allCategories;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('store').findAll('category');

                    case 2:
                        allCategories = context$1$0.sent;
                        return context$1$0.abrupt('return', _ember['default'].RSVP.filter(allCategories.toArray(), function (cat) {
                            return cat.get('parent').then(function (parent) {
                                return !parent;
                            });
                        }));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        init: function init() {
            var _this3 = this;

            this._super.apply(this, arguments);
            // set max price based on items
            this.set('rawMaxPrice', this.get('maxAllowedPrice'));

            this.set('allCategories', _ember['default'].A());
            this.get('restoreFromLocalStorage').perform();

            var router = getOwner(this).lookup('router:main');

            router.on('willTransition', function () {
                _this3.updateLocalStorage();
            });
        },

        getAllChildCategories: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var parentDropdowns, acc, i, children;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('dropdowns');

                    case 2:
                        parentDropdowns = context$1$0.sent;
                        acc = [];
                        i = 0;

                    case 5:
                        if (!(i < parentDropdowns.length)) {
                            context$1$0.next = 13;
                            break;
                        }

                        context$1$0.next = 8;
                        return parentDropdowns[i].get('children');

                    case 8:
                        children = context$1$0.sent;

                        acc = acc.concat(children.toArray());

                    case 10:
                        i++;
                        context$1$0.next = 5;
                        break;

                    case 13:
                        return context$1$0.abrupt('return', acc);

                    case 14:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        getAllParentCategories: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(topLevelTitles) {
            var parentDropdowns;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('dropdowns');

                    case 2:
                        parentDropdowns = context$1$0.sent;
                        return context$1$0.abrupt('return', parentDropdowns.filter(function (parent) {
                            return topLevelTitles.contains(parent.get('name'));
                        }));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        didDestroyElement: function didDestroyElement() {
            this.set('allCategories', _ember['default'].A());
        },

        willDestroyElement: function willDestroyElement() {
            var router = getOwner(this).lookup('router:main');
            router.off('willTransition');
        },

        restoreFromLocalStorage: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var localStorage, cacheExpiration, dropdownIds, topLevelCategories;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this4 = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        localStorage = this.get('localStorage').find(this.get('localStorageKey'));
                        cacheExpiration = Math.round(new Date().getTime() / 1000) - 2 * 60 * 60;

                        if (!(localStorage.lastUpdated < cacheExpiration)) {
                            context$1$0.next = 4;
                            break;
                        }

                        return context$1$0.abrupt('return', false);

                    case 4:

                        if (localStorage.scrollTop) {
                            _ember['default'].run.later(function () {
                                document.documentElement.scrollTop = document.body.scrollTop = localStorage.scrollTop;
                            }, 100);
                        }

                        dropdownIds = localStorage.dropDownItems;
                        topLevelCategories = localStorage.allCategories;
                        context$1$0.prev = 7;
                        return context$1$0.delegateYield(regeneratorRuntime.mark(function callee$1$0() {
                            var allDroopDowns, validParentDropdowns, allCats, i, dropdownObjects;
                            return regeneratorRuntime.wrap(function callee$1$0$(context$2$0) {
                                while (1) switch (context$2$0.prev = context$2$0.next) {
                                    case 0:
                                        context$2$0.next = 2;
                                        return this.get('getAllChildCategories').perform();

                                    case 2:
                                        allDroopDowns = context$2$0.sent;
                                        context$2$0.next = 5;
                                        return this.get('getAllParentCategories').perform(topLevelCategories);

                                    case 5:
                                        validParentDropdowns = context$2$0.sent;
                                        allCats = this.get('allCategories');

                                        for (i = 0; i < validParentDropdowns.get('length'); i++) {
                                            allCats.pushObject({
                                                topLevel: validParentDropdowns[i].get('name'),
                                                children: validParentDropdowns[i].get('children')
                                            });
                                        }

                                        dropdownObjects = dropdownIds.map(function (id) {
                                            return allDroopDowns.find(function (obj) {
                                                return obj.get('id') === id;
                                            });
                                        });

                                        this.set('dropDownItems', dropdownObjects);

                                    case 10:
                                    case 'end':
                                        return context$2$0.stop();
                                }
                            }, callee$1$0, _this4);
                        })(), 't0', 9);

                    case 9:
                        context$1$0.next = 13;
                        break;

                    case 11:
                        context$1$0.prev = 11;
                        context$1$0.t1 = context$1$0['catch'](7);

                    case 13:

                        if (localStorage.minPrice) {
                            this.set('rawMinPrice', localStorage.minPrice);
                        }
                        if (localStorage.maxPrice) {
                            this.set('rawMaxPrice', localStorage.maxPrice);
                        }

                        if (localStorage._searchText) {
                            this.set('rawSearchText', localStorage._searchText);
                            this.set('_searchText', localStorage._searchText);
                        }

                        this.set('validZip', localStorage.validZip);
                        this.set('zipCodeInput', localStorage.validZip);

                        this.set('sortByValue', localStorage.sortByValue);
                        this.set('distanceThreshold', localStorage.distanceThreshold);

                        this.set('currentPage', localStorage.currentPage || 1);

                    case 21:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this, [[7, 11]]);
        })),

        defaultValueForSortBy: _ember['default'].computed('sortByValue', function () {
            var _this5 = this;

            var sortByOptions = this.get('sortByOptions');
            return sortByOptions.find(function (i) {
                return i.value === _this5.get('sortByValue');
            });
        }),

        rawMinPrice: 0,
        rawMaxPrice: Infinity,

        minPrice: 0,
        maxPrice: Infinity,

        minPriceWatcher: _ember['default'].observer('rawMinPrice', function () {
            var _this6 = this;

            _ember['default'].run.debounce(function () {
                _this6.set('minPrice', _this6.get('rawMinPrice'));
                _this6.updateLocalStorage();
            }, 500);
        }),

        maxPriceWatcher: _ember['default'].observer('rawMaxPrice', function () {
            var _this7 = this;

            _ember['default'].run.debounce(function () {
                _this7.set('maxPrice', _this7.get('rawMaxPrice'));
                _this7.updateLocalStorage();
            }, 500);
        }),

        zipSearchDistances: [{
            text: 5
        }, {
            text: 10
        }, {
            text: 20
        }, {
            text: 30
        }],

        sortByOptions: [{
            text: 'Popularity',
            value: 'popularityScore'
        }, {
            text: 'Date Added',
            value: 'createdAt'
        }],

        maxAllowedPrice: _ember['default'].computed('items', function () {
            return this.get('items').toArray().reduce(function (prev, curr) {
                var price = curr.get('price');
                return prev >= price ? prev : price;
            }, 0);
        }),

        filteredItemsTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var items, validZip, searchText, validItems, itemsFilteredBySearch, fuse, validItemsForCriteria;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this8 = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        items = this.get('items');
                        validZip = this.get('validZip');
                        searchText = this.get('_searchText');
                        validItems = undefined;
                        itemsFilteredBySearch = this.get('items');

                        if (searchText) {
                            fuse = new _npmFuseJs['default'](this.get('items'), fuseOptions);

                            itemsFilteredBySearch = fuse.search(searchText);
                        }

                        validItemsForCriteria = itemsFilteredBySearch.filter(function (item) {
                            if (!item.isInPriceRange(_this8.get('minPrice'), _this8.get('maxPrice'))) {
                                return false;
                            }

                            var allDropdownItems = _this8.get('dropDownItems');

                            if (_this8.get('allCategories.length')) {
                                // look into all the top levelCategories
                                var allChildren = _this8.get('allCategories').reduce(function (prev, catObj) {
                                    return prev.concat(catObj.children.toArray());
                                }, []);

                                allDropdownItems = allDropdownItems.concat(allChildren);
                            }

                            if (!allDropdownItems.get('length')) {
                                return true;
                            }

                            return allDropdownItems.any(function (category) {
                                return item.get('categories').contains(category);
                            });
                        });

                        if (!validZip) {
                            context$1$0.next = 11;
                            break;
                        }

                        return context$1$0.delegateYield(regeneratorRuntime.mark(function callee$1$0() {
                            var latLng;
                            return regeneratorRuntime.wrap(function callee$1$0$(context$2$0) {
                                var _this9 = this;

                                while (1) switch (context$2$0.prev = context$2$0.next) {
                                    case 0:
                                        context$2$0.next = 2;
                                        return this.getGeoCodeResult(validZip);

                                    case 2:
                                        latLng = context$2$0.sent;

                                        validItems = validItemsForCriteria.filter(function (item) {

                                            try {
                                                var itemCoords = item.get('latLng');

                                                if (!itemCoords) {
                                                    return false;
                                                }

                                                var lat = itemCoords.lat;
                                                var lng = itemCoords.lng;

                                                var distance = _npmGeolib['default'].getDistance({ latitude: latLng.lat(), longitude: latLng.lng() }, { latitude: lat, longitude: lng });

                                                return distance * 0.00062137 <= _this9.get('distanceThreshold');
                                            } catch (e) {
                                                // in case there is not a user
                                                return false;
                                            }
                                        });

                                    case 4:
                                    case 'end':
                                        return context$2$0.stop();
                                }
                            }, callee$1$0, _this8);
                        })(), 't0', 9);

                    case 9:
                        context$1$0.next = 12;
                        break;

                    case 11:
                        validItems = validItemsForCriteria;

                    case 12:
                        return context$1$0.abrupt('return', validItems.sortBy(this.get('sortByValue')).toArray().reverse());

                    case 13:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        filteredItemsRaw: _ember['default'].computed('dropDownItems.[]', 'items.[]', 'minPrice', 'maxPrice', 'validZip', '_searchText', 'distanceThreshold', 'sortByValue', 'allCategories.[]', 'currentPage', function () {
            return this.get('filteredItemsTask').perform();
        }),

        filteredItems: _ember['default'].computed('filteredItemsRaw', function () {
            var _this10 = this;

            return this.get('filteredItemsRaw').then(function (itemsFiltered) {
                return itemsFiltered.slice(0, _this10.get('currentPage') * _this10.get('perPage'));
            });
        }),

        hasMore: _ember['default'].computed('filteredItemsRaw', 'currentPage', function () {
            var _this11 = this;

            return this.get('filteredItemsRaw').then(function (items) {
                var itemsShown = items.length;
                var numberOnPage = _this11.get('currentPage') * _this11.get('perPage');
                return numberOnPage < itemsShown;
            });
        }),

        /**
         * Selected sub items that are selected
         */
        dropDownItems: (function () {
            this.set('dropDownItems', _ember['default'].A());
        }).on('init'),

        /**
         * "All" items selected under a specific category
         */
        allCategories: _ember['default'].A(),

        actions: {
            onSelect: function onSelect(item) {
                this.set('currentPage', 1);
                var dropdownItems = this.get('dropDownItems');
                // if the item is currently in the list of applied filters use it.
                if (dropdownItems.contains(item)) {
                    dropdownItems.removeObject(item);
                } else {
                    dropdownItems.pushObject(item);
                    this.get('analytics').filterApplied(item.get('parent.name'), item.get('name'));
                }
                this.updateLocalStorage();
            },
            toggleMobileFilters: function toggleMobileFilters() {
                this.toggleProperty('mobileFiltersOpen');
            },
            setDistanceThreshold: function setDistanceThreshold(_ref) {
                var text = _ref.text;

                this.set('distanceThreshold', text);
                this.updateLocalStorage();
            },
            setSortBy: function setSortBy(_ref2) {
                var value = _ref2.value;

                this.set('currentPage', 1);
                this.set('sortByValue', value);
                this.updateLocalStorage();
            },
            selectAll: function selectAll(toLevelCategory, children) {
                var updateStorage = arguments.length <= 2 || arguments[2] === undefined ? true : arguments[2];

                this.set('currentPage', 1);
                var allCats = this.get('allCategories');

                // this might be a toggle
                var foundTopLevel = allCats.find(function (c) {
                    return c.topLevel === toLevelCategory.get('name');
                });

                if (foundTopLevel) {
                    allCats.removeObject(foundTopLevel);
                    this.updateLocalStorage();
                } else {
                    allCats.pushObject({
                        topLevel: toLevelCategory.get('name'),
                        children: children
                    });
                    this.updateLocalStorage();
                }
            },
            removeTopLevel: function removeTopLevel(topLevelName) {
                var allCats = this.get('allCategories');
                var itemToRemove = allCats.find(function (i) {
                    return i.topLevel == topLevelName;
                });
                allCats.removeObject(itemToRemove);
                this.updateLocalStorage();
            },
            clear: function clear() {
                this.set('dropDownItems', _ember['default'].A());
                this.set('allCategories', _ember['default'].A());
                this.updateLocalStorage();
            },

            loadMore: function loadMore() {
                this.incrementProperty('currentPage');
                this.updateLocalStorage();
            }
        },

        mobileFiltersOpen: false,

        zipCodeInput: null,

        zipCodeInputObserver: (function () {
            var value = this.get('zipCodeInput');
            var regex = /[0-9]{5}/g;
            if (value.match(regex)) {
                this.set('validZip', value);
                this.updateLocalStorage();
            } else {
                this.set('validZip', null);
            }
        }).observes('zipCodeInput'),

        validZip: null,

        updateLocalStorage: function updateLocalStorage() {

            var acc = {};
            // build an object that represents the state of our filter
            var dropDownIds = this.get('dropDownItems').map(function (i) {
                return i.get('id');
            });
            acc.allCategories = this.get('allCategories').map(function (i) {
                return i.topLevel;
            });

            acc.lastUpdated = Math.round(new Date().getTime() / 1000);

            acc.dropDownItems = dropDownIds;
            acc.maxPrice = this.get('maxPrice');
            acc.minPrice = this.get('minPrice');
            acc._searchText = this.get('_searchText');
            acc.sortByValue = this.get('sortByValue');
            acc._searchText = this.get('_searchText');
            acc.validZip = this.get('validZip');
            acc.distanceThreshold = this.get('distanceThreshold');
            acc.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            acc.currentPage = this.get('currentPage');
            this.get('localStorage').set(this.get('localStorageKey'), acc);
        }

    });
});

// import fuzzysearch from 'npm:fuzzysearch';

// set cache expiration to 2 hours

// find all the dropdownItems that are selected