define('hyve-frontend/components/beta-feedback', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        modalOpen: false,

        classNames: 'beta-feedback'.w(),

        store: service(),

        notifications: service('notification-messages'),

        init: function init() {
            this._super.apply(this, arguments);
            var feedback = this.get('store').createRecord('feedback');
            this.set('feedback', feedback);
        },

        actions: {
            toggleModal: function toggleModal() {
                // open modal
                this.toggleProperty('modalOpen');
            },

            save: function save() {
                var _this = this;

                this.get('feedback').save().then(function () {

                    _ember['default'].$.post({
                        url: _hyveFrontendConfigEnvironment['default'].FeedbackEmailURL,
                        data: JSON.parse(JSON.stringify(_this.get('feedback')))
                    });

                    _this.set('modalOpen', false);
                    _this.get('notifications').info('Thank you, your feedback has been submitted');
                });
            }
        }

    });
});