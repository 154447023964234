define('hyve-frontend/components/favorite-button', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({
        sessionManager: service(),

        btnText: _ember['default'].computed('item.favoritors.[]', function () {
            return this.get('btnTextTask').perform();
        }),

        btnTextTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var itemsThisUserHasFavorited;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.prev = 0;
                        context$1$0.next = 3;
                        return this.get('sessionManager.user.favorites');

                    case 3:
                        itemsThisUserHasFavorited = context$1$0.sent;
                        return context$1$0.abrupt('return', itemsThisUserHasFavorited.contains(this.get('item')) ? 'Unfavorite' : 'Favorite');

                    case 7:
                        context$1$0.prev = 7;
                        context$1$0.t0 = context$1$0['catch'](0);
                        return context$1$0.abrupt('return', 'Favorite');

                    case 10:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this, [[0, 7]]);
        })),

        click: function click() {
            var _this = this;

            this.get('btnText').then(function (text) {

                if (text == 'Unfavorite') {
                    _this.get('sessionManager.user').unfavoriteItem(_this.get('item'));
                } else {

                    _this.get('sessionManager.user').favoriteItem(_this.get('item'));
                }
            });
        }

    });
});