define('hyve-frontend/components/event-choice', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({

        methods: _hyveFrontendConfigEnvironment['default'].NOTIFICATION_METHODS,

        checkboxChoices: _ember['default'].computed('methods', 'selections.[]', function () {
            var _this = this;

            return Object.keys(this.get('methods')).map(function (key) {
                return {
                    value: key,
                    title: _this.get('methods.' + key),
                    selected: _this.get('selections').contains(key)
                };
            });
        }),

        init: function init() {
            this._super.apply(this, arguments);
        },

        actions: {
            toggleInList: function toggleInList(key) {

                if (this.get('selections').contains(key)) {
                    this.get('selections').removeObject(key);
                } else {
                    this.get('selections').pushObject(key);
                }

                this.sendAction('notificationPrefChanged', this.get('selections'));
            }
        }

    });
});