define('hyve-frontend/components/main-navigation', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        session: service('sessionManager'),
        sideMenu: service(),
        isSideMenuOpen: _ember['default'].computed('sideMenu.isOpen', function () {
            return this.get('sideMenu').isOpen;
        }),
        inviteFriendsService: service(),
        confirmModal: service(),

        numNotifications: _ember['default'].computed('session.user.notifications.[]', function () {
            return this.get('numbNotificationsTask').perform();
        }),

        numbNotificationsTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var notifications;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('session.user.notifications');

                    case 2:
                        notifications = context$1$0.sent;
                        return context$1$0.abrupt('return', (notifications || []).filter(function (n) {
                            return n.get('read') === false;
                        }).length);

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        userNeedsSubscription: _ember['default'].computed('session.user', 'session.user.stripeCustomerId', function () {
            return this.get('session.user') && !this.get('session.user.stripeCustomerId');
        }),

        actions: {
            login: function login() {
                this.get('session').signIn('facebook');
            },
            logout: function logout() {
                this.get('session').signOut();
            },

            close: function close() {
                this.get('sideMenu').close();
            },

            openMailToLink: function openMailToLink() {
                var mailToLink = this.get('inviteFriendsService.mailToLink');
                window.location = mailToLink;
                this.get('inviteFriendsService').triggerConfirmModal();
            }

        }

    });
});