define('hyve-frontend/components/item-meta', ['exports', 'ember'], function (exports, _ember) {
    var computed = _ember['default'].computed;
    var $ = _ember['default'].$;
    exports['default'] = _ember['default'].Component.extend({

        classNames: 'item__meta flex--column'.w(),

        classNameBindings: 'isSticky:sticky'.w(),

        scrollPosition: 0,

        /**
         * Set to infinity at first, so on first check item is not sticky
         * only read an offset top
         */
        offsetTop: null,

        offsetX: null,

        width: null,

        isSticky: computed('scrollPosition', function () {
            if (this.get('offsetTop')) {
                return this.get('scrollPosition') >= this.get('offsetTop');
            }
            return false;
        }),

        widthSet: (function () {
            var elem = this.$();
            if (!elem) {
                return;
            }
            elem.css({
                // width: this.get('width')
            });
        }).observes('width'),

        attachScrollListener: function attachScrollListener() {
            var _this = this;

            var scrollPosition = 0;
            this.scrollListener = function () {
                scrollPosition = $(window).scrollTop();
                _this.set('scrollPosition', scrollPosition);
            };
            $(window).on('scroll', this.scrollListener);
        },

        didDestroyElement: function didDestroyElement() {
            $(window).off('scroll', this.scrollListener);
        },

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.set('offsetTop', this.$().offset().top - 20); // 20 from css top value
            this.set('offsetX', this.$().offset().left);
            this.set('width', this.$().outerWidth());
            this.attachScrollListener();
        }

    });
});