define('hyve-frontend/services/fb-api', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {
    exports['default'] = _ember['default'].Service.extend({

        fbInitPromise: null,

        fbInit: function fbInit() {

            if (this.fbInitPromise) {
                return this.fbInitPromise;
            }

            var original = window.fbAsyncInit;
            var initSettings = _hyveFrontendConfigEnvironment['default'].FB;

            this.fbInitPromise = new _ember['default'].RSVP.Promise(function (resolve) {
                window.fbAsyncInit = function () {
                    window.FB.init(initSettings);
                    _ember['default'].run(null, resolve);
                };

                _ember['default'].$.getScript('//connect.facebook.net/en_US/sdk.js', function () {
                    // no op; wait for window.fbAsyncInit to be called
                });
            }).then(function () {
                if (original) {
                    window.fbAsyncInit = original;
                    window.fbAsyncInit();
                    window.fbAsyncInit.hasRun = true;
                }
            });

            return this.fbInitPromise;
        },

        api: function api(path) {
            var _this = this;

            var method = 'GET';
            var parameters = {};
            var arg = undefined;

            if (!path) {
                return _ember['default'].RSVP.reject('Please provide path for request');
            }

            switch (arguments.length) {
                case 2:
                    arg = arguments[1];
                    if (typeof arg === 'string') {
                        method = arg;
                    } else {
                        parameters = arg;
                    }
                    break;
                case 3:
                    method = arguments[1];
                    parameters = arguments[2];
            }

            return this.getToken().then(function (token) {
                parameters = _ember['default'].$.extend(parameters, { access_token: token });
                return _this.fbInit().then(function () {
                    return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                        window.FB.api(path, method, parameters, function (response) {
                            if (response.error) {
                                _ember['default'].run(null, reject, response.error);
                                return;
                            }

                            _ember['default'].run(null, resolve, response);
                        });
                    });
                });
            });
        },

        getToken: function getToken() {
            var _this2 = this;

            if (this.accessToken) {
                return _ember['default'].RSVP.resolve(this.accessToken);
            }

            var handleLoginStatus = function handleLoginStatus(response, resolve, reject) {
                if (response.status === 'connected') {
                    // logged into app and facebook
                    _this2.accessToken = _ember['default'].get(response, 'authResponse.accessToken');
                    resolve(_this2.accessToken);
                } else {
                    // bad things have happend, the user is somehow not logged to this app or facebook
                    reject();
                }
            };

            return this.fbInit().then(function () {
                return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                    window.FB.getLoginStatus(function (r) {
                        return handleLoginStatus(r, resolve, reject);
                    });
                });
            });
        },

        mutualFriends: function mutualFriends(userId) {
            var _this3 = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                _this3.getToken().then(function (token) {
                    _ember['default'].$.post({
                        url: _hyveFrontendConfigEnvironment['default'].FB.mutualFriendsUrl,
                        dataType: 'json',
                        data: {
                            access_token: token,
                            user_id: userId
                        }
                    }).then(function (data) {
                        return resolve(data);
                    }).fail(reject);
                });
            });
        },

        isFriendsWith: function isFriendsWith(userId) {
            return this.api('/me/friends/' + userId, { fields: 'name' }).then(function (resp) {
                return _ember['default'].get(resp, 'data.length') > 0;
            });
        },

        hasUserFriendsPermissions: function hasUserFriendsPermissions() {
            var _this4 = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                var permissionName = 'user_friends';
                return _this4.api('/me/permissions').then(function (response) {
                    if (response.data) {
                        response.data.forEach(function (perm) {
                            if (perm.permission == permissionName) {
                                resolve(perm.status === 'granted');
                            }
                        });
                    }
                });
            });
        },

        login: function login() {
            var _window$FB;

            (_window$FB = window.FB).login.apply(_window$FB, arguments);
        },

        reRequestedPerms: []

    });
});