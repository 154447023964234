define('hyve-frontend/components/save-and-post-button', ['exports', 'ember', 'npm:moment'], function (exports, _ember, _npmMoment) {
    var service = _ember['default'].inject.service;
    var get = _ember['default'].get;
    exports['default'] = _ember['default'].Component.extend({
        sessionManager: service(),

        classNames: 'save-and-post-button'.w(),

        postsRemaining: _ember['default'].computed.readOnly('sessionManager.user.numPostsRemaining'),
        numberOfPostsPerMonth: _ember['default'].computed.readOnly('sessionManager.user.plan.metadata.posts'),

        isOutOfPosts: _ember['default'].computed('sessionManager.user.numPostsRemaining', function () {
            return this.get('postsRemaining') <= 0;
        }),

        toolTipText: _ember['default'].computed('isOutOfPosts', function () {
            var currentMonthName = (0, _npmMoment['default'])().format('MMMM');
            var nextMonthName = (0, _npmMoment['default'])().add(1, 'month').format('MMMM');
            return 'You have used up all your posts for ' + currentMonthName + '. If you need to post before ' + nextMonthName + ', please upgrade your account.';
        }),

        requestBlocked: false,

        text: 'Save and Post Item',

        actions: {
            saveAndPublish: function saveAndPublish(item) {
                var _this = this;

                // make sure it is valid
                var fail = this.get('fail');
                if (item.get('validations.isValid') && !this.requestBlocked) {
                    this.requestBlocked = true;
                    // not valid
                    if (typeof fail === 'function') {
                        fail();
                    }
                    return item.post().then(function () {
                        _this.requestBlocked = false;
                    });
                } else {
                    fail();
                }
            }
        }
    });
});