define('hyve-frontend/components/category-dropdown', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {

    function isDescendant(parent, child) {
        var node = child.parentNode;
        while (node != null) {
            if (node == parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    exports['default'] = _ember['default'].Component.extend({
        classNames: 'dropdown',
        classNameBindings: ['mobileDropdown'],
        open: false,

        mobileDropdown: false,

        selectedItems: _ember['default'].computed('appliedFilters.[]', function () {
            return this.get('appliedFilters');
        }),

        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);
            this._clickListener = function (e) {
                return _this.onOutsideClick(e);
            };
            document.body.addEventListener('click', this._clickListener);
        },

        onOutsideClick: function onOutsideClick(e) {
            if (!isDescendant(this.$().get(0), e.target)) {
                this.set('open', false);
            }
        },

        didDestroyElement: function didDestroyElement() {
            document.body.removeEventListener('click', this._clickListener);
        },

        allIsSelected: false,

        actions: {
            toggleOpen: function toggleOpen() {
                this.toggleProperty('open');
            },
            selectItem: function selectItem(category) {
                this.sendAction('onSelect', category);
                this.set('allIsSelected', false);
            },
            selectAll: function selectAll() {
                // find all the categories and select them all
                this.set('allIsSelected', true);
                this.sendAction('selectAll', this.get('dropdown'), this.get('dropdown.children'));
            }
        }
    });
});