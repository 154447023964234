define('hyve-frontend/services/invite-friends-service', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Service.extend({
        sessionManager: service(),
        confirmModal: service(),
        inviteCode: _ember['default'].computed.readOnly('sessionManager.user.inviteCode'),

        mailToLink: _ember['default'].computed('inviteCode', function () {
            var link = 'mailto:?body=' + this.getMessage() + '&subject=Your Personal Invitation to BeeSwappy';
            return link.replace(' ', '%20');
        }),

        getMessage: function getMessage() {
            var lines = ['You have been invited to join BeeSwappy (http://beeswappy.com), the most exclusive online buy/sell/trade community in Memphis. From boutique children’s clothing and designer bags to high-end electronics and outdoor gear, BeeSwappy connects only the most trustworthy buyers and sellers to create a shopping experience unlike any other.', 'Visit http://beeswappy.com to see what all the buzz is about, and use the invitation code below to join the most happening hive in town.', 'Invitation code: ' + this.get('inviteCode')];
            return lines.join('%0D%0A%0D%0A');
        },

        triggerConfirmModal: function triggerConfirmModal() {
            this.get('confirmModal').confirm('No Email Client', 'Your code is: ' + this.get('inviteCode'));
        }

    });
});