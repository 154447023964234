define('hyve-frontend/controllers/item/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        assets: [],

        actions: {
            receiveFile: function receiveFile(file) {
                var asset = this.store.createRecord('image', {
                    image: file,
                    imageName: file.name,
                    title: file.name
                });
                this.get('assets').pushObject(asset);
            }
        }
    });
});