define('hyve-frontend/components/public-user', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        chat: service(),
        store: service(),
        sessionManager: service(),

        referenceItem: null,

        userStatus: _ember['default'].computed('user.online', function () {
            if (this.get('user.online') === true) {
                return 'online';
            } else {
                return 'offline';
            }
        }),

        actions: {
            openChat: function openChat() {
                var _this = this;

                var chatService = this.get('chat');
                this.get('store').findRecord('user', this.get('user.id')).then(function (user) {
                    return chatService.openChat(user, _this.get('referenceItem'));
                }).then(function (group) {
                    if (_this.get('media.isMobile')) {
                        // transition to the chat screen
                        var router = getOwner(_this).lookup('router:main');
                        router.transitionTo('message.view', group);
                    }
                });
            },

            followUser: function followUser() {
                var _this2 = this;

                this.get('store').findRecord('user', this.get('user.id')).then(function (user) {
                    var currentUser = _this2.get('sessionManager.user');
                    currentUser.get('following').pushObject(user);
                    user.get('followers').pushObject(currentUser);
                    currentUser.save();
                    user.save();
                });
            },

            unFollowUser: function unFollowUser() {
                var _this3 = this;

                this.get('store').findRecord('user', this.get('user.id')).then(function (user) {
                    var currentUser = _this3.get('sessionManager.user');
                    currentUser.get('following').removeObject(user);
                    user.get('followers').removeObject(currentUser);
                    currentUser.save();
                    user.save();
                });
            }

        }

    });
});