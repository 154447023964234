define('hyve-frontend/validators/valid-zipcode', ['exports', 'ember-cp-validations/validators/base'], function (exports, _emberCpValidationsValidatorsBase) {

    var ValidZipCode = _emberCpValidationsValidatorsBase['default'].extend({
        validate: function validate(value, options, model, attribute) {
            if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
                return this.createErrorMessage('zipcode', value, options);
            }
            return true;
        }
    });

    ValidZipCode.reopenClass({
        /**
         * Define attribute specific dependent keys for your validator
         *
         * @param {String}  attribute   The attribute being evaluated
         * @param {Unknown} options     Options passed into your validator
         * @return {Array}
         */
        getDependentsFor: function getDependentsFor() /* attribute, options */{
            return [];
        }
    });

    exports['default'] = ValidZipCode;
});