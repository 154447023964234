define('hyve-frontend/components/account-form', ['exports', 'ember', 'hyve-frontend/utils/user-validations', 'ember-cp-validations'], function (exports, _ember, _hyveFrontendUtilsUserValidations, _emberCpValidations) {

    var Validations = (0, _emberCpValidations.buildValidations)({
        'model.email': _hyveFrontendUtilsUserValidations.email
    });

    exports['default'] = _ember['default'].Component.extend(Validations, {

        stripe: _ember['default'].inject.service(),
        stripeAccount: _ember['default'].inject.service(),
        sessionManager: _ember['default'].inject.service(),

        isStripeCustomer: _ember['default'].computed('sessionManager.user.stripeCustomerId', function () {
            return this.get('sessionManager.user.stripeCustomerId');
        }),

        months: [{ value: '1', content: 'January' }, { value: '2', content: 'February' }, { value: '3', content: 'March' }, { value: '4', content: 'April' }, { value: '5', content: 'May' }, { value: '6', content: 'June' }, { value: '7', content: 'July' }, { value: '8', content: 'August' }, { value: '9', content: 'September' }, { value: '10', content: 'October' }, { value: '11', content: 'November' }, { value: '12', content: 'December' }],

        loading: false,

        years: _ember['default'].computed(function () {
            var acc = [];
            var currentYear = new Date().getFullYear();
            for (var i = 0; i < 10; i++) {
                acc.push({
                    value: currentYear,
                    content: currentYear
                });
                currentYear++;
            }
            return acc;
        }),

        buttonDisabled: _ember['default'].computed.not('validations.isValid'),

        _showPaymentForm: false,

        showPaymentForm: _ember['default'].computed('sessionManager.user.hasValidSubscription', '_showPaymentForm', function () {
            if (this.get('_showPaymentForm')) {
                return true;
            }
            return !this.get('sessionManager.user.hasValidSubscription');
        }),

        actions: {

            togglePaymentForm: function togglePaymentForm() {
                this.toggleProperty('_showPaymentForm');
            },

            submit: function submit() {
                var _this = this;

                this.set('loading', true);
                this.eventBus.publish('paymentFormSubmit', this);

                this.eventBus.subscribe('paymentProcessed', function () {
                    _this.set('loading', false);
                    _this.set('_showPaymentForm', false);
                    _this.sendAction('on-submit');
                });
            }
        }
    });
});