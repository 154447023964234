define('hyve-frontend/services/message-notification', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Service.extend({

        store: service(),
        sessionManager: service(),
        firebase: service(),
        media: service(),

        addedGroupIds: [],

        notifications: _ember['default'].inject.service('notification-messages'),

        callbacks: [],

        addCb: function addCb(fn) {

            // noop

        },

        // observe loggedin user groups
        groupAddedTask: _ember['default'].observer('sessionManager.user.groups.[]', function () {
            var _this = this;

            (this.get('sessionManager.user.groups') || []).forEach(function (group) {
                if (!_this.addedGroupIds.contains(group)) {
                    _this.get('addedGroupIds').pushObject(group);
                    _this.addCbToGroupId(group);
                }
            });
        }),

        usersWithUnreadMessages: [],

        addCbToGroupId: function addCbToGroupId(group) {
            var _this2 = this;

            var firebaseGroups = this.get('firebase').child('groups');

            firebaseGroups.child(group.get('id') + '/messages').orderByChild('timestamp').startAt(Date.now()).on('child_added', function (data) {
                data = data.val();
                var messageUserId = data.user;
                if (_this2.get('sessionManager.user.id') !== messageUserId) {
                    _this2.sendChatNotification(group, data);
                    _this2.get('callbacks').forEach(function (fn) {
                        return fn(group, data);
                    });
                    _this2.get('usersWithUnreadMessages').push(messageUserId);
                }
            });
        },

        addGroupListeners: function addGroupListeners() {
            var _this3 = this;

            (this.get('sessionManager.user.groups') || []).forEach(function (group) {
                _this3.get('addedGroupIds').pushObject(group);
                _this3.addCbToGroupId(group);
            });
        },

        init: function init() {
            this.addGroupListeners();
        },

        sendChatNotification: function sendChatNotification(group, data) {
            var _this4 = this;

            // open chat notification
            this.get('sessionManager.user.visibleChatGroups').pushObject(group);
            this.get('sessionManager.user').save();
            var messageNotifier = this;

            // from user
            this.get('store').findRecord('user', data.user).then(function (user) {
                if (_this4.get('media.isMobile')) {
                    var currentPath = _ember['default'].getOwner(messageNotifier).lookup('controller:application').currentPath;
                    if (currentPath !== 'message.view') {
                        _this4.get('notifications').info('\n                    <div>New message from ' + user.get('nameLastInitial') + '</div>\n                    <div><strong>' + data.message + '</strong></div>\n                ', {
                            onClick: function onClick() {
                                // if mobile go to the group message page
                                messageNotifier.get('router').transitionTo('message.view', group);
                            },
                            htmlContent: true,
                            cssClasses: 'brand-info-notification',
                            autoClear: true,
                            clearDuration: 5000
                        });
                    }
                }
            });
        }

    });
});