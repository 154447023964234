define('hyve-frontend/components/claim-button', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        i18n: service(),
        sessionManager: service(),

        tagName: 'div',

        classNames: 'btn btn--secondary-color btn--claim btn--block'.w(),

        btnClass: 'btn btn--secondary-color darken-2 btn--claim btn--block',

        classNameBindings: ['claimedByThisUser', 'claimedByOtherUser:join-wait-list'],

        claimedByThisUser: _ember['default'].computed.alias('item.claimedByCurrentUser'),

        btnActionTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('item.claimedByCurrentUser');

                    case 2:
                        if (!context$1$0.sent) {
                            context$1$0.next = 6;
                            break;
                        }

                        return context$1$0.abrupt('return', 'unclaim');

                    case 6:
                        context$1$0.next = 8;
                        return this.get('claimedByOtherUser');

                    case 8:
                        if (!context$1$0.sent) {
                            context$1$0.next = 10;
                            break;
                        }

                        return context$1$0.abrupt('return', 'joinWaitList');

                    case 10:
                        return context$1$0.abrupt('return', 'claim');

                    case 11:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        btnAction: _ember['default'].computed('item.claimedByCurrentUser', function () {
            return this.get('btnActionTask').perform();
        }),

        toolTipTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var validClaims;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('item.validClaims');

                    case 2:
                        validClaims = context$1$0.sent;
                        context$1$0.next = 5;
                        return this.get('item.claimedByCurrentUser');

                    case 5:
                        if (!context$1$0.sent) {
                            context$1$0.next = 9;
                            break;
                        }

                        return context$1$0.abrupt('return', this.get('i18n').t('claim.tool-tip.unclaim'));

                    case 9:
                        context$1$0.next = 11;
                        return this.get('claimedByOtherUser');

                    case 11:
                        if (!context$1$0.sent) {
                            context$1$0.next = 13;
                            break;
                        }

                        return context$1$0.abrupt('return', this.get('i18n').t('claim.tool-tip.already-claimed', { count: validClaims.get('length') }));

                    case 13:
                        return context$1$0.abrupt('return', this.get('i18n').t('claim.tool-tip.submit-claim'));

                    case 14:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        tooltipText: _ember['default'].computed('isInClaimMode', function () {
            return this.get('toolTipTask').perform();
        }),

        isInClaimMode: _ember['default'].computed.equal('btnAction', 'claim'),
        isInUncClaimMode: _ember['default'].computed.not('isInClaimMode'),

        claimedByOtherUser: _ember['default'].computed('item.claimedByCurrentUser', function () {
            if (this.get('item.claimedByCurrentUser')) {
                return Promise.resolve(false);
            }

            return this.get('item.validClaims').then(function (validClaims) {
                return validClaims.get('length');
            });
        }),

        btnTextTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (!this.get('item.claimedByCurrentUser')) {
                            context$1$0.next = 4;
                            break;
                        }

                        return context$1$0.abrupt('return', this.get('i18n').t('claim.unclaim'));

                    case 4:
                        if (!this.get('item.userInWaitListForItem')) {
                            context$1$0.next = 8;
                            break;
                        }

                        return context$1$0.abrupt('return', this.get('i18n').t('claim.leave-wait-list'));

                    case 8:
                        context$1$0.next = 10;
                        return this.get('claimedByOtherUser');

                    case 10:
                        if (!context$1$0.sent) {
                            context$1$0.next = 14;
                            break;
                        }

                        return context$1$0.abrupt('return', this.get('i18n').t('claim.join-wait-list'));

                    case 14:
                        return context$1$0.abrupt('return', this.get('i18n').t('claim.claim'));

                    case 15:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        modalIsOpen: false,

        btnText: _ember['default'].computed('btnAction', 'item.userInWaitListForItem', function () {
            return this.get('btnTextTask').perform();
        }),

        waitListLength: _ember['default'].computed('item.claim.[]', function () {
            return this.get('item.claim.length') - 1;
        }),

        clickTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var action, task;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('btnAction');

                    case 2:
                        action = context$1$0.sent;
                        task = this.get(action);

                        if (!task) {
                            context$1$0.next = 10;
                            break;
                        }

                        if (!this.get('status')) {
                            context$1$0.next = 7;
                            break;
                        }

                        return context$1$0.abrupt('return');

                    case 7:
                        task.perform();
                        context$1$0.next = 11;
                        break;

                    case 10:
                        this.actions[action].call(this);

                    case 11:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        click: function click() {
            return this.get('clickTask').perform();
        },

        status: null,

        unclaim: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        this.set('status', 'Pending...');
                        context$1$0.next = 3;
                        return this.get('sessionManager.user').unclaimItem(this.get('item'));

                    case 3:
                        this.set('status', null);

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        joinWaitList: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        this.set('status', 'Pending...');
                        context$1$0.next = 3;
                        return this.get('sessionManager.user').claimItem(this.get('item'));

                    case 3:
                        this.set('status', null);

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {

            claim: function claim() {
                this.set('modalIsOpen', true);
            },

            closeModal: function closeModal() {
                this.set('modalIsOpen', false);
            }

        }
    });
});