define('hyve-frontend/mixins/auth', ['exports', 'ember'], function (exports, _ember) {

    var authRoute = 'login';

    var service = _ember['default'].inject.service;
    var Mixin = _ember['default'].Mixin;
    exports['default'] = Mixin.create({

        /**
         * SessionManager service from app
         */
        sessionManager: service(),

        /**
         * Check whether the service is authenticated
         */
        beforeModel: function beforeModel(transition) {
            // if we are attempting to transition to a more info screen
            if (this.get('sessionManager.firebaseUserData') && !this.get('sessionManager.user')) {
                return this._super.apply(this, arguments);
            }

            if (!this.get('sessionManager.user')) {
                // no user in system yet, just homepage
                return this.transitionTo('/');
            }

            if (!this.get('sessionManager.session.isAuthenticated')) {
                // save the attempted transition to apply later
                this.set('sessionManager.attemptedTransition', transition);
                return this.transitionTo(authRoute);
            } else if (!this.get('sessionManager.user.inGoodStanding') && transition.targetName !== "account") {
                return this.transitionTo('account-inaccessible');
            }

            return this._super.apply(this, arguments);
        }

    });
});