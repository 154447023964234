define('hyve-frontend/components/validated-rich-editor', ['exports', 'ember'], function (exports, _ember) {
    var defineProperty = _ember['default'].defineProperty;
    var computed = _ember['default'].computed;
    var isPresent = _ember['default'].isPresent;
    exports['default'] = _ember['default'].Component.extend({

        validation: null,

        init: function init() {
            this._super.apply(this, arguments);
            var valuePath = this.get('valuePath');
            defineProperty(this, 'validation', computed.oneWay('model.validations.attrs.' + valuePath));
            defineProperty(this, 'value', computed.alias('model.' + valuePath));
        }
    });
});