define('hyve-frontend/components/product/image-viewer', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: 'item__images'.w(),
        activeImage: null,
        modalIsOpen: false,

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('product.primaryImage').then(function (image) {
                _this.set('activeImage', image);
            });
        },

        actions: {
            highlightImage: function highlightImage(image) {
                this.set('activeImage', image);
            },
            toggleModal: function toggleModal() {
                var body = $('body');
                this.get('modalIsOpen') ? body.removeClass('no-scroll') : body.addClass('no-scroll');
                this.set('modalIsOpen', !this.get('modalIsOpen'));
            }
        }

    });
});