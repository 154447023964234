define('hyve-frontend/services/chat', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    var Promise = _ember['default'].RSVP.Promise;
    exports['default'] = _ember['default'].Service.extend({
        store: service(),
        analytics: service(),

        sessionManager: service(),
        notificationSender: service(),

        users: [],

        openGroupCbs: [],

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('store').findAll('user').then(function (users) {
                _this.set('users', users);
            });
        },

        addOpenGroupListener: function addOpenGroupListener(fn) {
            this.openGroupCbs.push(fn);
        },

        removeOpenGroupListener: function removeOpenGroupListener(fn) {
            this.openGroupCbs = this.openGroupCbs.filter(function (f) {
                return f !== fn;
            });
        },

        broadcastOpen: function broadcastOpen(group) {
            this.openGroupCbs.forEach(function (fn) {
                fn(group);
            });
        },

        groupExists: function groupExists(foreignUser) {
            return this.get('sessionManager.user.groups').then(function (groups) {
                return groups.find(function (g) {
                    return g.get('members').contains(foreignUser);
                });
            });
        },

        findOrCreateGroup: function findOrCreateGroup(userToChatWith) {
            var _this2 = this;

            var currentlyLoggedInUser = this.get('sessionManager.user');
            return new Promise(function (resolve, reject) {
                _this2.groupExists(userToChatWith).then(function (group) {

                    if (!group) {
                        var _ret = (function () {
                            var group = _this2.get('store').createRecord('group');

                            group.get('members').setObjects([currentlyLoggedInUser, userToChatWith]);

                            return {
                                v: group.save().then(function () {
                                    currentlyLoggedInUser.get('groups').pushObject(group);
                                    userToChatWith.get('groups').pushObject(group);

                                    _this2.get('sessionManager.user.visibleChatGroups').pushObject(group);

                                    Promise.all([userToChatWith.save(), currentlyLoggedInUser.save()]).then(function () {
                                        resolve(group);
                                    });
                                })
                            };
                        })();

                        if (typeof _ret === 'object') return _ret.v;
                    } else {
                        _this2.get('sessionManager.user.visibleChatGroups').pushObject(group);
                        _this2.get('sessionManager.user').save().then(function () {
                            resolve(group);
                        });
                    }
                });
            });
        },

        openChat: function openChat(userToChatWith) {
            var _this3 = this;

            var chatSpawnedFromItem = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

            this.get('analytics').startChat();

            return this.findOrCreateGroup(userToChatWith).then(function (group) {
                _this3.broadcastOpen(group);
                return group;
            }).then(function (group) {
                group.set('chatSpawnedFrom', chatSpawnedFromItem);
                return group;
            });
        },

        sendMessageToUser: function sendMessageToUser(user, message) {
            this.get('analytics').sendMessage();
            this.get('sendMessageToUserTask').perform(user, message);
        },

        sendMessageToUserTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(user, message) {
            var group, messageObj;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.findOrCreateGroup(user);

                    case 2:
                        group = context$1$0.sent;
                        messageObj = this.get('store').createRecord('message', {
                            user: this.get('sessionManager.user'),
                            message: message,
                            timestamp: Date.now()
                        });

                        group.get('messages').pushObject(messageObj);
                        context$1$0.next = 7;
                        return group.save();

                    case 7:
                        this.broadcastOpen(group);

                    case 8:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        generateChatNotificationTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(group, message, chatSpawnedFrom) {
            var otherUsers;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this4 = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.getOtherUsers(group);

                    case 2:
                        otherUsers = context$1$0.sent;
                        context$1$0.next = 5;
                        return Promise.all(otherUsers.map(function (u) {
                            return _this4.sendUserChatNotification(u, group, message, chatSpawnedFrom);
                        }));

                    case 5:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        getOtherUsers: function getOtherUsers(group) {
            var _this5 = this;

            return group.get('members').then(function (members) {
                return members.filter(function (u) {
                    return u !== _this5.get('sessionManager.user');
                });
            });
        },

        /**
         *
         * @param user
         * @param group
         * @param message
         * @param chatSpawnedFrom
         * @return Ember.RSVP.Promise
         */
        sendUserChatNotification: function sendUserChatNotification(user, group, message, chatSpawnedFrom) {
            return this.get('sendUserChatNotificationTask').perform(user, group, message, chatSpawnedFrom);
        },

        sendUserChatNotificationTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(user, group, message, chatSpawnedFrom) {
            var itemMessage, sendMessage;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        sendMessage = function sendMessage() {
                            return this.get('notificationSender').chatSent({
                                heading: message.body,
                                text: 'You have received a new message ' + itemMessage + ' from ' + this.get('sessionManager.user.nameLastInitial') + ' \n                    Message: ' + message.get('message') + '\n                    ',
                                clickMethod: 'openChatGroup',
                                clickMethodArgs: [group.get('id')]
                            }, user);
                        };

                        itemMessage = chatSpawnedFrom ? ' on ' + chatSpawnedFrom.get('name') + ' ' : '';

                        // unset the chat spawn from. So only the first message will reference the item it was spawned from
                        group.set('chatSpawnedFrom', null);

                        if (user.get('online')) {
                            context$1$0.next = 5;
                            break;
                        }

                        return context$1$0.abrupt('return', sendMessage.call(this));

                    case 5:
                        context$1$0.next = 7;
                        return user.hasGroupVisible(group);

                    case 7:
                        if (context$1$0.sent) {
                            context$1$0.next = 9;
                            break;
                        }

                        return context$1$0.abrupt('return', sendMessage.call(this));

                    case 9:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        }))

    });
});

// do some stuff with notifications

// get the other users in this group

// 1. is the user online?

// send a notification

// does the user have this group visible

// send a notification