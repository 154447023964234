define('hyve-frontend/services/analytics', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {

    var configObj = _ember['default'].Object.create(_hyveFrontendConfigEnvironment['default']);

    exports['default'] = _ember['default'].Service.extend({

        gaInitPromise: null,

        hasAnalytics: _ember['default'].computed.bool('analyticsCode'),

        analyticsCode: null,

        init: function init() {
            this._super();
            if (configObj.get('googleAnalytics.id')) {
                this.gaInit();
                this.set('analyticsCode', configObj.get('googleAnalytics.id'));
            }
        },

        gaInit: function gaInit() {

            (function (i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r;
                i[r] = i[r] || function () {
                    (i[r].q = i[r].q || []).push(arguments);
                }, i[r].l = 1 * new Date();
                a = s.createElement(o), m = s.getElementsByTagName(o)[0];
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

            ga('create', configObj.get('googleAnalytics.id'), 'auto');
        },

        pageView: function pageView(url) {
            if (this.get('hasAnalytics')) {
                return ga('send', 'pageview', {
                    'page': url,
                    'title': url
                });
            }
        },

        filterApplied: function filterApplied(filterCategory, filterValue) {
            if (this.get('hasAnalytics')) {
                ga('send', 'event', {
                    'eventCategory': 'filter',
                    'eventAction': 'appliedFilter',
                    'eventLabel': filterCategory + ' (' + filterValue + ')'
                });
            }
        },

        openChatBox: function openChatBox() {
            if (this.get('hasAnalytics')) {
                ga('send', 'event', {
                    'eventCategory': 'chat',
                    'eventAction': 'openChatBox'
                });
            }
        },

        startChat: function startChat() {
            if (this.get('hasAnalytics')) {
                ga('send', 'event', {
                    'eventCategory': 'chat',
                    'eventAction': 'startChat'
                });
            }
        },

        sendMessage: function sendMessage() {
            if (this.get('hasAnalytics')) {
                ga('send', 'event', {
                    'eventCategory': 'chat',
                    'eventAction': 'sendMessage'
                });
            }
        },

        closeChat: function closeChat() {
            if (this.get('hasAnalytics')) {
                ga('send', 'event', {
                    'eventCategory': 'chat',
                    'eventAction': 'closeChat'
                });
            }
        },

        openNotifications: function openNotifications() {
            if (this.get('hasAnalytics')) {
                ga('send', 'event', {
                    'eventCategory': 'notifications',
                    'eventAction': 'openNotifications'
                });
            }
        },

        clickNotification: function clickNotification() {
            if (this.get('hasAnalytics')) {
                ga('send', 'event', {
                    'eventCategory': 'notifications',
                    'eventAction': 'clickNotification'
                });
            }
        },

        textSearch: function textSearch() {
            if (this.get('hasAnalytics')) {
                ga('send', 'event', {
                    'eventCategory': 'search',
                    'eventAction': 'search'
                });
            }
        }

    });
});