define('hyve-frontend/components/claim-list', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    exports['default'] = _ember['default'].Component.extend({

        currentlyActiveClaim: _ember['default'].computed('validClaims', function () {
            console.log(this.get('item.validClaims.length'));
            return this.get('item.validClaims.firstObject.id');
        }),

        getClaimListTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var claims, claimedSorted, foundFirstItem;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('item.claim');

                    case 2:
                        claims = context$1$0.sent;
                        claimedSorted = claims.sortBy('createdAt');
                        foundFirstItem = false;
                        return context$1$0.abrupt('return', claimedSorted.map(function (c) {
                            if (!c.get('rejected') && !foundFirstItem) {
                                c.set('isActive', true);
                                foundFirstItem = true;
                            } else {
                                c.set('isActive', false);
                            }
                            return c;
                        }));

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        claimList: _ember['default'].computed('item.claim.[]', 'item.claimedBy', 'item.claim.@each.rejected', function () {
            return this.get('getClaimListTask').perform();
        })

    });
});