define('hyve-frontend/components/chat-userlist', ['exports', 'ember', 'ember-data', 'ember-concurrency'], function (exports, _ember, _emberData, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({
        store: service(),
        analytics: service(),

        sessionManager: service(),

        chatService: service('chat'),

        messageNotification: service(),

        classNames: ['chat-user-list'],

        usersTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var users;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('store').query('user', {});

                    case 2:
                        users = context$1$0.sent;
                        return context$1$0.abrupt('return', users.filter(function (user) {
                            if (user === _this.get('sessionManager.user')) {
                                return false;
                            }
                            return user.get('canInteractWithCurrentUser');
                        }).sortBy('firstName'));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        users: (function () {
            return this.get('usersTask').perform();
        }).property(),

        open: false,

        hasUnreadGroups: false,

        init: function init() {
            this._super.apply(this, arguments);
            // this.get('messageNotification').addCb(this.onMessageNotification.bind(this));
        },

        onMessageNotification: function onMessageNotification(group, user) {
            var _this2 = this;

            // if the group is already visible, skip
            this.get('sessionManager.user.visibleChatGroups').then(function (visibleGroups) {
                if (!visibleGroups.contains(group)) {
                    _this2.set('hasUnreadGroups', true);
                }
            });
        },

        actions: {

            toggleOpen: function toggleOpen() {
                this.toggleProperty('open');
                this.get('analytics').openChatBox();
            },

            openChat: function openChat(personToChatWith) {
                this.get('chatService').openChat(personToChatWith);
            }
        }

    });
});

// get all the users