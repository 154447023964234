define('hyve-frontend/models/user', ['exports', 'ember-data', 'ember', 'hyve-frontend/mixins/mutual-friends', 'ember-concurrency', 'hyve-frontend/config/environment', 'ember-cp-validations', 'npm:shortid'], function (exports, _emberData, _ember, _hyveFrontendMixinsMutualFriends, _emberConcurrency, _hyveFrontendConfigEnvironment, _emberCpValidations, _npmShortid) {
    var attr = _emberData['default'].attr;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    var belongsTo = _emberData['default'].belongsTo;

    var Validations = (0, _emberCpValidations.buildValidations)({
        defaultSellZipCode: {
            description: 'Default Pickup Zipcode',
            validators: [(0, _emberCpValidations.validator)('valid-zipcode', {
                message: '{description} is invalid'
            })]
        }
    });

    var ACCOUNT_STATUS = {
        BANNED: 'BANNED',
        PROVISIONAL: 'PROVISIONAL',
        ENABLED: 'ENABLED'
    };

    var RSVP = _ember['default'].RSVP;
    var service = _ember['default'].inject.service;
    var NOTIFICATION_EVENTS = _hyveFrontendConfigEnvironment['default'].NOTIFICATION_EVENTS;
    exports['default'] = Model.extend(Validations, _hyveFrontendMixinsMutualFriends['default'], {
        firstName: attr('string'),
        lastName: attr('string'),
        location: attr('string'),
        facebookId: attr('string'),
        visibleChatGroups: hasMany('group', { inverse: false }),
        fb: service('fb-api'),
        accountStatus: attr('string'),
        online: attr('boolean'),
        phoneNumber: attr('string'),

        profileImageURL: _ember['default'].computed('facebookId', function () {
            var id = this.get('facebookId') || this.get('uid');
            return 'https://graph.facebook.com/' + id + '/picture?type=square';
        }),

        inviteCode: attr('string'),

        numPostsRemaining: attr('number'),

        userWhoReferred: belongsTo('user', { inverse: 'referredUsers' }),
        referredUsers: hasMany('user', { inverse: 'userWhoReferred' }),

        isOutOfPosts: _ember['default'].computed.lte('numPostsRemaining', 0),

        isBetaUser: attr('boolean', {
            defaultValue: function defaultValue() {
                return true;
            }
        }),
        items: hasMany('item', { inverse: 'user', cascadeDelete: true }),

        publishedItems: _ember['default'].computed('items.@each.hasExpired', function () {
            return this.get('publishedItemsTask').perform();
        }),

        publishedItemsTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var items;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('items');

                    case 2:
                        items = context$1$0.sent;
                        return context$1$0.abrupt('return', items.filter(function (item) {
                            return !item.get('hasExpired');
                        }));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        uid: attr('string'),
        userName: attr('string'),
        claim: hasMany('claim', { cascadeDelete: true }),
        sessionManager: service(),
        eventBus: service(),
        notifications: hasMany('notification'),
        routing: service('-routing'),
        router: _ember['default'].computed.alias('routing.router'),

        // status of account computeds
        isBanned: _ember['default'].computed.equal('accountStatus', ACCOUNT_STATUS.BANNED),
        isProvisional: _ember['default'].computed.equal('accountStatus', ACCOUNT_STATUS.PROVISIONAL),

        hasPaid: _ember['default'].computed('subscriptionEnd', function () {
            var currentTime = new Date().getTime() / 1000;
            return this.get('subscriptionEnd') > currentTime;
        }),

        hasNotPaid: _ember['default'].computed.not('hasPaid'),

        blockedUsers: hasMany('user', { inverse: null }),
        rawRatings: hasMany('rating', { inverse: 'on' }),

        blockUser: function blockUser(user) {
            this.get('blockedUsers').pushObject(user);
            return this.save();
        },

        unblockUser: function unblockUser(user) {
            this.get('blockedUsers').removeObject(user);
            return this.save();
        },

        rating: _ember['default'].computed('rawRatings.[]', function () {
            return this.get('ratingTask').perform();
        }),

        ratingTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var ratings, sum;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('rawRatings');

                    case 2:
                        ratings = context$1$0.sent;

                        sum = function sum(a) {
                            return a.reduce(function (p, c) {
                                return p + c.get('value');
                            }, 0);
                        };

                        return context$1$0.abrupt('return', sum(ratings) / ratings.get('length'));

                    case 5:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        timesReviewed: _ember['default'].computed('rawRatings', function () {
            return this.get('rawRatings.length');
        }),

        /**
         * Check if this user has blocked the currently logged in user
         */
        hasBlockedMe: _ember['default'].computed('sessionManager.user', 'blockedUsers.[]', function () {
            return this.get('blockedUsers').contains(this.get('sessionManager.user'));
        }),

        isBlockedByCurrentUser: _ember['default'].computed('sessionManager.user.blockedUsers.[]', function () {
            return this.get('sessionManager.user.blockedUsers').contains(this);
        }),

        canInteractWithCurrentUser: _ember['default'].computed('hasBlockedMe', 'isBlockedByCurrentUser', function () {
            // if (this.get('sessionManager.user') == this) return false;
            return !this.get('hasBlockedMe') && !this.get('isBlockedByCurrentUser');
        }),

        inaccessibleReason: _ember['default'].computed('accountStatus', 'paymentStatus', function () {
            if (this.get('isBanned')) {
                return 'Your account has been banned by the admin';
            }
            if (this.get('hasNotPaid')) {
                return 'You account has been suspended until payment is updated';
            }
        }),

        /**
         * User is in good standing if they have paid and are not currently banned
         * Super admins cannot be banned, nor do they need to pay
         */
        inGoodStanding: _ember['default'].computed('accountStatus', 'hasPaid', function () {
            var paid = this.get('hasPaid');
            var isBanned = this.get('isBanned');
            var isAdmin = this.get('isAdmin');

            if (isAdmin) {
                return true;
            }

            return paid && !isBanned;
        }),

        hardBan: (function () {
            if (!this.get('inGoodStanding')) {
                var router = this.get('router');
                router.transitionTo('account-inaccessible');
            }
        }).observes('inGoodStanding'),

        expireUserItemsTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var items;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('items');

                    case 2:
                        items = context$1$0.sent;
                        context$1$0.next = 5;
                        return Promise.all(items.map(function (i) {
                            return i.unPublishItem();
                        }));

                    case 5:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        ban: function ban() {
            var _this = this;

            // only admin can do this
            if (this.get('sessionManager.user.isAdmin')) {
                this.set('accountStatus', ACCOUNT_STATUS.BANNED);
                return this.save().then(function () {
                    return _this.get('expireUserItemsTask').perform();
                });
            }
        },

        unban: function unban() {
            if (this.get('sessionManager.user.isAdmin')) {
                this.set('accountStatus', ACCOUNT_STATUS.ENABLED);
                return this.save();
            }
        },

        orderedNotifications: _ember['default'].computed.alias('notifications'),

        stripeCustomerId: attr('string'),
        subscriptionStart: attr('number'),
        subscriptionEnd: attr('number'),
        creditCardLast4: attr('number'),
        creditCardBrand: attr('string'),

        /**
         * If the subscription has been canceled
         */
        subscriptionCanceled: attr('boolean'),

        plan: attr({
            defaultValue: function defaultValue() {
                return {};
            }
        }),

        notificationPreferences: attr({
            defaultValue: function defaultValue() {
                return Object.keys(NOTIFICATION_EVENTS).reduce(function (prev, key) {
                    prev[key] = ['email'];
                    return prev;
                }, {});
            }
        }),

        isCustomerInStripe: _ember['default'].computed('stripeCustomerId', function () {
            return !!this.get('stripeCustomerId');
        }),

        hasValidSubscription: _ember['default'].computed('subscriptionEnd', function () {
            // must exist and be in the future
            if (!this.get('subscriptionEnd')) {
                return false;
            }
            var currentDate = new Date().getTime();
            var subscriptionEnd = this.get('subscriptionEnd');

            if (subscriptionEnd.toString().length < 13) {
                subscriptionEnd = subscriptionEnd * 1000;
            }

            return subscriptionEnd > currentDate;
        }),

        unreadMessages: 0,

        isCurrentUser: _ember['default'].computed('sessionManager.user.id', function () {
            return this.get('sessionManager.user.id') == this.get('id');
        }),

        fullName: _ember['default'].computed('firstName', 'lastName', function () {
            return this.get('firstName') + ' ' + this.get('lastName');
        }),

        nameLastInitial: _ember['default'].computed('firstName', 'lastName', function () {
            if (!(this.get('firstName') && this.get('lastName'))) return null;
            return this.get('firstName') + ' ' + this.get('lastName').charAt(0) + '.';
        }),

        favorites: hasMany('item', { inverse: 'favoritors' }),
        following: hasMany('user', { inverse: 'followers' }),
        followers: hasMany('user', { inverse: 'following' }),

        groups: hasMany('group', { inverse: 'members' }),

        chattingWith: _ember['default'].computed.filter('group.members', function (user) {
            return user.get('id') != this.get('sessionManager.user.id');
        }),

        currentUserIsFollowing: _ember['default'].computed('following.[]', 'sessionManager.user.following.[]', function () {
            return this.get('sessionManager.user.following').contains(this);
        }),

        getDefaultNotificationPreferences: function getDefaultNotificationPreferences() {
            return Object.keys(NOTIFICATION_EVENTS).reduce(function (prev, key) {
                prev[key] = ['email', 'application'];
                return prev;
            }, {});
        },

        ownsItem: function ownsItem(item) {
            return this.get('items').then(function (items) {
                return items.contains(item);
            });
        },

        setFromSession: function setFromSession(session) {
            var _this2 = this;

            var profile = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

            if (!profile) {
                profile = get(session, 'currentUser.providerData.firstObject');
            }

            var FB = this.get('fb');

            var promise = FB.api('/me', { fields: 'gender,first_name,last_name,location' }).then(function (response) {

                var location = get(response, 'location.name');

                if (location) {
                    _this2.set('location', get(response, 'location.name'));
                }

                _this2.set('firstName', get(response, 'first_name'));
                _this2.set('lastName', get(response, 'last_name'));
                _this2.set('facebookId', get(response, 'id'));
                _this2.set('notificationPreferences', _this2.getDefaultNotificationPreferences());
                return _this2.save();
            });

            this.set('uid', get(session, 'uid'));
            this.set('provider', get(session, 'provider'));
            this.set('email', get(profile, 'email'));
            this.set('profileImageURL', get(profile, 'photoURL'));

            return promise.then(function () {
                return _this2;
            });
        },

        updateFacebookTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var FB, response, location;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        FB = this.get('fb');
                        context$1$0.next = 3;
                        return FB.api('/me', { fields: 'gender,first_name,last_name,location' });

                    case 3:
                        response = context$1$0.sent;
                        location = get(response, 'location.name');

                        if (location) {
                            this.set('location', get(response, 'location.name'));
                        }

                        this.set('firstName', get(response, 'first_name'));
                        this.set('lastName', get(response, 'last_name'));
                        this.set('facebookId', get(response, 'id'));

                        context$1$0.next = 11;
                        return this.save();

                    case 11:
                        return context$1$0.abrupt('return', context$1$0.sent);

                    case 12:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        favoriteItem: function favoriteItem(item) {
            this.get('favorites').pushObject(item);
            item.get('favoritors').pushObject(this);
            this.save();
            item.save();
        },

        unfavoriteItem: function unfavoriteItem(item) {
            this.get('favorites').removeObject(item);
            item.get('favoritors').removeObject(this);
            this.save();
            item.save();
        },

        claimItemTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(item) {
            var claim = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (!claim) {
                            claim = this.store.createRecord('claim');
                        }

                        claim.set('user', this);
                        claim.set('item', item);

                        context$1$0.next = 5;
                        return claim.save();

                    case 5:
                        context$1$0.next = 7;
                        return this.save();

                    case 7:
                        context$1$0.next = 9;
                        return item.save();

                    case 9:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        claimItem: function claimItem(item) {
            var claim = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

            return this.get('claimItemTask').perform(item, claim);
        },

        unclaimItem: function unclaimItem(item) {
            var _this3 = this;

            return this.store.findAll('claim').then(function (claims) {
                return claims.filter(function (claim) {
                    return claim.get('user.id') == _this3.get('id') && claim.get('item.id') == item.get('id');
                });
            }).then(function (claims) {
                var firstClaim = claims.get('firstObject');

                item.get('claim').removeObject(firstClaim);
                return firstClaim.destroyRecord().then(function () {
                    return item.save();
                }).then(function () {
                    return _this3.save();
                })['catch'](function (error) {
                    debugger;
                });
            });
        },

        profileImageStyle: _ember['default'].computed('profileImageURL', function () {
            var url = this.get('profileImageURL');
            return _ember['default'].String.htmlSafe('background-image: url(' + url + ')');
        }),

        // user profile
        email: attr('string'),
        isAdmin: attr('boolean', {
            defaultValue: function defaultValue() {
                return false;
            }
        }),
        defaultSellZipCode: attr('string'),
        defaultPaymentMethods: attr('string'),

        hasGroupVisible: function hasGroupVisible(group) {
            return this.get('visibleChatGroups').then(function (groups) {
                return groups.contains(group);
            });
        },

        subscriptionEndObserver: (function () {
            this.get('eventBus').publish('hasPublishedEnd');
        }).observes('subscriptionEnd')

    });
});