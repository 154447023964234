define('hyve-frontend/components/removable-image', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: 'removeable-image'.w(),
        classNameBindings: 'isPrimary',

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('image.isPrimary').then(function (bool) {
                _this.set('isPrimary', bool);
            });
        },

        isPrimary: _ember['default'].computed('image.isPrimary', function () {
            return this.get('image.isPrimary');
        }),

        actions: {
            removeImage: function removeImage() {
                var image = this.get('image');
                this.sendAction('onImageRemove', image);
            },
            imageClick: function imageClick() {
                var image = this.get('image');
                this.sendAction('onImageClick', image);
            }
        }
    });
});