define('hyve-frontend/validators/access-code', ['exports', 'ember-cp-validations/validators/base', 'ember', 'hyve-frontend/config/environment'], function (exports, _emberCpValidationsValidatorsBase, _ember, _hyveFrontendConfigEnvironment) {
    var $ = _ember['default'].$;
    var Promise = _ember['default'].RSVP.Promise;
    var accessCodeVerification = _hyveFrontendConfigEnvironment['default'].accessCodeVerification;

    var AccessCode = _emberCpValidationsValidatorsBase['default'].extend({
        validate: function validate(value /*, options, model, attribute*/) {
            if (!value) {
                return true;
            }
            return new Promise(function (resolve, reject) {
                $.post(accessCodeVerification, { code: value }).then(function (result) {
                    resolve(_ember['default'].get(result, 'error') || true);
                }).fail(function (error) {
                    resolve('Could not verify invite code, please try again later');
                });
            });
        }
    });

    AccessCode.reopenClass({
        /**
         * Define attribute specific dependent keys for your validator
         *
         * @param {String}  attribute   The attribute being evaluated
         * @param {Unknown} options     Options passed into your validator
         * @return {Array}
         */
        getDependentsFor: function getDependentsFor() /* attribute, options */{
            return [];
        }
    });

    exports['default'] = AccessCode;
});