define('hyve-frontend/services/-gestures', ['exports', 'ember', 'hyve-frontend/config/environment', 'ember-gestures/services/-gestures'], function (exports, _ember, _hyveFrontendConfigEnvironment, _emberGesturesServicesGestures) {

  var merge = _ember['default'].assign || _ember['default'].merge;

  var gestures = merge({}, {
    useCapture: false
  });
  gestures = merge(gestures, _hyveFrontendConfigEnvironment['default'].gestures);

  exports['default'] = _emberGesturesServicesGestures['default'].extend({
    useCapture: gestures.useCapture
  });
});