define('hyve-frontend/components/user-list-item', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        actions: {
            openChat: function openChat(user) {
                this.sendAction('openChat', user);
                // scroll to the top of the screen
                _ember['default'].$('html,body').animate({ scrollTop: 0 }, 'slow');
            }
        },

        click: function click() {
            this.actions.openChat.call(this, this.get('user'));
        }

    });
});