define('hyve-frontend/components/membership-picker', ['exports', 'ember', 'ember-concurrency', 'npm:moment'], function (exports, _ember, _emberConcurrency, _npmMoment) {
    exports['default'] = _ember['default'].Component.extend({

        sessionManager: _ember['default'].inject.service(),
        stripeAccount: _ember['default'].inject.service(),
        confirmModal: _ember['default'].inject.service(),
        notificationMessages: _ember['default'].inject.service(),
        stripe: _ember['default'].inject.service(),

        title: 'Membership Level',

        hasChanged: false,
        userSelectedLevel: null,
        isLoading: false,

        isNewMembership: false,

        loadedPlanId: _ember['default'].computed.readOnly('sessionManager.user.plan.id'),
        showUpdateButton: _ember['default'].computed('userSelectedLevel', 'loadedPlanId', function () {
            return this.get('userSelectedLevel') && this.get('userSelectedLevel') !== this.get('loadedPlanId') || !this.get('userStripeCustomerId');
        }),

        membershipButtonText: _ember['default'].computed('userSelectedLevel', 'loadedPlanId', function () {
            return this.get('showUpdateButton') && !this.get('userSelectedLevel') || this.get('userSelectedLevel') === this.get('loadedPlanId') ? 'Confirm Plan' : 'Update Plan';
        }),

        userStripeCustomerId: _ember['default'].computed.readOnly('sessionManager.user.stripeCustomerId'),

        membershipHasBeenCanceled: _ember['default'].computed.readOnly('sessionManager.user.subscriptionCanceled'),

        rawLevels: [{
            name: 'Elite Shopper',
            id: 'elite',
            price: 20,
            posts: 15
        }, {
            name: 'Premier Seller',
            id: 'premier',
            price: 30,
            posts: 30

        }, {
            name: 'Super Seller',
            id: 'super',
            price: 40,
            posts: 60,
            infoText: 'Best Value'
        }],

        membershipLevels: _ember['default'].computed('sessionManager.user.plan', 'userSelectedLevel', function () {
            var _this = this;

            var rawLevels = this.get('rawLevels');

            if (this.get('userSelectedLevel')) {
                return rawLevels.map(function (level) {
                    _ember['default'].set(level, 'active', level.id === _this.get('userSelectedLevel'));
                    return level;
                });
            }

            return rawLevels.map(function (level) {
                _ember['default'].set(level, 'active', level.id === _this.get('loadedPlanId'));
                return level;
            });
        }),

        billingPeriodEnd: _ember['default'].computed('sessionManager.user.subscriptionEnd', function () {
            return (0, _npmMoment['default'])(Number(this.get('sessionManager.user.subscriptionEnd') * 1000)).format('MMMM Do, YYYY');
        }),

        cancelSubscriptionTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this2 = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:

                        this.get('confirmModal').openModal({
                            header: 'We hate to see you go',
                            boldText: 'Your access to BeeSwappy will continue until the end of this billing period (' + this.get('billingPeriodEnd') + ')',
                            text: 'We hate to see you go, but we understand that life changes and sometimes you have to go with the flow. BeeSwappy will still be here when you want to come back',
                            splitButtons: true,
                            leftButton: {
                                'class': 'btn btn--hallow btn--brand-green',
                                text: 'Keep Membership',
                                action: function action() {
                                    _this2.get('confirmModal').closeModal();
                                }
                            },
                            rightButton: {
                                'class': 'btn btn--brand-red',
                                text: 'Cancel Membership',
                                action: function action() {
                                    _this2.set('isLoading', true);
                                    _this2.get('confirmModal').closeModal();
                                    _this2.get('stripeAccount').cancelSubscription().then(function () {
                                        _this2.set('isLoading', false);
                                    });
                                }
                            }
                        });

                    case 1:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        activateSubscriptionTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('confirmModal').confirm('Confirm Action', 'By activating your subscription your card on file will automatically be charged on <strong>' + this.get('billingPeriodEnd') + '</strong>');

                    case 2:
                        this.set('isLoading', true);
                        context$1$0.next = 5;
                        return this.get('stripeAccount').reactivateSubscription();

                    case 5:
                        this.set('isLoading', false);

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        betaPaymentModalOpen: false,

        updatePlanTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var user, _ref, posts, price, newPlanTotalCost, newPlanTotalPosts;

            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this3 = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        user = this.get('sessionManager.user');

                        if (user.get('stripeCustomerId')) {
                            context$1$0.next = 4;
                            break;
                        }

                        this.set('betaPaymentModalOpen', true);
                        return context$1$0.abrupt('return');

                    case 4:
                        context$1$0.prev = 4;
                        context$1$0.next = 7;
                        return this.get('calculateChangesTask').perform();

                    case 7:
                        _ref = context$1$0.sent;
                        posts = _ref.posts;
                        price = _ref.price;
                        newPlanTotalCost = _ref.newPlanTotalCost;
                        newPlanTotalPosts = _ref.newPlanTotalPosts;
                        context$1$0.next = 14;
                        return this.get('confirmModal').openModal({
                            header: 'Membership Upgrade',
                            boldText: 'By switching plans, your credit card on file will be charged an additional $' + price + '. You will receive ' + posts + ' additional posts immediately. Next month you will receive ' + newPlanTotalPosts + ' total posts.',
                            text: 'Your plan will automatically renew on <strong>' + this.get('billingPeriodEnd') + '</strong> at the full price of $' + newPlanTotalCost + '.',
                            splitButtons: true,
                            leftButton: {
                                'class': 'btn btn--hallow btn--brand-red',
                                text: 'Cancel Upgrade',
                                action: function action() {
                                    _this3.get('confirmModal').closeModal();
                                }
                            },
                            rightButton: {
                                'class': 'btn btn--brand-green',
                                text: 'Upgrade Membership',
                                action: function action() {
                                    _this3.set('isLoading', true);
                                    _this3.get('confirmModal').closeModal();
                                    _this3.get('stripeAccount').switchPlan(_this3.get('userSelectedLevel')).then(function () {
                                        _this3.set('isLoading', false);
                                        _this3.set('userSelectedLevel', false);
                                    });
                                }
                            }
                        });

                    case 14:
                        context$1$0.next = 18;
                        break;

                    case 16:
                        context$1$0.prev = 16;
                        context$1$0.t0 = context$1$0['catch'](4);

                    case 18:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this, [[4, 16]]);
        })),

        calculateChangesTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var oldPlan, newPlan;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this4 = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        oldPlan = this.get('rawLevels').find(function (l) {
                            return l.id === _this4.get('loadedPlanId');
                        });
                        newPlan = this.get('rawLevels').find(function (l) {
                            return l.id === _this4.get('userSelectedLevel');
                        });

                        if (!(oldPlan.price > newPlan.price)) {
                            context$1$0.next = 6;
                            break;
                        }

                        context$1$0.next = 5;
                        return this.get('confirmModal').confirm('Downgrading not permitted', 'You cannot downgrade your membership at this time.');

                    case 5:
                        throw 'Downgrade';

                    case 6:
                        return context$1$0.abrupt('return', {
                            posts: newPlan.posts - oldPlan.posts,
                            price: newPlan.price - oldPlan.price,
                            newPlanTotalCost: newPlan.price,
                            newPlanTotalPosts: newPlan.posts
                        });

                    case 7:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        createUserInStripeTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(_ref2) {
            var creditCard = _ref2.creditCard;
            var month = _ref2.month;
            var year = _ref2.year;
            var cvv = _ref2.cvv;
            var stripe, stripeAccount, stripeToken, errorMessage;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        stripe = this.get('stripe');
                        stripeAccount = this.get('stripeAccount');
                        context$1$0.prev = 2;
                        context$1$0.next = 5;
                        return stripe.card.createToken({
                            number: creditCard,
                            cvc: cvv,
                            exp_month: month,
                            exp_year: year
                        });

                    case 5:
                        stripeToken = context$1$0.sent;
                        context$1$0.next = 8;
                        return stripeAccount.createCustomerPromise(stripeToken.id, this.get('sessionManager.user.email'), this.get('userSelectedLevel'), false);

                    case 8:

                        this.set('betaPaymentModalOpen', false);

                        this.get('notificationMessages').info('Plan Successfully Updated', {
                            autoClear: true
                        });

                        context$1$0.next = 16;
                        break;

                    case 12:
                        context$1$0.prev = 12;
                        context$1$0.t0 = context$1$0['catch'](2);
                        errorMessage = _ember['default'].get(context$1$0.t0, 'responseJSON.message');

                        this.set('betaUserPaymentError', errorMessage);

                    case 16:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this, [[2, 12]]);
        })),

        betaUserPaymentError: null,

        actions: {
            selectLevel: function selectLevel(level) {
                this.set('userSelectedLevel', level.id);
                this.sendAction('planSelected', level);
            },

            cancelSubscription: function cancelSubscription() {
                this.get('cancelSubscriptionTask').perform();
            },

            updatePlan: function updatePlan() {
                this.get('updatePlanTask').perform();
            },

            activateSubscription: function activateSubscription() {
                this.get('activateSubscriptionTask').perform();
            },

            onBetaPaymentFormSave: function onBetaPaymentFormSave(creditCard) {
                this.get('createUserInStripeTask').perform(creditCard);
            },

            onBetaPaymentCancel: function onBetaPaymentCancel() {
                this.set('betaPaymentModalOpen', false);
            }

        }

    });
});

// make sure that the user has a stripe id, if not, show payment from