define('hyve-frontend/models/image', ['exports', 'ember-data/model', 'ember-data/attr', 'ember', 'ember-data/relationships', 'hyve-frontend/utils/thumbnailUrls', 'ember-concurrency'], function (exports, _emberDataModel, _emberDataAttr, _ember, _emberDataRelationships, _hyveFrontendUtilsThumbnailUrls, _emberConcurrency) {
    var computed = _ember['default'].computed;
    exports['default'] = _emberDataModel['default'].extend({
        url: (0, _emberDataAttr['default'])('string'),
        order: (0, _emberDataAttr['default'])('number'),
        item: (0, _emberDataRelationships.belongsTo)('item', { inverse: 'images' }),
        thumbnailingComplete: (0, _emberDataAttr['default'])('boolean', false),

        isPrimaryTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var itemPrimaryImage;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('item.primaryImage');

                    case 2:
                        itemPrimaryImage = context$1$0.sent;
                        return context$1$0.abrupt('return', itemPrimaryImage === this);

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        isPrimary: _ember['default'].computed('item.primaryImage', function () {
            return this.get('isPrimaryTask').perform();
        }),

        thumbnails: computed('url', function () {
            return (0, _hyveFrontendUtilsThumbnailUrls['default'])(this.get('url'));
        }),

        small: computed('thumbnails', function () {
            return this.get('thumbnails')[0];
        }),

        medium: computed('thumbnails', function () {
            return this.get('thumbnails')[1];
        }),

        large: computed('thumbnails', function () {
            return this.get('thumbnails')[2];
        })

    });
});