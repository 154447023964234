define('hyve-frontend/routes/items', ['exports', 'ember', 'hyve-frontend/mixins/auth', 'npm:moment'], function (exports, _ember, _hyveFrontendMixinsAuth, _npmMoment) {
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {

        title: 'BeeSwappy - Items',

        filterItems: function filterItems(items, filterFn) {
            items = Array.isArray(items) ? items : items.toArray();
            return _ember['default'].RSVP.filter(items, filterFn);
        },

        getAllValidItems: function getAllValidItems() {

            return this.store.findAll('item', { reload: true }).then(function (items) {
                return _ember['default'].RSVP.filter(items.toArray(), function (item) {
                    if (!item) {
                        return false;
                    }
                    // remove expired items
                    if (item.get('hasExpired')) {
                        return false;
                    }

                    return item.get('user').then(function (user) {
                        if (!user.get('firstName')) return false;
                        return item.get('isPosted');
                    });
                });
            });
        },

        model: function model() {
            return this.getAllValidItems().then(function (items) {
                return _ember['default'].RSVP.filter(items, function (item) {
                    return item.get('user').then(function (user) {
                        if (!user) {
                            return false;
                        }
                        return user.get('canInteractWithCurrentUser');
                    });
                });
                // most popular items should appear first
            }).then(function (items) {
                return items.sortBy('popularityScore').toArray().reverse();
            });
        }
    });
});