define("hyve-frontend/instance-initializers/global", ["exports"], function (exports) {
    exports.initialize = initialize;

    function initialize(application) {
        application.store = application.lookup("service:store");
        window.App = application; // or window.Whatever
    }

    exports["default"] = {
        name: 'global',
        initialize: initialize
    };
});