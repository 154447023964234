define('hyve-frontend/routes/login', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend({
        sessionManager: service(),
        actions: {
            signIn: function signIn(provider) {
                this.get('sessionManager').signIn(provider);
            },
            signinAsBoneSaw: function signinAsBoneSaw() {
                this.get('sessionManager').signInAsBoneSaw();
            }
        }
    });
});