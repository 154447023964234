define('hyve-frontend/components/block-user', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        sessionManager: service(),

        classNames: 'btn btn--flat orange'.w(),

        click: function click() {
            if (this.get('user.isBlockedByCurrentUser')) {
                this.get('sessionManager.user').unblockUser(this.get('user'));
            } else {
                this.get('sessionManager.user').blockUser(this.get('user'));
            }
        }

    });
});