define('hyve-frontend/mixins/mutual-friends', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Mixin.create({

        mutualFriends: [],

        _mutualFriendsLoaded: false,

        areFacebookFriends: false,

        numberMutualFriends: _ember['default'].computed.alias('mutualFriends.length'),

        mutualFriendsError: null,

        /**
         * Mutual friends between this user and the current fb user
         * @return Ember.RSVP.Promise
         */
        getMutualFriends: function getMutualFriends() {
            var _this = this;

            if (this.get('_mutualFriendsLoaded')) {
                return _ember['default'].RSVP.resolve(this.get('mutualFriends'));
            }

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                var FB = _this.get('fb');

                var facebookId = _this.get('facebookId');

                FB.mutualFriends(_this.get('facebookId')).then(function (mutualFriends) {
                    if (mutualFriends.error) {
                        _this.set('mutualFriendsError', mutualFriends);
                        return resolve(mutualFriends);
                    }
                    _this.set('mutualFriends', mutualFriends);
                    _this.set('_mutualFriendsLoaded', true);
                    resolve(mutualFriends);
                });

                if (facebookId) {
                    FB.api('/me/friends/' + facebookId, { fields: 'name' }).then(function (resp) {
                        if (_ember['default'].get(resp, 'data.length') > 0) {
                            _this.set('areFacebookFriends', true);
                        }
                    });
                }
            });
        }

    });
});