define('hyve-frontend/components/x-input', ['exports', 'ember'], function (exports, _ember) {
    var Component = _ember['default'].Component;
    var computed = _ember['default'].computed;
    exports['default'] = Component.extend({
        classNames: ['input-field'],
        type: 'text',
        _errorMessages: computed('errors.[]', function () {
            return (this.get('errors') || []).join(', ');
        }),
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            if (Materialize) {
                Materialize.updateTextFields();
            }
        }
    });
});