define('hyve-frontend/event_dispatcher', ['exports', 'ember', 'ember-gestures/event_dispatcher', 'hyve-frontend/config/environment'], function (exports, _ember, _emberGesturesEvent_dispatcher, _hyveFrontendConfigEnvironment) {

  var merge = _ember['default'].assign || _ember['default'].merge;

  var gestures = merge({}, {
    emberUseCapture: false,
    removeTracking: false,
    useFastPaths: false
  });
  gestures = merge(gestures, _hyveFrontendConfigEnvironment['default'].gestures);

  exports['default'] = _emberGesturesEvent_dispatcher['default'].extend({
    useCapture: gestures.emberUseCapture,
    removeTracking: gestures.removeTracking,
    useFastPaths: gestures.useFastPaths
  });
});