define("hyve-frontend/templates/components/validated-rich-editor", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.7.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "hyve-frontend/templates/components/validated-rich-editor.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "froala-editor", [], ["content", ["subexpr", "@mut", [["get", "value", ["loc", [null, [1, 25], [1, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "update", ["subexpr", "action", [["subexpr", "mut", [["get", "value", ["loc", [null, [1, 51], [1, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 46], [1, 57]]], 0, 0]], [], ["loc", [null, [1, 38], [1, 58]]], 0, 0], "options", ["subexpr", "hash", [], ["heightMin", 200], ["loc", [null, [1, 67], [1, 87]]], 0, 0]], ["loc", [null, [1, 0], [1, 90]]], 0, 0], ["inline", "log", [["get", "value", ["loc", [null, [2, 7], [2, 12]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 0], [2, 15]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});