define('hyve-frontend/services/inactive-checker', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Service.extend({

        sessionManager: service(),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            if (window) {

                var handleVisibilityChange = function handleVisibilityChange() {
                    if (document.hidden) {
                        _this.handleInactive();
                    } else {
                        _this.handleActive();
                    }
                };

                document.addEventListener("visibilitychange", handleVisibilityChange, false);
            }
        },

        handleInactive: function handleInactive() {
            if (this.get('sessionManager.user')) {
                var user = this.get('sessionManager.user');
                user.set('online', false);
                user.save();
            }
        },

        handleActive: function handleActive() {
            if (this.get('sessionManager.user')) {
                var user = this.get('sessionManager.user');

                user.set('online', true);
                user.save();
            }
        }

    });
});