define('hyve-frontend/helpers/is-rejected', ['exports', 'ember-promise-helpers/helpers/is-rejected'], function (exports, _emberPromiseHelpersHelpersIsRejected) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersIsRejected['default'];
    }
  });
  Object.defineProperty(exports, 'isRejected', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersIsRejected.isRejected;
    }
  });
});