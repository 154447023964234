define('hyve-frontend/routes/account', ['exports', 'ember', 'hyve-frontend/mixins/auth'], function (exports, _ember, _hyveFrontendMixinsAuth) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {
        notify: service(),
        sessionManager: service(),
        title: 'Edit Your Account',

        model: function model() {
            var sessionManager = this.get('sessionManager');
            return this.get('sessionManager.user');
        },

        actions: {
            submit: function submit() {
                var _this = this;

                this.currentModel.save().then(function () {
                    _this.get('notify').info('Saved!');
                });
            }
        }
    });
});