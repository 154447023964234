define('hyve-frontend/router', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {
    var service = _ember['default'].inject.service;

    var Router = _ember['default'].Router.extend({

        analytics: service(),

        location: _hyveFrontendConfigEnvironment['default'].locationType,
        notifyGoogleAnalytics: (function () {
            this.get('analytics').pageView(this.get('url'));
        }).on('didTransition'),

        scrollToTop: (function () {
            window.scrollTo(0, 0);
        }).on('didTransition')
    });

    Router.map(function () {

        this.route('signup-information');
        this.route('account-inaccessible');
        this.route('login');
        this.route('account');
        this.route('edit-profile');
        this.route('notification-settings');
        this.route('privacy-policy');
        this.route('terms-of-use');
        this.route('faq');
        this.route('notifications');
        this.route('messages');

        this.route('message', function () {
            this.route('view', { path: ':id' });
        });

        this.route('rating', function () {
            this.route('buyerToSellerRating', { path: ':claimId/buyer-to-seller-rating' });
            this.route('sellerToBuyerRating', { path: ':claimId/seller-to-buyer-rating' });
        });

        this.route('items');

        this.authenticatedRoute('admin', function () {
            this.authenticatedRoute('categories');
            this.authenticatedRoute('category', function () {
                this.authenticatedRoute('new');
                this.authenticatedRoute('edit', { path: ':id' });
            });
            this.authenticatedRoute('users');
            this.authenticatedRoute('global-settings');
            this.authenticatedRoute('reports', function () {
                this.authenticatedRoute('view', { path: ':id' });
            });
        });

        this.route('my-items');
        this.authenticatedRoute('item', function () {
            this.authenticatedRoute('new');
            this.authenticatedRoute('edit', { path: 'edit/:id' });
            this.authenticatedRoute('view', { path: ':id' });
        });

        this.route('profile', { path: 'profile/:id' });

        this.authenticatedRoute('account');

        this.route('favorites');
        this.route('not-found', { path: '/*path' });
    });

    exports['default'] = Router;
});