define('hyve-frontend/models/feedback', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    var attr = _emberData['default'].attr;
    var belongsTo = _emberData['default'].belongsTo;

    var Validations = (0, _emberCpValidations.buildValidations)({
        message: {
            description: 'Message',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        },
        name: {
            description: 'Your name',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        },
        email: {
            description: 'Email',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        }
    });

    exports['default'] = _emberData['default'].Model.extend(Validations, {

        createdAt: attr('date', {
            defaultValue: function defaultValue() {
                return new Date();
            }
        }),

        submittedBy: belongsTo('user'),
        message: attr('string'),
        browserMeta: attr('string'),
        email: attr('string'),
        name: attr('string')

    });
});