define('hyve-frontend/components/login-form', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({
        sessionManager: service(),
        actions: {
            authenticateWithFacebook: function authenticateWithFacebook() {
                this.get('sessionManager').signIn('facebook');
            }
        }
    });
});