define('hyve-frontend/components/mobile-chat-users-list', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        sessionManager: service(),

        chatService: service('chat'),
        store: service('store'),

        usersTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var users;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('store').query('user', {});

                    case 2:
                        users = context$1$0.sent;
                        return context$1$0.abrupt('return', users.filter(function (user) {
                            return user.get('canInteractWithCurrentUser') && user.get('nameLastInitial');
                        }));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        users: (function () {
            return this.get('usersTask').perform();
        }).property(),

        actions: {
            openChat: function openChat(personToChatWith) {
                this.get('chatService').openChat(personToChatWith);
            }
        }

    });
});

// get all the users