define('hyve-frontend/components/notifications/notification-item', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Component.extend({

        notificationClickMessage: service(),
        analytics: service(),

        tagName: 'li',

        showActorAvatar: _ember['default'].computed('notification.event.type', function () {
            var type = this.get('notification.event.type');
            return type === 'toUser';
        }),

        classNames: 'notification-item',

        classNameBindings: ['isRead:notification-item--read'],

        isRead: _ember['default'].computed.alias('notification.read'),

        click: function click() {
            this.get('analytics').clickNotification();
            this.get('clickTask').perform();
        },

        clickTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var clickMethod, args, mobile;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('notification.event.clickMethod');

                    case 2:
                        clickMethod = context$1$0.sent;
                        context$1$0.next = 5;
                        return this.get('notification.event.clickMethodArgs');

                    case 5:
                        args = context$1$0.sent;
                        mobile = this.get('mobile');

                        if (clickMethod) {
                            if (mobile) {
                                this.get('container').lookup('router:main').transitionTo('/message/' + args[0]);
                            } else {
                                this.get('notificationClickMessage').call(clickMethod, args);
                            }
                        }

                    case 8:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        mouseEnter: function mouseEnter() {
            if (this.get('notification.read') == false) {
                this.get('notification').set('read', true);
                this.get('notification').save();
            }
        },

        actions: {
            remove: function remove() {
                this.get('notification').destroyRecord();
                return false;
            }
        }

    });
});