define('hyve-frontend/locales/en/translations', ['exports'], function (exports) {
    exports['default'] = {
        // "some.translation.key": "Text for some.translation.key",
        //
        // "a": {
        //   "nested": {
        //     "key": "Text for a.nested.key"
        //   }
        // },
        //
        // "key.with.interpolation": "Text with {{anInterpolation}}"
        item: {
            view: {
                'back-to-items': 'Back to Items',
                'item-has-been-sold': 'Item has been sold!',
                'price-is-negotiable': 'Price is Negotiable',
                'listed-price': 'Listed Price',
                'item-description': 'Item Description',
                'pickup-location': 'Pickup Location',
                'item-for-sale-by': 'Item for sale by'
            }
        },
        comments: {
            header: 'Ask a question or join the discussion',
            'add-comment': 'Add a comment'
        },
        location: 'Location',
        followers: {
            'one': 'follower',
            'other': 'followers'
        },
        user: {
            'number-followers': "{{ user }} has {{ numFollowers }} {{ pluralFollowers }}",
            'me': 'me'
        },
        'user-actions': {
            following: 'Following',
            unfollow: 'unfollow',
            follow: 'Follow',
            chat: 'chat'
        },
        'mutual-friends': {
            'not-facebook-firends': 'You are not friends on Facebook',
            'facebook-friends': 'You are friends on Facebook',
            'friends-in-common': {
                'one': 'You have <strong>{{ count }}</strong> friend in common',
                'other': 'You have <strong>{{ count }}</strong> friends in common'
            }
        },
        claim: {
            'claim': 'claim',
            'unclaim': 'unclaim',
            'join-wait-list': 'Join Wait List',
            'leave-wait-list': 'Leave Wait List',
            'tool-tip': {
                'unclaim': 'Unclaim this item, seller will be notified',
                'already-claimed': 'This item has already been claimed, join the wait list. There are {{ count }} people ahead of you',
                'submit-claim': 'Item has not yet been claimed. Submit your claim now',
                'date': 'Claim submitted {{ date }}'
            }
        }
    };
});