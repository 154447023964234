define('hyve-frontend/validators/credit-card-expiry', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {

    var CreditCardExpiry = _emberCpValidationsValidatorsBase['default'].extend({
        stripe: _ember['default'].inject.service(),

        validate: function validate(value, options, model, attribute) {
            var year = model.get('year');
            var month = model.get('month');

            var stripeResult = this.get('stripe').card.validateExpiry(month, year);
            return stripeResult || 'Expiration date must be in the future';
        }
    });

    CreditCardExpiry.reopenClass({
        /**
         * Define attribute specific dependent keys for your validator
         *
         * @param {String}  attribute   The attribute being evaluated
         * @param {Unknown} options     Options passed into your validator
         * @return {Array}
         */
        getDependentsFor: function getDependentsFor() /* attribute, options */{
            return ['year', 'month'];
        }
    });

    exports['default'] = CreditCardExpiry;
});