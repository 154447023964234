define('hyve-frontend/validators/credit-card-cvc', ['exports', 'ember-cp-validations/validators/base'], function (exports, _emberCpValidationsValidatorsBase) {

    var CreditCardCvc = _emberCpValidationsValidatorsBase['default'].extend({

        stripe: Ember.inject.service(),

        validate: function validate(value) {
            var stripeResult = this.get('stripe').card.validateCVC(value);
            return stripeResult || 'CVV number does not appear to be valid';
        }
    });

    CreditCardCvc.reopenClass({
        /**
         * Define attribute specific dependent keys for your validator
         *
         * @param {String}  attribute   The attribute being evaluated
         * @param {Unknown} options     Options passed into your validator
         * @return {Array}
         */
        getDependentsFor: function getDependentsFor() /* attribute, options */{
            return [];
        }
    });

    exports['default'] = CreditCardCvc;
});