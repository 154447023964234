define('hyve-frontend/components/mobile-chat-box', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
    var service = _ember['default'].inject.service;
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Component.extend({

        sessionManager: service(),
        store: service(),
        analytics: service(),
        chat: service(),
        notification: service('message-notification'),

        foreignUsersTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var members;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        context$1$0.next = 2;
                        return this.get('group.members');

                    case 2:
                        members = context$1$0.sent;
                        return context$1$0.abrupt('return', members.filter(function (m) {
                            return m.get('uid') != _this.get('sessionManager.user.uid');
                        }));

                    case 4:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        foreignUsers: _ember['default'].computed('users.members.[]', function () {
            return this.get('foreignUsersTask').perform();
        }),

        messages: _ember['default'].computed('group.messages.[]', 'group', function () {
            var _this2 = this;

            _ember['default'].run.later(function () {
                return _this2.scrollToBottom();
            }, 100);
            return this.get('group.messages');
        }),

        scrollToBottom: function scrollToBottom() {
            var el = this.$('.message-window');
            if (el.length) {
                el.scrollTop(el.get(0).scrollHeight);
            }
        },

        actions: {
            createMessage: function createMessage() {
                var _this3 = this;

                this.get('analytics').sendMessage();
                // create a new message
                var messageObj = this.get('store').createRecord('message', {
                    user: this.get('sessionManager.user'),
                    message: this.get('message'),
                    timestamp: Date.now()
                });

                this.get('group.messages').pushObject(messageObj).save().then(function () {
                    _this3.set('message', '');
                    _this3.scrollToBottom();
                });
            }
        }

    });
});