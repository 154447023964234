define('hyve-frontend/initializers/mobile-chat-notification', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'message-notification', 'service:message-notification');
    application.inject('service', 'router', 'router:main');
  }

  exports['default'] = {
    name: 'mobile-chat-notification',
    initialize: initialize
  };
});