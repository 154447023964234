define('hyve-frontend/components/notification-preferences', ['exports', 'ember', 'hyve-frontend/config/environment'], function (exports, _ember, _hyveFrontendConfigEnvironment) {
    var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

    function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

    exports['default'] = _ember['default'].Component.extend({

        sessionManager: _ember['default'].inject.service(),

        methods: _hyveFrontendConfigEnvironment['default'].NOTIFICATION_METHODS,

        events: _hyveFrontendConfigEnvironment['default'].NOTIFICATION_EVENTS,

        eventRows: _ember['default'].computed('events', function () {
            var _this = this;

            var currentNotificationPreferences = this.get('sessionManager.user.notificationPreferences') || {};

            return Object.keys(this.get('events')).map(function (eventKey) {

                return {
                    key: eventKey,
                    title: _this.get('events.' + eventKey),
                    selections: currentNotificationPreferences[eventKey] || []
                };
            });
        }),

        actions: {
            notificationPrefChanged: function notificationPrefChanged(eventKey, selections) {
                var user = this.get('sessionManager.user');

                var currentNotificationPreferences = this.get('sessionManager.user.notificationPreferences') || {};

                user.setProperties({
                    notificationPreferences: _extends({}, currentNotificationPreferences, _defineProperty({}, eventKey, selections))
                });

                user.save();
            }
        }

    });
});