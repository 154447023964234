define('hyve-frontend/controllers/item/view', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        modalIsOpen: false,
        actions: {
            openModal: function openModal() {
                this.set('modalIsOpen', true);
            },
            closeModal: function closeModal() {
                this.set('modalIsOpen', false);
            }

        }
    });
});