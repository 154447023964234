define('hyve-frontend/routes/item/view', ['exports', 'ember'], function (exports, _ember) {
    var service = _ember['default'].inject.service;
    exports['default'] = _ember['default'].Route.extend({
        sessionManager: service(),

        titleToken: function titleToken(model) {
            return model.get('name');
        },

        title: function title(tokens) {
            return 'BeeSwappy - ' + tokens.join(' ');
        },

        model: function model(params) {
            return this.store.findRecord('item', params.id);
        },
        afterModel: function afterModel(model, transition) {

            return model.get('user').then(function (user) {

                if (!user.get('canInteractWithCurrentUser')) {
                    return _ember['default'].RSVP.Promise.reject();
                } else {
                    // increment the times viewed property if this item is not my item
                    if (!model.get('isMyItem')) {
                        model.incrementProperty('timesViewed');
                        return model.save();
                    }
                    return _ember['default'].RSVP.Promise.resolve(model);
                }
            }).then(function (model) {
                if (model.get('isMyItem')) {
                    return model;
                }
                if (model.get('hasExpired')) {
                    return _ember['default'].RSVP.Promise.reject();
                }
                return model;
            });
        },

        actions: {
            favoriteItem: function favoriteItem() {
                this.get('sessionManager.user').favoriteItem(this.currentModel);
            },
            unfavoriteItem: function unfavoriteItem() {
                this.get('sessionManager.user').unfavoriteItem(this.currentModel);
            },
            share: function share(url) {
                url = url.replace(/beeswappy_page/g, window.location.href);
                window.open(url, 'share-dialog', 'width=626, height=436');
            }
        }
    });
});