define('hyve-frontend/components/signup-information', ['exports', 'ember', 'ember-concurrency', 'ember-cp-validations', 'hyve-frontend/config/environment'], function (exports, _ember, _emberConcurrency, _emberCpValidations, _hyveFrontendConfigEnvironment) {
    var service = _ember['default'].inject.service;

    var Validations = (0, _emberCpValidations.buildValidations)({
        email: {
            description: 'Email',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
                type: 'email'
            })]
        },
        accessCode: {
            description: 'Invite Code',
            validators: [(0, _emberCpValidations.validator)('accessCode')]
        },
        zipCode: {
            description: 'Zip Code',
            validators: [(0, _emberCpValidations.validator)('valid-zipcode'), (0, _emberCpValidations.validator)('zip-code-in-target-area')]
        },
        acceptsTos: {
            description: 'Terms Of Use',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        },
        membershipPlan: {
            description: 'Membership Plan',
            validators: [(0, _emberCpValidations.validator)('presence', true)]
        },

        creditCard: {
            description: 'Credit Card number',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCard')]
        },
        cvv: {
            description: 'CVV number',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCardCvc')]
        },
        year: {
            description: 'Expiration Date',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCardExpiry')]
        },
        month: {
            description: 'Expiration Month',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCardExpiry')]
        }

    });

    var signupInfo = _ember['default'].Object.extend(Validations, {
        email: '',
        accessCode: ''
    });

    exports['default'] = _ember['default'].Component.extend({

        sessionManager: service(),
        stripe: _ember['default'].inject.service(),
        stripeAccount: _ember['default'].inject.service(),
        notificationMessages: service(),

        creditCardErrors: _ember['default'].computed('signupInfo.validations.attrs.creditCard.isValid', function () {
            if (this.get('validations.attrs.creditCard.isDirty')) {
                return this.get('validations.attrs.creditCard.messages');
            }
        }),

        cvcErrors: _ember['default'].computed('signupInfo.validations.attrs.cvv.isValid', function () {
            if (this.get('validations.attrs.cvv.isDirty')) {
                return this.get('validations.attrs.cvv.messages');
            }
        }),

        yearErrors: _ember['default'].computed('signupInfo.validations.attrs.year.isValid', function () {
            if (this.get('validations.attrs.year.isDirty') && this.get('validations.attrs.month.isDirty')) {
                return this.get('validations.attrs.year.messages');
            }
        }),

        monthErrors: _ember['default'].computed('signupInfo.validations.attrs.month.isValid', function () {
            if (this.get('validations.attrs.month.isDirty') && this.get('validations.attrs.year.isDirty')) {
                return this.get('validations.attrs.month.messages');
            }
        }),

        months: [{ value: '1', content: 'January' }, { value: '2', content: 'February' }, { value: '3', content: 'March' }, { value: '4', content: 'April' }, { value: '5', content: 'May' }, { value: '6', content: 'June' }, { value: '7', content: 'July' }, { value: '8', content: 'August' }, { value: '9', content: 'September' }, { value: '10', content: 'October' }, { value: '11', content: 'November' }, { value: '12', content: 'December' }],

        years: _ember['default'].computed(function () {
            var acc = [];
            var currentYear = new Date().getFullYear();
            for (var i = 0; i < 10; i++) {
                acc.push({
                    value: currentYear,
                    content: currentYear
                });
                currentYear++;
            }
            return acc;
        }),

        accessCodeShown: false,
        useSavedPromise: null,

        userName: _ember['default'].computed('sessionManager.firebaseUserData', function () {
            return this.get('sessionManager.firebaseUserData.currentUser').displayName;
        }),

        signupInfo: _ember['default'].computed('sessionManager', function () {
            return signupInfo.create(_ember['default'].getOwner(this).ownerInjection());
        }),

        loading: false,

        getUserFromAccessCode: function getUserFromAccessCode(value) {
            var accessCodeVerification = _hyveFrontendConfigEnvironment['default'].accessCodeVerification;

            return new Promise(function (resolve, reject) {
                $.post(accessCodeVerification, { code: value }).then(function (user) {
                    if (_ember['default'].get(user, 'error')) {
                        reject(_ember['default'].get(user, 'error'));
                    } else {
                        resolve(user);
                    }
                }).fail(function (error) {
                    reject('Could not verify invite code, please try again later');
                });
            });
        },

        createUserTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var email, defaultSellZipCode, planId, userWhoReferred;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                var _this = this;

                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        email = this.get('signupInfo.email');
                        defaultSellZipCode = this.get('signupInfo.zipCode');
                        planId = this.get('signupInfo.membershipPlan');
                        context$1$0.next = 5;
                        return this.getUserFromAccessCode(this.get('signupInfo.accessCode'));

                    case 5:
                        userWhoReferred = context$1$0.sent;

                        if (!this.get('sessionManager').userCreated()) {
                            context$1$0.next = 8;
                            break;
                        }

                        return context$1$0.abrupt('return');

                    case 8:
                        context$1$0.next = 10;
                        return this.get('sessionManager').createNewUser({ email: email, defaultSellZipCode: defaultSellZipCode, userWhoReferred: userWhoReferred });

                    case 10:

                        this.eventBus.subscribe('hasPublishedEnd', function () {
                            _ember['default'].getOwner(_this).lookup('router:main').transitionTo('/items');
                            _this.set('loading', false);
                            _this.eventBus.unsubscribe('hasPublishedEnd');
                        });

                    case 11:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        saveTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        if (!this.get('signupInfo').get('validations.isValid')) {
                            context$1$0.next = 6;
                            break;
                        }

                        this.set('loading', true);
                        context$1$0.next = 4;
                        return this.get('createUserTask').perform();

                    case 4:
                        context$1$0.next = 6;
                        return this.get('createUserInStripeTask').perform();

                    case 6:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        createUserInStripeTask: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
            var stripe, stripeAccount, stripeToken;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        stripe = this.get('stripe');
                        stripeAccount = this.get('stripeAccount');
                        context$1$0.next = 4;
                        return stripe.card.createToken({
                            number: this.get('signupInfo.creditCard'),
                            cvc: this.get('signupInfo.cvv'),
                            exp_month: this.get('signupInfo.month'),
                            exp_year: this.get('signupInfo.year')
                        });

                    case 4:
                        stripeToken = context$1$0.sent;
                        context$1$0.next = 7;
                        return stripeAccount.createCustomerPromise(stripeToken.id, this.get('signupInfo.email'), this.get('signupInfo.membershipPlan'), this.get('signupInfo.autoRenew'));

                    case 7:
                    case 'end':
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })),

        actions: {
            save: function save() {
                var _this2 = this;

                this.get('saveTask').perform()['catch'](function (e) {
                    // something went wrong here
                    _this2.get('notificationMessages').error(_ember['default'].get(e, 'responseJSON.message'));
                    _this2.set('loading', false);
                });
            },

            toggleAccessCode: function toggleAccessCode() {
                this.toggleProperty('accessCodeShown');
            },

            planSelected: function planSelected(_ref) {
                var id = _ref.id;

                this.set('signupInfo.membershipPlan', id);
            }

        },

        init: function init() {
            this._super.apply(this, arguments);
        }

    });
});

// don't recreate the user

// check if validations are valid

// create the user in stripe