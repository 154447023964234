define('hyve-frontend/components/upload/file-upload', ['exports', 'ember', 'ember-uploader', 'hyve-frontend/components/upload/ProgressUploader', 'hyve-frontend/config/environment'], function (exports, _ember, _emberUploader, _hyveFrontendComponentsUploadProgressUploader, _hyveFrontendConfigEnvironment) {
    var isEmpty = _ember['default'].isEmpty;
    var computed = _ember['default'].computed;
    var run = _ember['default'].run;
    var Promise = _ember['default'].RSVP.Promise;
    var service = _ember['default'].inject.service;
    exports['default'] = _emberUploader['default'].FileField.extend({

        signingUrl: _hyveFrontendConfigEnvironment['default'].APP.IMAGE_SIGN_URL,
        store: service(),
        multiple: true,
        tagName: 'div',
        attributeBindings: [],
        classNames: 'upload-box'.w(),

        _allowedTypes: ['image/jpeg', 'image/png'],

        label: computed('image.url', function () {
            return this.get('image.url') ? 'Replace' : 'Upload';
        }),

        id: computed.oneWay('elementId'),

        inputField: null,

        uploaders: [],

        hasImages: computed.notEmpty('image.url'),

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.inputField = $('<input />').attr({
                type: 'file',
                name: this.get('name'),
                style: 'display: none;',
                multiple: this.get('multiple'),
                accept: this.get('_allowedTypes')
            });

            this.$().append(this.inputField);
        },

        filesDidChange: function filesDidChange(files) {
            var _this = this;

            var uploadPromises = [];
            this.sendAction('onUploadStart', null);

            // for each file create an uploader
            var uploaders = Array.from(files).map(function (f, i) {
                var uploader = _hyveFrontendComponentsUploadProgressUploader['default'].create({
                    url: _this.get('url'),
                    filename: f.name,
                    signingUrl: _this.get('signingUrl'),
                    firebase: _this.get('firebase')
                });

                _this.sendAction('onUploadStart', uploader);

                uploader.on('didUpload', function (url) {
                    var image = _this.get('store').createRecord('image', {
                        item: _this.get('parentModel')
                    });
                    image.set('url', url);
                    image.save();
                });

                uploadPromises.push(uploader.upload(f));
                return uploader;
            });

            this.set('uploaders', uploaders);

            _ember['default'].RSVP.Promise.all(uploadPromises).then(function () {
                _this.sendAction('onUploadEnd', null);
            });
        },

        removeUploader: function removeUploader(uploader) {
            this.get('uploaders').removeObject(uploader);
        },

        actions: {
            uploadClick: function uploadClick() {
                this.get('inputField').trigger('click');
            }
        }

    });
});