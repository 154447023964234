define('hyve-frontend/components/payment-form', ['exports', 'ember', 'hyve-frontend/utils/user-validations', 'ember-cp-validations'], function (exports, _ember, _hyveFrontendUtilsUserValidations, _emberCpValidations) {

    var Validations = (0, _emberCpValidations.buildValidations)({
        creditCard: {
            description: 'Credit Card number',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCard')]
        },
        cvv: {
            description: 'CVV number',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCardCvc')]
        },
        year: {
            description: 'Expiration Date',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCardExpiry')]
        },
        month: {
            description: 'Expiration Month',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCardExpiry')]
        }
    });

    exports['default'] = _ember['default'].Component.extend(Validations, {

        stripe: _ember['default'].inject.service(),
        stripeAccount: _ember['default'].inject.service(),
        sessionManager: _ember['default'].inject.service(),

        classNames: ['payment-form'],
        classNameBindings: ['processingPayment:processing-payment'],

        creditCardErrors: _ember['default'].computed('validations.attrs.creditCard.isValid', function () {
            if (this.get('validations.attrs.creditCard.isDirty')) {
                return this.get('validations.attrs.creditCard.messages');
            }
        }),

        cvcErrors: _ember['default'].computed('validations.attrs.cvv.isValid', function () {
            if (this.get('validations.attrs.cvv.isDirty')) {
                return this.get('validations.attrs.cvv.messages');
            }
        }),

        yearErrors: _ember['default'].computed('validations.attrs.year.isValid', function () {
            if (this.get('validations.attrs.year.isDirty') && this.get('validations.attrs.month.isDirty')) {
                return this.get('validations.attrs.year.messages');
            }
        }),

        monthErrors: _ember['default'].computed('validations.attrs.month.isValid', function () {
            if (this.get('validations.attrs.month.isDirty') && this.get('validations.attrs.year.isDirty')) {
                return this.get('validations.attrs.month.messages');
            }
        }),

        months: [{ value: '1', content: 'January' }, { value: '2', content: 'February' }, { value: '3', content: 'March' }, { value: '4', content: 'April' }, { value: '5', content: 'May' }, { value: '6', content: 'June' }, { value: '7', content: 'July' }, { value: '8', content: 'August' }, { value: '9', content: 'September' }, { value: '10', content: 'October' }, { value: '11', content: 'November' }, { value: '12', content: 'December' }],

        years: _ember['default'].computed(function () {
            var acc = [];
            var currentYear = new Date().getFullYear();
            for (var i = 0; i < 10; i++) {
                acc.push({
                    value: currentYear,
                    content: currentYear
                });
                currentYear++;
            }
            return acc;
        }),

        userCreated: false,

        init: function init() {
            this._super.apply(this, arguments);
            this.eventBus.subscribe('paymentFormSubmit', this.onFormSubmit.bind(this));
        },

        processingPayment: false,

        isUpdateOfPayment: _ember['default'].computed.readOnly('sessionManager.user.isCustomerInStripe'),

        formHeading: _ember['default'].computed('isUpdateOfPayment', function () {

            if (this.get('isUpdateOfPayment')) {
                return 'Update Payment Information';
            } else {
                return 'Enter Payment Information';
            }
        }),

        submitEmail: _ember['default'].computed('email', 'model.email', function () {
            if (this.get('email')) {
                return this.get('email');
            }
            return this.get('model.email');
        }),

        onFormSubmit: function onFormSubmit(accountFormComponent, planId) {
            var _this = this;

            var stripe = this.get('stripe');
            var stripeAccount = this.get('stripeAccount');

            if (this.get('validations.isValid')) {
                stripe.card.createToken({
                    number: this.get('creditCard'),
                    cvc: this.get('cvv'),
                    exp_month: this.get('month'),
                    exp_year: this.get('year')
                }).then(function (resp) {
                    if (_this.get('isUpdateOfPayment')) {
                        return stripeAccount.updateCustomer(resp.id, _this.get('submitEmail'));
                    }
                    return stripeAccount.createCustomer(resp.id, _this.get('submitEmail'), planId, _this.get('autoRenew'));
                }).then(function (customer) {
                    _this.eventBus.publish('paymentProcessed', customer);
                })['catch'](function (err) {
                    debugger;
                });
            }
        }

    });
});