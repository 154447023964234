define('hyve-frontend/adapters/application', ['exports', 'emberfire/adapters/firebase', 'hyve-frontend/mixins/cascade-delete-mixin', 'ember-data'], function (exports, _emberfireAdaptersFirebase, _hyveFrontendMixinsCascadeDeleteMixin, _emberData) {
    var attr = _emberData['default'].attr;
    var hasMany = _emberData['default'].hasMany;
    var Model = _emberData['default'].Model;
    var belongsTo = _emberData['default'].belongsTo;
    var _Ember = Ember;
    var Promise = _Ember.RSVP.Promise;

    Model.reopen({
        commit: function commit() {
            this.get('_internalModel').send('willCommit');
            this.set('_internalModel._attributes', {});
            this.get('_internalModel').send('didCommit');
        },

        changedAttributes: function changedAttributes() {
            var _this = this;

            var attributes = this._super.apply(this, arguments);
            var relationships = {};

            // check relationships
            this.eachRelationship(function (name, meta) {
                if (meta.kind === 'belongsTo') {
                    var basePath = '_internalModel._relationships.initializedRelationships.' + name;

                    var initialId = _this.get(basePath + '.canonicalState.id');
                    var newId = _this.get(name + '.id');

                    if (initialId !== newId) {

                        relationships[name] = [_this.get('_data.' + name + '.id'), _this.get(name + '.id')];
                    }
                }
            });

            return Ember.merge(attributes, relationships);
        }

    });

    exports['default'] = _emberfireAdaptersFirebase['default'].extend(_hyveFrontendMixinsCascadeDeleteMixin['default'], {

        updateRecord: function updateRecord(store, typeClass, snapshot) {
            var _this2 = this;

            if (snapshot.record.get('isNew')) {
                return this._super.apply(this, arguments);
            }

            var recordRef = this._getAbsoluteRef(snapshot.record);
            var recordCache = this._getRecordCache(typeClass, snapshot.id);
            var pathPieces = recordRef.path.toString().split('/');
            var lastPiece = pathPieces[pathPieces.length - 1];
            var serializedRecord = snapshot.serialize({
                includeId: lastPiece !== snapshot.id // record has no firebase `key` in path
            });

            var serializer = store.serializerFor(typeClass.modelName);

            return new Promise(function (resolve, reject) {

                var changes = _this2._getAllChangedAttributesForModel(snapshot.record);
                var relationshipsToSave = [];

                snapshot.record.eachRelationship(function (key, relationship) {
                    var relationshipKey = serializer.keyForRelationship(key);
                    var data = serializedRecord[relationshipKey];
                    var isEmbedded = _this2.isRelationshipEmbedded(store, typeClass.modelName, relationship);
                    var hasMany = relationship.kind === 'hasMany';

                    if (hasMany || isEmbedded) {
                        if (!Ember.isNone(data)) {
                            relationshipsToSave.push({
                                data: data,
                                relationship: relationship,
                                isEmbedded: isEmbedded,
                                hasMany: hasMany
                            });
                        }
                        delete serializedRecord[relationshipKey];
                    }
                });
                var reportError = function reportError(errors) {
                    var error = new Error('Some errors were encountered while saving ' + typeClass + ' ' + snapshot.id);
                    error.errors = errors;
                    reject(error);
                };

                // now go through all the changes, and update individually
                // it appears that relationships do not appear in `changes` so this just works
                // we do need to care about actual value should be the serialized one
                var changePromises = Object.keys(changes).map(function (changedAttribute) {
                    return _this2._updateRecordAttribute(changedAttribute, serializedRecord[changedAttribute], snapshot.record);
                });

                Promise.all(changePromises).then(function () {
                    var savedRelationships = relationshipsToSave.map(function (relationshipToSave) {
                        var data = relationshipToSave.data;
                        var relationship = relationshipToSave.relationship;
                        if (relationshipToSave.hasMany) {
                            return _this2._saveHasManyRelationship(store, typeClass, relationship, data, recordRef, recordCache);
                        } else {
                            // embedded belongsTo, we need to fill in the informations.
                            if (relationshipToSave.isEmbedded) {
                                return _this2._saveEmbeddedBelongsToRecord(store, typeClass, relationship, data, recordRef);
                            }
                        }
                    });
                    return Ember.RSVP.allSettled(savedRelationships);
                })['catch'](function (e) {
                    reportError(e);
                }).then(function (results) {
                    var rejected = Ember.A(results).filterBy('state', 'rejected');
                    if (rejected.length !== 0) {
                        reportError(rejected.mapBy('reason').toArray());
                    } else {
                        resolve();
                    }
                });
            }, 'DS: FirebaseAdapter#updateRecord ' + typeClass + ' to ' + recordRef.toString());
        },

        _updateRecordAttribute: function _updateRecordAttribute(attribute, value, record) {
            var saveRef = this._getAbsoluteRef(record).child(attribute);
            return saveRef.set(value);
        },

        _getAllChangedAttributesForModel: function _getAllChangedAttributesForModel(model) {
            var changes = model.changedAttributes.call(model);
            return changes;
        }

    });
});