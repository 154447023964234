define('hyve-frontend/routes/my-items', ['exports', 'ember', 'hyve-frontend/mixins/auth'], function (exports, _ember, _hyveFrontendMixinsAuth) {
    var RSVP = _ember['default'].RSVP;
    exports['default'] = _ember['default'].Route.extend(_hyveFrontendMixinsAuth['default'], {
        title: 'BeeSwappy - My Items',
        model: function model() {
            return this.store.findAll('item').then(function (items) {
                var validItems = items.filter(function (item) {
                    return item.get('isMyItem');
                });
                return RSVP.Promise.resolve(validItems);
            });
        }
    });
});