define('hyve-frontend/components/validated-input', ['exports', 'ember', 'ember-cli-materialize/components/md-input-field'], function (exports, _ember, _emberCliMaterializeComponentsMdInputField) {
    var defineProperty = _ember['default'].defineProperty;
    var computed = _ember['default'].computed;
    var isPresent = _ember['default'].isPresent;
    exports['default'] = _emberCliMaterializeComponentsMdInputField['default'].extend({
        type: 'text',

        validation: null,
        isFocused: false,
        isInvalid: false,

        inputValueChange: _ember['default'].observer('rawInputValue', function () {
            this.set('isTyping', true);
            _ember['default'].run.debounce(this, this.setValue, 500, false);
        }),

        setValue: function setValue() {
            this.set('value', this.get('rawInputValue'));
            this.set('isTyping', false);
        },

        setupLabelOnChange: _ember['default'].observer('rawInputValue', function () {
            this._setupLabel();
            _ember['default'].run.later(this, '_setupLabel', 1000);
        }),

        init: function init() {
            this._super.apply(this, arguments);
            var valuePath = this.get('valuePath');
            defineProperty(this, 'validation', computed.oneWay('model.validations.attrs.' + valuePath));
            defineProperty(this, 'value', computed.alias('model.' + valuePath));
            defineProperty(this, 'rawInputValue', computed.alias('model.' + valuePath));
        },

        notValidating: computed.not('validation.isValidating'),

        didValidate: computed.oneWay('targetObject.didValidate'),

        hasContent: computed.notEmpty('value'),

        isValid: computed.and('hasContent', 'validation.isValid', 'notValidating'),

        validationInvalid: computed.oneWay('validation.isInvalid'),

        showErrorClass: computed.and('notValidating', 'showMessage', 'hasContent', 'validation'),

        showErrorMessage: computed('validation.isDirty', 'isInvalid', 'didValidate', function () {
            return (this.get('validation.isDirty') || this.get('didValidate')) && this.get('isInvalid');
        }),

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            // make sure the label moves when a value is bound.
            this._setupLabel();

            // run setup label again in case one of the values was resolved late and the label is overlapping
            _ember['default'].run.later(this, '_setupLabel', 1000);
        },

        actions: {
            /**
             * Do the validation here
             */
            handleBlur: function handleBlur() {
                if (this.get('validationInvalid')) {
                    this.set('isInvalid', true);
                } else {
                    this.set('isInvalid', false);
                }
                return true;
            },
            /**
             * Check if we are valid, if so set invalid to false
             * This will clear any invalid indicators as soon as the value passes
             */
            handlekeydown: function handlekeydown() {
                if (!this.get('validationInvalid')) {
                    this.set('isInvalid', false);
                }
                // bubble
                return true;
            }
        }
    });
});