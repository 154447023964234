define('hyve-frontend/components/payment-validation-form', ['exports', 'ember', 'ember-cp-validations'], function (exports, _ember, _emberCpValidations) {

    var Validations = (0, _emberCpValidations.buildValidations)({
        creditCard: {
            description: 'Credit Card number',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCard')]
        },
        cvv: {
            description: 'CVV number',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCardCvc')]
        },
        year: {
            description: 'Expiration Date',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCardExpiry')]
        },
        month: {
            description: 'Expiration Month',
            validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('creditCardExpiry')]
        }
    });

    exports['default'] = _ember['default'].Component.extend(Validations, {

        creditCardErrors: _ember['default'].computed('validations.attrs.creditCard.isValid', function () {
            if (this.get('validations.attrs.creditCard.isDirty')) {
                return this.get('validations.attrs.creditCard.messages');
            }
        }),

        cvcErrors: _ember['default'].computed('validations.attrs.cvv.isValid', function () {
            if (this.get('validations.attrs.cvv.isDirty')) {
                return this.get('validations.attrs.cvv.messages');
            }
        }),

        yearErrors: _ember['default'].computed('validations.attrs.year.isValid', function () {
            if (this.get('validations.attrs.year.isDirty') && this.get('validations.attrs.month.isDirty')) {
                return this.get('validations.attrs.year.messages');
            }
        }),

        monthErrors: _ember['default'].computed('validations.attrs.month.isValid', function () {
            if (this.get('validations.attrs.month.isDirty') && this.get('validations.attrs.year.isDirty')) {
                return this.get('validations.attrs.month.messages');
            }
        }),

        months: [{ value: '1', content: 'January' }, { value: '2', content: 'February' }, { value: '3', content: 'March' }, { value: '4', content: 'April' }, { value: '5', content: 'May' }, { value: '6', content: 'June' }, { value: '7', content: 'July' }, { value: '8', content: 'August' }, { value: '9', content: 'September' }, { value: '10', content: 'October' }, { value: '11', content: 'November' }, { value: '12', content: 'December' }],

        years: _ember['default'].computed(function () {
            var acc = [];
            var currentYear = new Date().getFullYear();
            for (var i = 0; i < 10; i++) {
                acc.push({
                    value: currentYear,
                    content: currentYear
                });
                currentYear++;
            }
            return acc;
        }),

        showErrors: false,
        isLoading: false,

        errorMessageObserver: _ember['default'].observer('errorMessage', function () {
            // if there is an error message show the form
            if (this.get('errorMessage')) {
                this.set('isLoading', false);
            }
        }),

        actions: {
            save: function save() {
                if (this.get('validations.isValid')) {
                    this.set('isLoading', true);
                    this.sendAction('onSave', {
                        creditCard: this.get('creditCard'),
                        month: this.get('month'),
                        year: this.get('year'),
                        cvv: this.get('cvv')
                    });
                } else {
                    this.set('showErrors', true);
                }
            },
            cancel: function cancel() {
                this.sendAction('onCancel');
            }
        }

    });
});